import * as yup from "yup";

export const dealFormSchema = yup.object().shape({ 
    dealName: yup.string().required("Deal Name is required"),
    dealType: yup.string().required("Deal Type is required"),


    brokerPrimaryContact: yup.string().nullable().notRequired(),
    brokerSecondaryContact: yup.string().nullable().notRequired(),
    
    contactEmail: yup.string().email("Enter a valid email address."),
    contactFullName: yup.string().nullable().notRequired(),
    contactPhone: yup.string().nullable().notRequired(),
    contactCitizenship: yup.string().nullable().notRequired(),
    contactResidency: yup.string().nullable().notRequired(),

    hasCoApplicant: yup.boolean(),
    coApplicationStatus: yup.boolean(),

    // secondaryContactEmail: yup.string().email("Enter a valid email address."),
    secondaryContactEmail: yup.string()
      .when('coApplicationStatus', ([coApplicationStatus] ,schema) => {
        return coApplicationStatus ? schema.email("Enter a valid email address.") : schema.notRequired();
      }),

    secondaryContactFullName: yup.string().nullable().notRequired(),
    secondaryContactPhone: yup.string().nullable().notRequired(),
    secondaryContactCitizenship: yup.string().nullable().notRequired(),
    secondaryContactResidency: yup.string().nullable().notRequired(),    

    websiteTrackingId: yup.string(),
    formId: yup.string(),
    fullName: yup.string(),
    email: yup.string().email("Enter a valid email address."),
    phone: yup.string(),
    citizenship: yup.string(),

    residency: yup.string(),
    countryCode: yup.string(),

    interestRate: yup.number().max(100, "Interest rate cannot exceed 100"),
    propertyValue: yup.number(),
    loanAmount: yup.number(),
    loanTerm: yup.number(),
    lvr: yup.number(),
    product: yup.string().nullable().notRequired(),
    cashOutAmount: yup.number(),
    rateType_1a: yup.string(),
    cashOutReason: yup.string().nullable().notRequired(),

    occupation: yup.string(),
    employment: yup.string(),
    employer: yup.string().nullable().notRequired(),
    since: yup.string().nullable().notRequired(),
    currency: yup.string(),
    fx: yup.string(),

    offset: yup.string(),

    salaryFreq: yup.string(),
    salary: yup.number(),
    calMonthSalary: yup.number(),

    salaryDeduction: yup.number(),
    salaryDeduction_2: yup.number(),
    allowances: yup.number(),
    allowances_2: yup.number(),
    allowanceFreq: yup.string(),
    allowanceFreq_2: yup.string(),
    commission: yup.number(),
    commFreq: yup.string(),
    commission_2: yup.number(),
    commFreq_2: yup.string(),
    bonusThis: yup.number(),
    bonusLast: yup.mixed().nullable().notRequired().test(
        'is-string-or-number',
        'Must be a string or a number',
        value => typeof value === 'string' || typeof value === 'number' || value === undefined
      ),
    bonusThis_2: yup.number(),
    bonusLast_2:yup.number(),
    anyOtherLoan: yup.string(),
    anyOtherLoan_2: yup.string(),
    osProperties: yup.string(),
    anyLiabilities: yup.string(), //*TODO: full logic not completed 
    detailsOfDebt: yup.string(),
    date: yup.string(),

    pricing: yup.string(),
    
    valuation: yup.string().nullable().notRequired(),
    livingStatus: yup.string(),
    currentMortgage_1: yup.string(),
    rentalExpense: yup.number(),
    maritalStatus: yup.string(),
    kids: yup.string(),
    // kidsAge: yup.mixed().oneOf([yup.number(), yup.string()], "please enter a valid age"),
    kidsAge: yup.string(),
    privateEducation: yup.string(),
    purpose: yup.string(),
    purposeEquity: yup.string(),
    
    security: yup.string(),
    aipRentalyiel: yup.number(),
    apiRent: yup.number(),
    ccTotalLimit: yup.number(),
    reduceLimit: yup.string(),

    ccNewLimit: yup.string(),
    lender: yup.string().nullable().notRequired(),
    existingCustomer: yup.string(),
    

    //card details
    ccLimit1: yup.number(),
    ccCurrency1: yup.string(),
    cardFXConversion1: yup.number(),
    curentCCLimit1: yup.string(),


    //Co Applicant Details
    
    fullName_2: yup.string(),
    firstName_2: yup.string(),
    lastName_2: yup.string(),
    phone_2: yup.string(),
    citizenship_2: yup.string(),
    countryResidence_2: yup.string(),
    occupation_2: yup.string(),
    employment_2: yup.string(),
    jobStartMonth: yup.string(),
    jobStartYear: yup.string(),
    jobStartMonth_2: yup.string(),
    jobStartYear_2: yup.string(),
    currencySalary_2: yup.string(),
    since_2: yup.string().nullable().notRequired(),
    fx_2: yup.string(),
    fxConversation_2: yup.number(),
    employer_2: yup.string().nullable().notRequired(),
    
    salary_2: yup.number(),
    salaryFreq_2: yup.string(),
    calMonthSalary_2: yup.number(),

    ccLimit_2: yup.number().nullable().notRequired(),
    residency_2: yup.string(),
    cardCurrency2: yup.string(),
    cardFXConversion2: yup.number(),
    curentCCLimit2: yup.string(),
    proposedCardLimit: yup.number(),
    proposedCardLimit_2: yup.number().nullable().notRequired(),
    proposedCurentCCLimit1: yup.number(),
    proposedCurentCCLimit_2: yup.number(),
    proposedCCTotalLimit: yup.number().nullable().notRequired(),
    


    // calculations
    calcAllowance: yup.number(), 
    calcAllowance2: yup.number(),
    calcCommission: yup.number(),
    calcCommission2: yup.number(),
    
    maxPurchasePrice: yup.number().nullable().notRequired(),
});



