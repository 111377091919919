import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";


export const loanRequestApi = createApi({
    reducerPath: "loanRequestApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        fetchLoanRequest: builder.query({
            query: ({ formId, page, pageSize, searchQuery }) => {
                const params: any = {
                    "page_size": pageSize,
                    "current_page": page,
                    ...(searchQuery && { search: encodeURIComponent(JSON.stringify(searchQuery)) })
                };
                return {
                    url: `/api/utils/get_website_data/${formId}`,
                    params
                };
            },
            transformResponse: (response: { entries: any[]; total_count: number; _labels: any }) => ({
                entries: response.entries,
                totalCount: response.total_count,
                labels: response._labels
            })
        }),

        fetchLoanDetail: builder.query({
            query: requestId => `/api/utils/get_website_data/${requestId}/detail/`
        }),
        fetchLabels: builder.query({
            query: () => `/api/utils/get_website_data/labels/`
        })
    })
});

export const { useFetchLoanRequestQuery, useFetchLabelsQuery, useFetchLoanDetailQuery } = loanRequestApi;
