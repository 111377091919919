import { FC, useEffect, useState } from "react";
import { UserPromptFormTypes } from "../../types";
import ComplianceLoanObjectiveForm from "./CompliancePromptForm/ComplianceLoanObjectiveForm";
import { ContactType, DealFormType } from "@/pages/deals/types";
import { useUpdateDeal } from "@/hooks/useUpdateDeal";
import { FormFieldDataType } from "@/pages/deals/components/DealForm";
import ComplianceLoanRequiremtForm from "./CompliancePromptForm/ComplianceLoanRequiremtForm";
import ComplianceLCForm from "./CompliancePromptForm/ComplianceLCForm";

interface UserPromptFormProps {
    dealId: string | undefined;
    selectedForm: UserPromptFormTypes | null;
    formData: Partial<FormFieldDataType> | null;
    contactData: ContactType | null;
    secondaryContactData: ContactType | null;
    handleDealUpdateState: () => void;
};

const UserPromptForm:FC<UserPromptFormProps> = ({selectedForm, dealId, formData, contactData, secondaryContactData, handleDealUpdateState}) => {
    console.log("selectedForm", selectedForm);
    const { handleDealUpdate, isLoading, isSuccess, error } = useUpdateDeal();
    const handleFormSubmit = ({id, data}:{id:UserPromptFormTypes, data: Partial<DealFormType>}) => {
        handleDealUpdate({formData:data as DealFormType, requestId: dealId ?? ""});
    };  
    useEffect(() => {
        if(isSuccess) {
            handleDealUpdateState()
        }
    },[isSuccess]);

    switch (selectedForm) {
        case "loan_objectives":
            return <ComplianceLoanObjectiveForm formData={formData} isLoading={isLoading}  formId="loan_objectives" handleFormSubmit={handleFormSubmit} />;
        case "loan_requirements":
            return <ComplianceLoanRequiremtForm contactData={contactData} formData={formData} isLoading={isLoading}  formId="loan_requirements" handleFormSubmit={handleFormSubmit} />
        case "loan_circumstances":
            return <ComplianceLCForm secondaryContactData={secondaryContactData} contactData={contactData} formData={formData} isLoading={isLoading}  formId="loan_circumstances" handleFormSubmit={handleFormSubmit} />
        default:
            return <p>No form selected yet.</p>;
    }
};

export default UserPromptForm;