// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { ApiResponse, BrokerRole, User } from "@/types";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        fetchAllUsers: builder.query<User[], { brokerRole?:BrokerRole, fullname?: string, email?: string | null } | void>({
            query: (searchParams) => {
                const queryParts = [];
                if (searchParams) {
                    if (searchParams.fullname) {
                        queryParts.push(`fullname=${encodeURIComponent(searchParams.fullname)}`);
                    }
                    if (searchParams.email) {
                        queryParts.push(`email=${encodeURIComponent(searchParams.email)}`);
                    }
                    if (searchParams.brokerRole) {
                        queryParts.push(`broker_role=${encodeURIComponent(searchParams.brokerRole)}`);
                    }
                }
                const queryString = queryParts.length > 0 ? `?${queryParts.join('&')}` : '';
                return `api/auth/users${queryString}`;
            },
            transformResponse: (response: ApiResponse<any>): any => {
                return response.data;
            }
        }),
        postUserFeedback: builder.mutation<ApiResponse<any>, { message: string }>({
            query: body => ({
                url: "api/auth/feedback",
                method: "POST",
                body
            }),
            transformResponse: (response: ApiResponse<any>): any => response
        }),
    })
});
export const { useFetchAllUsersQuery, usePostUserFeedbackMutation } = userApi;
