import InfoDisplay from "@/components/UI/InfoDisplay";
import { Col, Row, Typography, theme, Input } from "antd";
import Title from "antd/es/typography/Title";
import { ReactNode } from "react";
import { COMPLIANCE_CATEGORY } from "../constant/list";
const { Text } = Typography;

const { useToken } = theme;
const { TextArea } = Input;

const DetailContainer = ({ children }: { children: ReactNode }) => {
    const { token } = useToken();
    return <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>{children}</div>;
};
const DetailTitle = ({ title }: { title: string }) => {
    const { token } = useToken();
    return (
        <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>
            <Title level={4} style={{ fontWeight: "400", margin: "0", padding: "1rem 0" }}>
                {title}
            </Title>
        </div>
    );
};

const ComplianceDetailPanel = ({ data, tableMapper }: { data: any; tableMapper: any }) => {
    console.log("complianceDetailPanel", data)
    return (
        <>
            <div>
                <DetailContainer>
                    <DetailTitle title="Need Analysis Inputs" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {COMPLIANCE_CATEGORY.needAnalysisInputs.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
               
                <DetailContainer>
                    <DetailTitle title="Preferred Loan Inputs" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {COMPLIANCE_CATEGORY.preferredLoanInputs.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Generated Notes" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {COMPLIANCE_CATEGORY.generatedNotes.map((item: string, index) => {
                                return(
                                    <Col xs={12} md={12} key={index}>
                                        <DetailTitle title={tableMapper[item]} />
                                        <TextArea
                                            value={data[item]}
                                            readOnly={true}
                                            rows={4}
                                            />
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                
            </div>
        </>
    );
};

export default ComplianceDetailPanel;
