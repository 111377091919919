import { ChangeEvent, FormEvent, useEffect, useState, useRef, FC, useMemo } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Pagination, Button, Flex, Input, Alert, Drawer } from "antd";
import Title from "antd/es/typography/Title";
import type { PaginationProps } from "antd";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import DealDetailView from "./DealDetailView";
import ActionButtonsRenderer from "./ActionButtonsRenderer";

import { useFetchLoanRequestQuery } from "@/store/apis/loanApi";

import { PURCHASE_TABLE_MAPPER, PURCHASE_DATA_MAPPER } from "@/devFrontData/websiteDataMapper";
import { themeConfig } from "@/config";
import { dateFormatter, formatTitle } from "@/utils/common.utils";
import { PURCHASE_DATA_CATEGORY } from "@/pages/deals/constant";


const transformData = (data:any) => {
    return data.map((item:any) => {
        const updatedItem:{ [key: string]: any } = {};
        Object.keys(PURCHASE_DATA_MAPPER).forEach((key) => {
            updatedItem[PURCHASE_DATA_MAPPER[key]] = item[key];
        });
        return updatedItem;
    });
};


interface PurchaseListProps {
    getSelectedRowId?: (id: string) => void;
    handleCreateDeal?: (id: string) => void;
}
const PurchaseList: FC<PurchaseListProps> = ({ getSelectedRowId, handleCreateDeal }) => {

    const gridRef = useRef<any>(null);
    const [colDefs, setColDefs] = useState<ColDef[]>([]);
    const [rowData, setRowData] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);

    const [searchQuery, setSearchQuery] = useState<any>(null);
    const [searchParms, setSearchParms] = useState<{ [key: string]: string }>({
        "186": "", // firstname
        "188": "", // lastname
        "192": "" // email
    }); // State for search parameters

    // Fetching loan request data using RTK Query
    const {
        data: { entries: purchaseData, totalCount: totalRows, labels: columnLabels } = {},
        error: purchaseDataError,
        isLoading
    } = useFetchLoanRequestQuery({formId:"8", page, pageSize, searchQuery });

    useEffect(() => {
        if (purchaseData) {
            const transformedData = transformData(purchaseData);
            setRowData(transformedData);
        }
    }, [purchaseData]);


    useEffect(() => {
        if (columnLabels) {
            generateColumnDefinitions(columnLabels);
        }
    }, [columnLabels]);

    const generateColumnDefinitions = (labels: Record<number | string, string>) => {
        // const newColDefs: ColDef[] = Object.keys(PURCHASE_TABLE_MAPPER).map(key => {

        //     return {
        //         headerName: `${PURCHASE_TABLE_MAPPER[key]}`,
        //         field: key.toString(),
        //         headerTooltip: PURCHASE_TABLE_MAPPER[key],
        //         filter: true,
        //         editable: true
        //     };
        // });
        const newColDefs: ColDef[] = Object.entries(PURCHASE_DATA_CATEGORY).map(([key, value]) => {
            if (Array.isArray(value)) {
                return {
                    headerName: formatTitle(key),
                    children: value.map(field => { 
                     return {
                            headerName: `${PURCHASE_TABLE_MAPPER[field]}`,
                            field,
                            headerTooltip: PURCHASE_TABLE_MAPPER[field],
                            filter: true,
                            editable: true
                        }     
                    })
                };
            } else {
                return {
                    headerName:  formatTitle(key),
                    field: value
                };
            }
        });

        

        
        // Add action column definition
        newColDefs.push({
            headerName: "Actions",
            cellRenderer: ActionButtonsRenderer,
            cellRendererParams: {
                buttons:['select', 'view', 'create'],
                onAction: handleAction, // Passing the callback function to the renderer
                onView: handleViewData,
                onCreate: createDeal
            },
            width: 80,
            suppressHeaderMenuButton: true,
            pinned: "right",
            filter: false,
        });

        setColDefs(newColDefs);
    };

    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    const createDeal = (data:any) => {
        if(handleCreateDeal) {
            handleCreateDeal(data.websiteTrackingId)
        }
    }

    const handleViewData = (data: any) => {
        // Display a modal or popup with the view data
        setSelectedRow(data);
        setOpenDrawer(true);
    }
    const handleAction = (data: any) => {
        if (data && data.id && getSelectedRowId) {
            getSelectedRowId(data.id as string);
        }
    };

    const handleInputValue = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSearchParms(prev => ({ ...prev, [name]: value }));
    };

    const handleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        gridRef.current?.api?.showLoadingOverlay();
        const searchParams = {
            field_filters: Object.keys(searchParms).map(key => {
                // Only include parameters that are not empty
                if (searchParms[key].trim() !== '') {
                    return {
                        key,
                        value: searchParms[key],
                        operator: "contains"
                    };
                }
                return undefined; // Return undefined for empty values
            }).filter(item => item !== undefined) // Filter out undefined values
        };

        console.log('searchParams', searchParams)
        setSearchQuery(searchParams);
        setPage(1); // Reset to first page for new search
    };

    const onPageSizeChanged = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(0); // Reset to first page
    };

    const onPageChanged = (newPage: number) => {
        setPage(newPage);
    };

    const onShowSizeChange: PaginationProps["onShowSizeChange"] = (current, pageSize) => {
        onPageSizeChanged(pageSize);
    };

    const onPagiSizeChange: PaginationProps["onShowSizeChange"] = (page, pageSize) => {
        onPageChanged(page);
    };

    if (purchaseDataError) {
        return <Alert message="Error" description="Something Went Wrong." type="error" showIcon />;
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setSelectedRow(null);
    }

    return (
        <>
            
                <div style={{ marginBottom: "1rem" }}>
                    <Flex justify="end" gap={20} align="flex-end">
                        <form onSubmit={handleSearch}>
                            <Flex gap="small" justify="flex-end" align="center">
                                <InputField value={searchParms[186]} onChange={handleInputValue} name="186" placeholder="first name" />
                                <InputField  value={searchParms[188]} onChange={handleInputValue} name="188" placeholder="last name" />
                                <InputField  value={searchParms[192]} onChange={handleInputValue} name="192" placeholder="email" />
                                <Button size="small" type="primary" htmlType="submit" shape="circle" icon={<SearchOutlined />} />
                            </Flex>
                        </form>
                    </Flex>
                </div>
                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 350px)" }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        tooltipInteraction={true}
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={colDefs}
                        onGridReady={params => {
                            params.api.paginationGoToPage(page);
                        }}
                    />
                    <Flex justify="space-between" style={{ marginTop: "1rem" }}>
                        <Title level={5} style={{ margin: "0" }}>
                            Total: {totalRows && totalRows}
                        </Title>
                        <Pagination
                            defaultPageSize={pageSize}
                            onChange={onPagiSizeChange}
                            showSizeChanger
                            onShowSizeChange={onShowSizeChange}
                            defaultCurrent={page}
                            total={totalRows}
                        />
                    </Flex>
                </div>
                <Drawer onClose={handleDrawerClose} open={openDrawer}  width={themeConfig.drawer.xxl} title={`${selectedRow?.firstName} ${selectedRow?.lastName} - ${dateFormatter(selectedRow?.date ?? "")}`}>
                        {selectedRow && <DealDetailView data={selectedRow} tableMapper={PURCHASE_TABLE_MAPPER} /> }
                </Drawer>        
        </>
    );
};

const InputField = ({ label, value, onChange, name, placeholder }: any) => (
    <div className="form-control" style={{margin:"0"}}>
        <div className="form-label-wrap">
            <label className="form-label">{label}</label>
        </div>
        <Input value={value} onChange={onChange} name={name} style={{ width: "150px" }} placeholder={placeholder} />
    </div>
);

export default PurchaseList;
