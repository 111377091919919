// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { ApiResponse } from "@/types";
import {
    ComplianceAPIResponse,
    ComplianceFieldTypes,
    ComplianceFormType,
    CompliancePayload,
    SystemPromptPayload,
    SystemPromptType
} from "@/pages/noteGeneration/types";

import { getComplianceUserContent } from "@/pages/noteGeneration/utils";

export const complianceNoteApi = createApi({
    reducerPath: "complianceNoteApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        generateComplianceNote: builder.mutation<any, { type: ComplianceFieldTypes; data: ComplianceFormType }>({
            query: ({ type, data }) => {
                const userContent = getComplianceUserContent(data, type);

                const postData = {
                    user_content: userContent,
                    compliance_field: type
                };

                return {
                    url: "api/comliance-note/v2/generate/",
                    method: "POST",
                    body: postData
                };
            },
            transformResponse: (response: ApiResponse<any>): any => {
                return response.data;
            }
        }),
        getAllComplianceNote: builder.query<ApiResponse<ComplianceAPIResponse[]>, { latest?: boolean } | void>({
            query: searchParams => {
                const queryParts = [];
                if (searchParams) {
                    if (searchParams.latest) {
                        queryParts.push(`latest=${encodeURIComponent(searchParams.latest)}`);
                    }
                }
                const queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
                return `api/comliance-note${queryString}`;
            }
        }),
        getComplianceNoteDetail: builder.query<ApiResponse<ComplianceAPIResponse>, string>({
            query: complianceId => `api/comliance-note/${complianceId}`
        }),
        updateComplianceNote: builder.mutation<ApiResponse<ComplianceAPIResponse>, CompliancePayload & { complianceId: string }>({
            query: body => ({
                url: `api/comliance-note/${body.complianceId}`,
                method: "PATCH",
                body
            })
        }),
        postComplianceNote: builder.mutation<ApiResponse<any>, CompliancePayload>({
            query: body => ({
                url: "api/comliance-note/",
                method: "POST",
                body
            })
        }),
        getComplianceSystemPrompt: builder.query<ApiResponse<SystemPromptType<ComplianceFieldTypes>[]>, void>({
            query: () => `api/comliance-note/system-prompt/`
        }),
        updateComplianceSystemPrompt: builder.mutation<ApiResponse<SystemPromptType<ComplianceFieldTypes>>, SystemPromptPayload>({
            query: body => ({
                url: `api/comliance-note/system-prompt/${body.promptId}`,
                method: "PATCH",
                body
            })
        })
    })
});
export const {
    useGenerateComplianceNoteMutation,
    useGetAllComplianceNoteQuery,
    usePostComplianceNoteMutation,
    useGetComplianceNoteDetailQuery,
    useUpdateComplianceNoteMutation,
    useGetComplianceSystemPromptQuery,
    useUpdateComplianceSystemPromptMutation
} = complianceNoteApi;
