import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface BackButtonProps {
    btnText?: string;
    btnSize?: SizeType
};

const BackButton:FC<BackButtonProps> = ({btnText = 'Back', btnSize='small'}) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Button type="primary" size={btnSize} icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
            {btnText}
        </Button>
    );
};

export default BackButton;