export const PURCHASE_DATA_MAPPER: Record<string, any> = {
    id: "websiteTrackingId",
    form_id: "formId",
    "904": "date",
    "186": "firstName",
    "188": "lastName",
    fullName: "fullName",
    "192": "email",
    "193": "phone",
    "9": "purchasingUnder",
    "16": "propertyType",
    "21": "purpose",
    "26": "timeframe",
    "31": "iRStructurePref",
    "36": "repaymentPref",
    "41": "citizenship",
    "46.6": "residency",
    "115": "rent_1",
    "116": "rentFreq_1",
    "126": "currentMortgage_1",
    "56": "maritalStatus",
    "51": "livingStatus",
    "61": "kids",
    "85": "kidsAge_1",
    "96": "kidsAge_2",
    "97": "kidsAge_3",
    "100": "kidsAge_4",
    "102": "kidsAge_5",
    "139": "maxPurchasePrice",
    "148": "stateOfPurchase",
    "153": "holdingPeriod",
    "158": "maxsFundAvailable",
    "165": "preferredAvailable",
    "170.1": "bankSavings_1",
    "170.3": "sharesInv",
    "170.4": "cashGift",
    "175": "austProperties",
    "209": "employment",
    "630": "address_1a",
    "633": "owner_1a",
    "650": "propValue_1a",
    "632": "useType_1a",
    "905": "rent_1a",
    "906": "rentFreq_1a",
    "631": "mortgaged_1a",
    "641": "loanLimit_1a",
    "642": "lender_1a",
    "643": "loanTerm_1a",
    "644": "lr_1a",
    "645": "rateType_1a",
    "656": "address_2a",
    "658": "owner_2a",
    "659": "propValue_2a",
    "660": "useType_2a",
    "909": "rent_2a",
    "911": "rentFreq_2a",
    "657": "mortgaged_2a",
    "702": "loanLimit_2a",
    "703": "lender_2a",
    "704": "loanTerm_2a",
    "705": "lr_2a",
    "706": "rateType_2a",
    "665": "address_3a",
    "719": "rateType_3a",
    "675": "address_4a",
    "673": "owner_4a",
    "672": "propValue_4a",
    "671": "useType_4a",
    "918": "rent_4a",
    "917": "rentFreq_4a",
    "674": "mortgaged_4a",
    "729": "loanLimit_4a",
    "730": "lender_4a",
    "731": "loanTerm_4a",
    "732": "iR_4a",
    "733": "rateType_4a",
    "685": "address_5a",
    "682": "owner_5a",
    "691": "releaseEquity",
    "180": "osProperties",
    "772": "country_1p",
    "1117": "propValue_1p",
    "1118": "mortgageBalance_1p",
    "775": "iR_1p",
    "776": "loanTerm_1p",
    '1119':"1pRent",
    '778':"1pRentFreq",
    '779': "1pOwner",
    "242": "occupation",
    "451": "occupation_2",
    "247": "currency",
    "462": "currencySalary_2",
    "252": "salary",
    "467": "salary_2",
    calMonthSalary_2: "calMonthSalary_2",
    "253": "salaryFreq",
    "468": "salaryFreq_2",
    "258": "allowance",
    "259": "allowanceFreq",
    "473": "allowance_2",
    "474": "allowanceFreq_2",
    "264": "commission",
    "265": "commFreq",
    "479": "commission_2",
    "480": "commFreq_2",
    "270": "bonusThis",
    "271": "bonusLast",
    "276": "seMonth",
    "277": "seYear",
    "282": "industry",
    "287": "currencySelfEmp_1",
    "292": "personalThisYear",
    "293": "personalLastYear",
    "298": "ownership",
    "303": "ebtThisYear_1",
    "304": "ebtLastYear_1",

    "485": "bonusThis_2",
    "486": "bonusLast_2",
    "214": "anyOtherLoan",
    "312": "anyOtherLoanCurrency",
    "313": "anyOtherLoanType",
    "1133": "anyOtherLoanBalance",
    "319": "anyOtherLoanTerms",
    "1134": "anyOtherLoanRepayment",
    "321": "anyOtherLoanFrequency",


    "534": "anyOtherLoan_2",
    "388": "ccCurrency1",
    "221": "ccLimit1",
    "226": "coApplicationStatus",
    hasCoApplicant: "hasCoApplicant",
    "396": "firstName_2",
    "398": "lastName_2",
    fullName_2: "fullName_2",
    "960": "email_2",
    "404": "relationship",
    "409": "citizenship_2",
    "414": "livingTogether",
    "419.6": "residency_2",
    "425": "residenceStatus_2",
    "1143": "rent_2",
    "437":"rentFreq_2",
    "442": "currentMortgage_2",
    "430": "employment_2",
    "anyLiabilities": "anyLiabilities",
    "597": "cardCurrency2",
    "598": "ccLimit_2",
    "231": "leadSource",
    "203": "comments",
    "955": "prOfCountry",
    "957": "privateEducation",
    "962": "subClass",
    "170.5": "propertySalesProceed",
    "1084": "rsuThisYear",
    "1085": "rsuLastYear",
    "1074": "phone_2",
    "1075": "repayment_1p",
    "1049": "useType_1p",
    "236": "jobStartMonth",
    "237": "jobStartYear",
    "456": "jobStartMonth_2",
    "457": "jobStartYear_2",
    "122": "currentMortgage",
    since: "since",
    since_2: "since_2",
    fx_2: "fx_2",

    "170.2": "offset",
    "valuation": "valuation",
    "cashOutReason": "cashOutReason",
    "employer": "employer",
    "employer_2": "employer_2",
    "lender": "lender"
};


export type PurchaseDataMapperType = {
    anyLiabilities_2: string;
    kidsAge_1: string;
    kidsAge_2: string;
    kidsAge_3: string;
    kidsAge_4: string;
    kidsAge_5: string;
  };

interface FormMapper {
    [key: string]: string;
}

export const PURCHASE_TABLE_MAPPER: FormMapper = {
    "date": "Date",
    "firstName": "First name (1)",
    "lastName": "Last name (1)",
    "email": "Email (1)",
    "phone": "Phone",
    "purchasingUnder": "Purchasing under",
    "propertyType": "Property type",
    "purpose": "Purpose",
    "timeframe": "Timeframe",
    "iRStructurePref": "IR Structure Pref",
    "repaymentPref": "Repayment Pref",
    "citizenship":"Citizenhip (1)",
    "residency": "Country Residence (1)",
    "livingStatus": "Residential Status (1)",
    "rent_1": "Rent (1)",
    "rentFreq_1": "Rent Freq(1)",
    "currentMortgage_1": "Current mortgage (1)",
    "maritalStatus": "Marital Status",
    "kids": "No. of children",
    "maxPurchasePrice": "Max Purchase Price",
    "stateOfPurchase": "State Of Purchase",
    "holdingPeriod": "Holding Period",
    "maxsFundAvailable": "Max Funds Available",
    "preferredAvailable": "Preferred Available",
    "bankSavings_1": " Bank savings",
    "offset": "Offset/redraw",
    "sharesInv": "Shares/Inv",
    "cashGift": "Cash Gift",
    "austProperties": "Aust. Properties",

    "employment": "Employment (1)",
    "jobStartMonth": "Job start month (1)",
    "jobStartYear": "Job start year (1)",
    "occupation": "Occupation (1)",
    "currency": "Currency Salary (1)",
    "salary": "Salary (1)",
    "salaryFreq": "Salary Freq (1)",    
    "allowance": "Allowance (1)",
    "allowanceFreq": "Allowance Freq (1)",
    "commission": "Commission (1)",
    "commFreq": "Commission Freq (1)",
    "bonusThis": "Bonus This Year (1)",
    "bonusLast": "Bonus Last Year (1)",
    "seMonth": "SE Month",
    "seYear": "SE Year",
    "industry": "Industry",
    "currencySelfEmp_1": "Currency self-emp (1)",
    "personalThisYear": "Personal this year",
    "personalLastYear": "Personal last year",
    "ownership": "Ownership",
    "ebtThisYear_1": "Ebt this year (1)",
    "ebtLastYear_1": "Ebt last year (1)",

    "anyOtherLoan": "Any other loans? (1)",
    "anyOtherLoanCurrency": "1L Currency 1",
    "anyOtherLoanType": "1L Loan type 1",
    "anyOtherLoanBalance": "1L Loan balance 1",
    "anyOtherLoanTerms": "1L Loan terms 1",
    "anyOtherLoanRepayment": "1L Repayment 1",
    "anyOtherLoanFrequency": "1L Freq.",

    "ccCurrency1": "CC currency",
    "ccLimit1": "CC limit 1",

    "address_1a": "1A Address",
    "owner_1a": "1A Owner",
    "propValue_1a": "1A Prop Value",
    "useType_1a": "1A Use Type",
    "rent_1a": "1A Rent",
    "rentFreq_1a": "1A Rent Freq.",
    "mortgaged_1a": "1A Mortgaged?",
    "loanLimit_1a": "1A Loan Limit",
    "lender_1a": "1A Lender",
    "loanTerm_1a": "1A Loan Term",
    "lr_1a": "1A IR%",
    "rateType_1a": "1A Rate type",
    "address_2a": "2A Address",
    "owner_2a": "2A Owner",
    "propValue_2a": "2A Prop Value",
    "useType_2a": "2A Use Type",
    "rent_2a": "2A Rent",
    "rentFreq_2a": "2A Rent freq.",
    "mortgaged_2a": "2A Mortgaged ?",
    "loanLimit_2a": "2A Loan Limit",
    "lender_2a": "2A Lender",
    "loanTerm_2a": "2A Loan term",
    "lr_2a": "2A IR%",
    "rateType_2a": "2A Rate Type",
    "address_3a": "3A Address",
    "rateType_3a": "3A Rate type",
    "releaseEquity": "Release equity?",
    "osProperties": "O/s properties",
    "country_1p": "1P Country",
    "propValue_1p": "1P Prop Value",
    "mortgageBalance_1p": "1P Mortgage Balance",
    "iR_1p":"1P IR%",
    "loanTerm_1p":"1P Loan Term",
    "1pRent": "1P Rent",
    "1pRentFreq": "1p Rent freq",
    "1pOwner": "1P Owner",


    "coApplicationStatus": "Co-applicant?", 
    "firstName_2": "First name (2)",
    "lastName_2": "Last name (2)",
    "email_2": "Email (2)",
    "relationship": "Relationship",
    "citizenship_2": "Citizenship (2)",
    "livingTogether": "Living together?",
    "residency_2": "Country residence (2)",
    "residenceStatus_2": "Residence Status (2)",
    "rent_2": "Rent (2)",
    "rentFreq_2": "Rent freq (2)",
    "currentMortgage_2": "Current mortgage (2)",
    "employment_2":"employment (2)",
    "jobStartMonth_2": "Job Start month (2)",
    "jobStartYear_2": "Job Start year (2)",
    "occupation_2": "Occupation (2)",
    "currencySalary_2": "Currency salary (2)",
    "salary_2": "Salary (2)",
    "salaryFreq_2" : "Salary frequency (2)",
    "allowance_2": "Allowance (2)",
    "allowanceFreq_2": "Allowance frequency (2)",
    "commission_2": "Commission (2)",
    "commFreq_2": "Commission frequency (2)",
    "bonusThis_2": "Bonus this year (2)",
    "bonusLast_2": "Bonus last year (2)",
    "anyOtherLoan_2": "Any other loan (2)",
    "cardCurrency2": "CC Currency 2",
    "ccLimit_2": "CC Limit 2",
    "phone_2": "Phone 2",
};