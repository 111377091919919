import InfoDisplay from "@/components/UI/InfoDisplay";
import { PURCHASE_DATA_CATEGORY } from "@/pages/deals/constant";
import { Row, Typography, theme } from "antd";
import Title from "antd/es/typography/Title";
import { ReactNode } from "react";

const { useToken } = theme;

const DetailContainer = ({ children }: { children: ReactNode }) => {
    const { token } = useToken();
    return <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>{children}</div>;
};
const DetailTitle = ({ title }: { title: string }) => {
    const { token } = useToken();
    return (
        <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>
            <Title level={4} style={{ fontWeight: "400", margin: "0", padding: "1rem 0" }}>
                {title}
            </Title>
        </div>
    );
};

const DealDetailView = ({ data, tableMapper }: { data: any; tableMapper: any }) => {
    return (
        <>
            <div>
                <DetailContainer>
                    <DetailTitle title="Contact Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.contactDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                <DetailContainer>
                    <DetailTitle title={`Has Co-Applicant - ${data?.coApplicationStatus}`} />
                </DetailContainer>
               {data?.coApplicationStatus === 'Yes'&& <DetailContainer>
                    <DetailTitle title="Co-Applicant Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.coApplicantDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                }
                <DetailContainer>
                    <DetailTitle title="Loan Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.loanDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                <DetailContainer>
                    <DetailTitle title="Purchase Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.purchaseDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Employment Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.employmentDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                {data?.coApplicationStatus === 'Yes'&& <DetailContainer>
                    <DetailTitle title="Co-Applicant Employment Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.employmentDetail_secondary.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                }


                <DetailContainer>
                    <DetailTitle title="Properties Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.propertiesDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                <DetailContainer>
                    <DetailTitle title="2A Properties Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.propertiesDetail_secondary.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                <DetailContainer>
                    <DetailTitle title="Equity Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.equityDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Other Loan Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.otherLoanDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
                
                <DetailContainer>
                    <DetailTitle title="Card Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {PURCHASE_DATA_CATEGORY.cardDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={tableMapper[item]} value={(data[item] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>
            </div>
        </>
    );
};

export default DealDetailView;
