import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "@/store";
import { ApiResponse, ChatResponse } from "@/types";
import { Bank, BankPolicy, ChatSession, FetchReplyPaylod, Message, Policies, Policy } from "../types";

type ChatApiResponse = ApiResponse<ChatResponse> & {
    chat_id: string; 
}
export const policiesAPI = createApi({
    reducerPath: "policiesAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        getPolicies: builder.query<ApiResponse<{banks:Bank[],policies: Policies[] }>, void>({
            query: () => "api/bank-policies/"
        }),
        fetchReply: builder.mutation<ChatApiResponse , FetchReplyPaylod>({
            query: body => ({
                url: `api/bank-policies/query/`,
                method: "POST",
                body
            }),
        }),
        getBanks: builder.query<ApiResponse<Bank[]>, void>({
            query: () => "api/bank-policies/banks/"
        }),
        getPoliciesList: builder.query<ApiResponse<Policy[]>, void>({
            query: () => "api/bank-policies/policies/"
        }),
        getChatSessions: builder.query<ApiResponse<ChatSession[]>, void>({
             query: () => "api/bank-policies/chat-sessions/"
        }),
        getChatSessionMessages: builder.query<ApiResponse<Message[]>, string>({
            query: (session_id) => `api/bank-policies/chat-sessions/${session_id}/messages/`
        }),
    })
});

export const { useGetPoliciesQuery, useFetchReplyMutation, useGetBanksQuery, useGetPoliciesListQuery, useGetChatSessionsQuery, useGetChatSessionMessagesQuery } = policiesAPI;


