import React, { useState } from "react";
import { Button, Flex, Drawer } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NewTemplateUploadForm from "../../components/TemplateComponents/NewTemplateUploadForm";

import { allLenders } from '@/devFrontData/lenders';
import styles from './TemplatesPage.module.scss';

const TemplatesPage = () => {
    const [showUploadModal, setUploadModal] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState("Upload");
    const [formBtnText, setFormBtnText] = useState("Submit");

    const openUpload = () => {
        setDrawerTitle("Upload");
        setUploadModal(true);
    };

    const handleCloseUploadModal = () => {
        setUploadModal(false);
    };

    const handleNewTemplateUpload = () => {
        // Placeholder for upload functionality
    };

    return (
        <>
            <div style={{ display: "block", width: "100%" }}>
                <Flex style={{ marginBottom: "1rem" }} justify="flex">
                    <Button icon={<PlusOutlined />} type="primary" onClick={openUpload}>Upload</Button>
                </Flex>
            </div>

            <div className={styles['lenders-grid']}>
                {allLenders.map((lender) => (
                    <div key={lender.id} >
                        <h2>{lender.name}</h2>
                        {lender.documents && (
                            <ul>
                                {lender.documents.map((doc, index) => (
                                    <li key={index}>{doc}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
           
            <Drawer
                title={drawerTitle}
                placement="right"
                size="large"
                onClose={handleCloseUploadModal}
                open={showUploadModal}>
                    <NewTemplateUploadForm onSubmit={handleNewTemplateUpload} formBtnText={formBtnText}/>
            </Drawer>  
        </>
    );
};

export default TemplatesPage;
