import { FC, forwardRef, RefObject, useEffect, useImperativeHandle, useRef } from "react";

import { Button, Col, Row, Space } from "antd";
import Title from "antd/es/typography/Title";

// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectField from "@/components/Form/SelectField";
import { RadioGroupField } from "@/components/Form";

import { FREQUENCE_OPTIONS, LENDER_UPDATED_OPTIONS, LOAN_STRUCTURE_OPTIONS, RATE_TYPE_OPTIONS, REPAYMENT_OPTIONS } from "@/devFrontData/optionList";
import { DealFormType } from "@/pages/deals/types";
import { themeConfig } from "@/config";
import SubTitle from "@/components/UI/SubTitle";
import { ComplianceAPIResponse } from "../../types";

const preferredLoanFormSchema = yup.object().shape({
    rateType_1a: yup.string().required("Rate Type is required"),
    repaymentType: yup.string().required("Repayment Type is required"),
    repaymentFrequency: yup.string().required("Repayment Frequency is required"),
    offset: yup.string().required("Offset is required"),
    loanStructure: yup.string().required("Loan Structure is required"),
    loanScenarioLender1: yup.string().required("Lender 1 is required"),
    loanScenarioLender2: yup.string(),
    loanScenarioLender3: yup.string()
});
export type PreferredLoanFormType = yup.InferType<typeof preferredLoanFormSchema>;

export interface PreferredLoanFormMethods {
    triggerValidation: () => Promise<{ isValid: boolean; data?: PreferredLoanFormType }>;
}

interface PreferredLoanFormProps {
    handleFormSubmit: (data: PreferredLoanFormType) => void;
    submitRef: RefObject<HTMLButtonElement>;
    dealsData?: Partial<DealFormType> | null;
    complianceGeneratedData?: ComplianceAPIResponse | null;
}
const PreferredLoanForm = forwardRef<PreferredLoanFormMethods, PreferredLoanFormProps>(
    ({ handleFormSubmit, submitRef, dealsData, complianceGeneratedData }, ref) => {
        const {
            control,
            handleSubmit,
            watch,
            setValue,
            getValues,
            trigger,
            formState: { errors }
        } = useForm({
            resolver: yupResolver(preferredLoanFormSchema),
            defaultValues: {
                rateType_1a: "",
                repaymentType: "",
                repaymentFrequency: "",
                offset: ""
            }
        });

        /**
         * Sets deals data to Preferred Loan Form
         */
        useEffect(() => {
            if (dealsData) {
                if (dealsData) {
                    setValue("rateType_1a", dealsData?.rateType_1a ?? "");
                    setValue("offset", dealsData?.offset ?? "");
                }
            }
        }, [dealsData]);

        useEffect(() => {
            if (complianceGeneratedData) {
                setValue("repaymentType", complianceGeneratedData?.repayment_type ?? "");
                setValue("repaymentFrequency", complianceGeneratedData?.repayment_frequency ?? "");
                setValue("loanStructure", complianceGeneratedData?.loan_structure ?? "");
                setValue("loanScenarioLender1", complianceGeneratedData?.loan_scenario_lender_1 ?? "");
                setValue("loanScenarioLender2", complianceGeneratedData?.loan_scenario_lender_2 ?? "");
                setValue("loanScenarioLender3", complianceGeneratedData?.loan_scenario_lender_3 ?? "");
            }
        }, [complianceGeneratedData]);

        useImperativeHandle(ref, () => ({
            triggerValidation: async () => {
                const result = await trigger();
                if (result) {
                    return { isValid: true, data: getValues() };
                }
                return { isValid: false };
            }
        }));

        const onSubmit: SubmitHandler<PreferredLoanFormType> = data => {
            handleFormSubmit(data);
        };

        return (
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={themeConfig.rowGutterMd}>
                        <Col xs={24} md={12}>
                            <SelectField
                                name="rateType_1a"
                                control={control}
                                label="Priority 1 (Rate Type)"
                                placeholder="Rate Type"
                                error={errors.rateType_1a?.message}
                                formDirection="row"
                                options={RATE_TYPE_OPTIONS}
                                required={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectField
                                name="repaymentType"
                                control={control}
                                label="Priority 2 (Repayment Type)"
                                placeholder="Repayment Type"
                                error={errors.repaymentType?.message}
                                formDirection="row"
                                options={REPAYMENT_OPTIONS}
                                required={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectField
                                name="repaymentFrequency"
                                control={control}
                                label="Priority 3 (Repayment Frequency)"
                                placeholder="Repayment Frequency"
                                error={errors.repaymentFrequency?.message}
                                formDirection="row"
                                options={FREQUENCE_OPTIONS}
                                required={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <RadioGroupField
                                name="offset"
                                control={control}
                                label="Offset"
                                error={errors.offset?.message}
                                formDirection="row"
                                required={true}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <SelectField
                                name="loanStructure"
                                control={control}
                                label="Loan Structure"
                                placeholder="Loan Structure"
                                error={errors.loanStructure?.message}
                                formDirection="row"
                                options={LOAN_STRUCTURE_OPTIONS}
                                required={true}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <div style={{ marginBottom: "1rem" }}>
                                <Title style={{ marginTop: "0", marginBottom: "0" }} level={5}>
                                    Loan Scenario Comparison
                                </Title>
                                <SubTitle title="Always select at least 3 lenders" />
                            </div>
                        </Col>

                        <Col xs={24} md={12}>
                            <SelectField
                                name="loanScenarioLender1"
                                control={control}
                                label="Lender 1 (Proceeding With)"
                                placeholder="Lender 1"
                                error={errors.loanScenarioLender1?.message}
                                formDirection="row"
                                options={LENDER_UPDATED_OPTIONS}
                                required={true}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectField
                                name="loanScenarioLender2"
                                control={control}
                                label="Lender 2"
                                placeholder="Lender 2"
                                error={errors.loanScenarioLender2?.message}
                                formDirection="row"
                                options={LENDER_UPDATED_OPTIONS}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectField
                                name="loanScenarioLender3"
                                control={control}
                                label="Lender 3"
                                placeholder="Lender 3"
                                error={errors.loanScenarioLender3?.message}
                                formDirection="row"
                                options={LENDER_UPDATED_OPTIONS}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                                Form Submit
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
);

export default PreferredLoanForm;
