import React, { useRef } from 'react';
import { Button } from 'antd';

// Define the props interface
interface UploadFileButtonProps {
    onUpload: (file: FileList) => void; // Function that handles the file upload
}

// Define the component function with typed props and return type
const UploadFileButton = ({ onUpload }: UploadFileButtonProps): JSX.Element => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            onUpload(files); // Pass all selected files to onUpload
        }
    };

    return (
        <>
            <Button onClick={handleButtonClick}>Upload Files</Button>
            <input
                type="file"
                accept=".pdf,.doc,.docx"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple // Add this attribute to allow multiple file selection
            />
        </>
    );
};

export default UploadFileButton;
