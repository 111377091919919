import { Button, Flex, Input, Tooltip, Typography, Select, Row, Col } from "antd";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "rc-scrollbars";
const { TextArea } = Input;

import styles from "./Policy.module.scss";
import { ArrowUpOutlined, CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { useFetchReplyMutation, useGetBanksQuery, useGetChatSessionMessagesQuery, useGetChatSessionsQuery, useGetPoliciesListQuery } from "../../api/policiesAPI";
import COPILOT from "@/assets/copilot.png";
import CopilotIcon from "./CopilotIcon";
import ChatLoading from "./ChatLoading";
import { displayError } from "@/utils/common.utils";
import { FetchReplyPaylod } from "../../types";
import ChatSidebar from "./ChatSidebar";

const PolicyCopilot = () => {
    const [isTextCopied, setIsTextCopied] = useState(false);
    const [selectedBankId, setSelectedBankId] = useState<string|null>(null);
    const [selectedPolicyId, setSelectedPolicyId] = useState<string|null>(null);
    const [activeChatId, setActiveChatId] = useState<string|null>(null);
    const [activeSessionId, setActiveSessionId] = useState<string|null>(null);

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState<{ role: "user" | "assistant"; text: string }[]>([]);

    const [fetchReply, { data: chatResponse, isLoading }] = useFetchReplyMutation();
    
    const {data: banks, isLoading:banksLoading} = useGetBanksQuery();
    const {data: policies, isLoading: policiesLoading} = useGetPoliciesListQuery();
    const {data: chatSessions, isLoading: chatSessionsLoading} = useGetChatSessionsQuery();
    const {data: sessionMessages, isLoading: sessionMessagesLoading} = useGetChatSessionMessagesQuery(activeSessionId!, {skip: !activeSessionId, refetchOnMountOrArgChange: true });

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSend = async () => {
        if (!message.trim()) return;
        setMessages(prevMessages => [...prevMessages, { role: "user", text: message }]);
        setMessage("");
        let payload = { user_query: message, bank_id: selectedBankId as string, policy_id: selectedPolicyId as string } as FetchReplyPaylod
        if(activeChatId){
            payload.chat_id = activeChatId
        }
        fetchReply(payload).unwrap()
        .then(result => {
            setActiveChatId(result?.chat_id)
            if ("data" in result && result.data) {
                result.data.choices.forEach(choice => {
                    setMessages(prevMessages => [...prevMessages, { role: "assistant", text: choice.message.content }]);
                });
            }
        })
        .catch(err => {
            displayError(err)
        })
        
        
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        console.log("keypress", event);
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };
    const copyToClipboard = (msg: string) => {
        navigator.clipboard.writeText(msg).then(() => {
            setIsTextCopied(true);
            setTimeout(() => setIsTextCopied(false), 3000);
        });
    };

    const handlePolicyChange = (value: string) => {
        setSelectedPolicyId(value);
      };
    
    const handleBankChange = (value: string) => {
        setSelectedBankId(value)
    };

    const handleActiveSession = (id: string) => {
        setActiveSessionId(id);
    };

    const handleCreateNewSession = () => {
        setActiveSessionId(null);
    };

    return (
        <>
          <Row>
            <Col xs={24} md={6}>
                <ChatSidebar isLoading={chatSessionsLoading} sessions={chatSessions?.data} handleActiveSession={handleActiveSession} handleCreateNewSession={handleCreateNewSession} />
            </Col>
            <Col xs={24} md={18}>
                <Flex align="center" style={{height:"calc(100vh - 108px)"}}>
                    <div style={{width:"100%"}}>
                        {messages.length < 1 && (
                            <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                                <div style={{ filter: "drop-shadow(13px 10px 6px #05a2e4)" }}>
                                    <img src={COPILOT} alt="" style={{ width: "75px" }} />
                                </div>
                                <Typography.Title style={{ marginTop: "1rem" }} level={4}>
                                    Your Copilot for work
                                </Typography.Title>
                            </div>
                        )}
                        {messages.length > 0 && (
                            <div style={{ margin: "20px 0", paddingRight: "10px" }}>
                                <Scrollbars className="chat_scrollbar" style={{ height: "calc(100vh - 200px)", overflowX: "hidden" }} autoHide>
                                    <div className={styles.chatContent}>
                                        {messages.map((msg, index) => (
                                            <div key={index} style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }}>
                                                {msg.role === "user" && <Flex justify="flex-end">
                                                        <div className={styles.userMsgBox}>{msg.text}</div>
                                                    </Flex>}

                                                { (msg.role === "assistant" || isLoading) && <div>
                                                        <Flex justify="flex-start" gap={10}>
                                                            <div style={{flex:"0 0 35px"}}>
                                                                <CopilotIcon />
                                                            </div> 
                                                            {isLoading && <ChatLoading /> }
                                                            {msg.role === "assistant" && <div style={{ paddingTop: "5px" }}>{msg.text}</div>}
                                                        </Flex>
                                                        {msg.role === "assistant" &&<div style={{ paddingLeft: "40px" }}>
                                                            <Tooltip title="Copy">
                                                                <Button
                                                                    type="text"
                                                                    size="small"
                                                                    style={{marginTop:"8px"}}
                                                                    icon={isTextCopied ? <CheckOutlined /> : <CopyOutlined />}
                                                                    onClick={() => copyToClipboard(msg.text)}
                                                                />
                                                            </Tooltip>
                                                        </div>}
                                                    </div>}
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                </Scrollbars>
                            </div>
                        )}
                        <Flex style={{marginBottom:"0.5rem"}} justify="center" gap={10}>
                        <Select
                                style={{width:"150px"}}
                                showSearch
                                placeholder="Banks"
                                optionFilterProp="label"
                                onChange={handleBankChange}
                                options={banks?.data ?? []}
                                fieldNames={{label:"name", value:"id"}}
                                popupMatchSelectWidth={false}
                            />
                            <Select
                                style={{width:"150px"}}
                                showSearch
                                placeholder="Policies"
                                optionFilterProp="label"
                                onChange={handlePolicyChange}
                                options={policies?.data ?? []}
                                fieldNames={{label:"name", value:"id"}}
                                popupMatchSelectWidth={false}
                            />
                        </Flex>
                        <div className={styles.inputWrapper}>
                            <TextArea
                                variant="borderless"
                                rows={1}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                autoSize={{ minRows: 1, maxRows: 10 }}
                                onPressEnter={handleKeyPress}
                                placeholder="Message Policy AI"
                                disabled={!selectedBankId && !selectedPolicyId}
                            />

                            <Button type="primary" onClick={handleSend} disabled={!message.trim()} shape="circle" icon={<ArrowUpOutlined />} />
                        </div>
                    </div>
                </Flex>
            </Col>
          </Row>
        </>
    );
};

export default PolicyCopilot;
