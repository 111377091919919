import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Row, Col, Flex, Button, Typography, Breadcrumb } from "antd";
import Title from "antd/es/typography/Title";
import ButtonGroup from "antd/es/button/button-group";
import Scrollbars from "rc-scrollbars";

//hooks
import { useUpdateDeal } from "@/hooks/useUpdateDeal";
//redux
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import {  useGetOpportunityDetailQuery } from "@/store/apis/opportunityApi";
import { Container } from "@/components/Layout";

import { dateTimeTransformer } from "@/utils/common.utils";

//components
import DealForm, { DealFormMethods, FormFieldDataType } from "../components/DealForm";
import { BackButton, CountTitle } from "@/components/UI";

//types
import { BrokerContactType, ContactType, DealFormType, DealInfoType, DealPayload } from "../types";

import { updateDealbreadcrumbList } from "../constant";

const { Text } = Typography;



const DealDetail = () => {
    const { requestId } = useParams<{ requestId: string }>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");

    const dealsFormData = useSelector((state: RootState) => state.opportunityForm);
    const [dealFieldData, setDealFieldData] = useState<DealInfoType | null>(null);
    const [brokerContactData, setBrokerContactData] = useState<BrokerContactType | null>(null);
    const [contactData, setContactData] = useState<ContactType | null>(null);
    const [secondaryContactData, setSecondaryContactData] = useState<ContactType | null>(null);

    const dealFormRef = useRef<DealFormMethods>(null);
    const submitRef = useRef<HTMLButtonElement>(null);

    const [formFieldsData, setFormFieldsData] = useState<FormFieldDataType | null>(null);

    /**
     * fetch deals detail
     */
    const {
        data: dealsData,
        error: dealsDataError,
        isLoading: dealsDataLoading
    } = useGetOpportunityDetailQuery(requestId!, { skip: !requestId || source !== "deals", refetchOnMountOrArgChange: true });

    /**
     * Update deals detail
     */
    const { handleDealUpdate, isLoading, isSuccess } = useUpdateDeal();

    /**
     * Watches for changes in the data fetched from the API request for deals.
     * When the deals data is available, not loading, and a request ID is present:
     * - Resets form fields and related state.
     * - Updates deal-related state with data from `dealsData`.
     * - If primary or secondary contact data is present, updates contact state accordingly.
     * - If additional JSON data exists and has more than two keys, updates form fields data.
     */
    useEffect(() => {
        if (source === "deals" && dealsData && !dealsDataLoading && requestId) {
            setBrokerContactData(null);
            setContactData(null);
            setSecondaryContactData(null);
            setFormFieldsData(null);

            if (dealFormRef.current) {
                dealFormRef.current.resetForm();
            }
            setDealFieldData({
                dealName: dealsData?.name,
                dealType: dealsData?.type
            });

            setBrokerContactData({
                primaryContact: dealsData?.primary_processor?.email ?? "",
                secondaryContact: dealsData?.secondary_processor?.email ?? ""
            });

            if (dealsData.primary_contact) {
                setContactData({
                    name: dealsData?.primary_contact.name,
                    email: dealsData?.primary_contact.email,
                    phone: dealsData?.primary_contact.phone,
                    residency: dealsData?.primary_contact.residency
                });
            }
            if (dealsData.secondary_contact) {
                setSecondaryContactData({
                    name: dealsData?.secondary_contact.name,
                    email: dealsData?.secondary_contact.email,
                    phone: dealsData?.secondary_contact.phone,
                    residency: dealsData?.secondary_contact.residency
                });
            }

            if (dealsData?.json_data && Object.keys(dealsData?.json_data).length > 2) {
                setFormFieldsData(dealsData?.json_data as FormFieldDataType);
            }
        }
    }, [source, dealsData, dealsDataLoading, requestId]);


    /**
     * Triggers DealsForm button to submit the form
     */
    const handleSaveDeal = () => {
        if (submitRef.current) {
            submitRef.current.click();
        } 
    };

    /**
     * Handles the submission of the Deal Form.
     * @param {Object} params - The parameters for the function.
     * @param {DealFormType} params.formData - The data from the Deal Form.
     */
    const handleFormSubmit = ({ formData }: { formData: DealFormType }) => {
        handleDealUpdate({formData, requestId: requestId ?? ""});
    };

    return (
        <>
            <Container fullWidth={true} align="left">
                <Row>
                    <Col xs={24} md={24}>
                        <Flex justify="space-between" align="center" style={{ marginBottom: "1rem" }}>
                      
                                <Breadcrumb>
                                    {updateDealbreadcrumbList.map((breadcrumb, index) => (
                                        <Breadcrumb.Item key={index}>
                                            {breadcrumb.path ? <Link to={breadcrumb.path}>{breadcrumb.title}</Link> : breadcrumb.title}
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            <BackButton />
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Flex justify="space-between" align="center" style={{ marginBottom: "1rem" }}>
                            <div>
                                <Flex gap={15} align="center">
                                    <Title level={4} style={{ margin: "0" }}>
                                        {dealsData?.name}
                                    </Title>
                                    <CountTitle>{dealsData?.stage}</CountTitle>
                                </Flex>
                                
                                <Text style={{ fontSize: "12px" }} color="secondary">
                                    Last Updated: {dateTimeTransformer(dealsData?.updated_at ?? "")}
                                </Text>
                            </div>
                            <ButtonGroup>
                                <Button type="primary" onClick={handleSaveDeal} loading={isLoading}>Save Deal</Button>
                            </ButtonGroup>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Scrollbars style={{ height: "calc(100vh - 180px)" }} autoHide={true}>
                            <DealForm
                                handleFormSubmit={handleFormSubmit}
                                submitRef={submitRef}
                                formFieldsData={formFieldsData}
                                initialValues={dealsFormData}
                                dealData={dealFieldData}
                                brokerContact={brokerContactData}
                                ref={dealFormRef}
                                contactData={contactData}
                                secondaryContactData={secondaryContactData}
                            />
                        </Scrollbars>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DealDetail;
