interface FormMapper {
    [key: string]: string;
}



export const REFINANCE_DATA_MAPPER:{ [key: string]: any } = {
    id: "websiteTrackingId",
    form_id: "formId",
    '919': 'date',
    '16.3': 'firstName',
    '16.6': 'lastName',
    '17': 'email',
    '18': "phone",    
    '1.1': 'lowerRepayment',
    '1.2': 'payOffMortgageFaster',
    '1.3': 'getBetterRate',
    '1.4': "moreLoanFeatures",
    "1.5": "consolidateDebt",
    "1.6": "cashOutEquity",
    "2": "irPreference",
    "3": "irRepaymentPreference",
    "4": "citizenship",
    "974.6": "residency",
    "6": "livingStatus",
    "848": "rent_1",
    "7": "rentFreq_1",
    "858": "currentMortgage",
    "853": "maritalStatus",
    "8": "kids",
    "1156": "kidsAge_1",
    "1157": "kidsAge_2",
    "1158": "kidsAge_3",
    "1159": "kidsAge_4",
    "1160": "kidsAge_5",
    "14": "asProperties",
    "38": "1aAddress",
    "62": "1aMortgaged",
    "78": "1aLoanBalance",
    "79": "1aLR",
    "80": "1aLoanTerm",
    "81": "1aLender",
    "157": "1aFixed",
    "63": "1aOwner",
    "39": "1aPropValue",
    "40": "1aUseType",
    "43": "1aRent",
    "56": "1aRentFreq",
    "65": "1aServiceRequired",
    "74": "1aEquityAmount",
    "72": "1aEquityPurpose",
    "15": "nonAusProperty",
    "248": "1pCountry",
    "250": "1pPropValue",
    "457": "1pMortgageBalance",
    "251": "1pIR",
    "252": "1pLoanTerm",
    "459": "1pRent",
    "253": "1pRentFreq",
    "254": "1pOwner",
    "338": "employment",
    "399": "jobStartMonth",
    "400": "jobStartYear",
    "405": "occupation",
    "411": "currency",
    "481": "salary",
    "482": "salaryFreq",
    "487": "allowance",
    "488": "allowanceFreq",
    "492": "commission",
    "493": "commFreq",

    "499": "bonusThis",
    "500": "bonusLast",
    "505": "seMonth",
    "507": "seYear",
    "512": "industry",
    "517": "seCurrency",
    "521": "personalIncomeThisYear",
    "522": "personalIncomeLastYear",

    "527": "ownership",
    "533": "companyIncomeThisYear",
    "534": "companyIncomeLastYear",

    "341": "anyOtherLoan",
    "542": "anyOtherLoanCurrency",
    "543": "anyOtherLoanType",
    "546": "anyOtherLoanBalance",
    "544": "anyOtherLoanTerms",
    "545": "anyOtherLoanRepayment",
    "547": "anyOtherLoanFrequency",

    "658": "ccCurrency1",
    "659": "ccLimit1",

    "363": "coApplicationStatus",
    "381": "firstName_2",
    "385": "lastName_2",
    "386": "email_2",
    "634": "relationship",

    "638": "citizenship_2",
    "644": "livingTogether",
    "663": "residency_2",
    "669": "residenceStatus_2",
    "684": "rentExpense_2",
    "685": "rentFreq_2",

    "691": "currentMortgage_2",
    "648": "employment_2",
    "698": "jobStartMonth_2",
    "699": "jobStartYear_2",
    "704": "occupation_2",
    "709": "currencySalary_2",
    "716": "salary_2",

    "715": "salaryFreq_2",
    "720": "allowance_2",
    "721": "allowanceFreq_2",
    "726": "commission_2",
    "727": "commFreq_2",
    "732": "bonusThis_2",
    "733": "bonusLast_2",

    "738": "seMonth_2",
    "739": "seYear_2",
    "744": "industry_2",
    "749": "seCurrency_2",
    "754": "personalThisYear_2",
    "755": "personalLastYear_2",
    "760": "ownership_2",

    "765": "companyThisYear_2",
    "766": "companyLastYear_2",
    "653": "anyOtherLoan_2",
    "776": "1LCurrency_2",
    "777": "1LLoanType_2",
    "779": "1LLoanBalance_2",
    "786": "1LLoanTerm_2",

    "780": "1LRepayment_2",
    "781": "1LFreq_2",

    "355": "cardCurrency2",
    "356": "ccLimit_2",
    "973": "privateEducation",
    "1114": "phone_2",
    calMonthSalary_2: "calMonthSalary_2",
    fullName: "fullName",
    fullName_2: "fullName_2",
    hasCoApplicant: "hasCoApplicant",
    since: "since",
    since_2: "since_2",
    fx_2: "fx_2",
    "valuation": "valuation",
    "lender": "lender",
    "purpose": "purpose"
}

export const REFINANCE_TABLE_MAPPER: FormMapper = {
    "date": "Date",
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email",
    "phone": "Phone",
    'lowerRepayment': "Lower Repayment",
    'payOffMortgageFaster': "Pay Off mortgage faster",
    'getBetterRate': "Get on a better rate",
    "moreLoanFeatures": "More Loan features",
    "consolidateDebt": "Consolidate debt",
    "cashOutEquity": "Cash out equity",
    "irPreference": "IR Preference",
    "irRepaymentPreference": "Repayment Preference",
    "citizenship": "Citizenhip (1)",
    "residency": "Country residence (1)",
    "livingStatus": "Residentail status (1)",
    "rent_1": "Rent (1)",
    "rentFreq_1": "Rent Frequency(1)",
    "currentMortgage": "Current Mortgage (1)",
    "maritalStatus": "Marital Status",
    "kids": "No. of children",
    "kidsAge_1": "Child age 1",
    "kidsAge_2": "Child age 2",
    "kidsAge_3": "Child age 3",
    "kidsAge_4": "Child age 4",
    "kidsAge_5": "Child age 5",
    "asProperties": "Australian properties owned",
    "1aAddress": "1A Address",
    "1aMortgaged": "1A Mortgaged?",
    "1aLoanBalance": "1A Loan Balance",
    "1aLR": "1A IR%",
    "1aLoanTerm": "1A Loan term",
    "1aLender": "1A Lender",
    "1aFixed": "1A Fixed?",
    "1aOwner": "1A Owner",
    "1aPropValue": "1A Prop Value",
    "1aUseType": "1A Use Type",
    "1aRent": "1A Rent",
    "1aRentFreq": "1A Rent Freq",
    "1aServiceRequired": "1A Service required?",
    "1aEquityAmount": "1A Equity Amount Req",
    "1aEquityPurpose": "1A Equity Purpose",
    "nonAusProperty": "No. of overseas properties",
    "1pCountry": "1P Country",
    "1pPropValue": "1P Prop Value",
    "1pMortgageBalance": "1P Mortgage Balance",
    "1pIR": "1P IR%",
    "1pLoanTerm": "1P Loan Term",
    "1pRent": "1P Rent",
    "1pRentFreq": "1P Rent freq",
    "1pOwner": "1P Owner",

    "employment": "Employment (1)",
    "jobStartMonth": "Job start month (1)",
    "jobStartYear": "Job start year (1)",
    "occupation": "Occupation (1)",
    "currency": "Currency Salary (1)",
    "salary": "Salary (1)",
    "salaryFreq": "Salary freq (1)",
    "allowance": "Allowance (1)",
    "allowanceFreq": "Allowance freq (1)",
    "commission": "Commission (1)",
    "commFreq": "Commission freq (1)",
    "bonusThis": "Bonuse this year (1)",
    "bonusLast": "Bonuse last year (1)",
    "seMonth": "SE Month",
    "seYear": "SE Year",
    "industry": "Industry",
    "seCurrency": "SE Currency",
    "personalIncomeThisYear": "Personal Income This Year",
    "personalIncomeLastYear": "Personal Income Last Year",

    "ownership": "Ownership",
    "companyIncomeThisYear": "Company Income This Year",
    "companyIncomeLastYear": "Company Income Last Year",

    "anyOtherLoan": "Any other loans? (1)",
    "anyOtherLoanCurrency": "1L Currency (1)",
    "anyOtherLoanType": "1L Loan type (1)",
    "anyOtherLoanBalance": "1L Loan balance (1)",
    "anyOtherLoanTerms": "1L Loan term (1)",
    "anyOtherLoanRepayment": "1l Repayment (1)",
    "anyOtherLoanFrequency": "1L Freq (1)",

    "ccCurrency1": "CC Currency 1",
    "ccLimit1": "CC limit 1",

    "coApplicationStatus": "Co-Applicant?",
    "firstName_2": "First name (2)",
    "lastName_2": "Last name (2)",
    "email_2": "Email (2)",
    "relationship": "Relationship",
    "phone_2": "Phone (2)",
    "citizenship_2": "Citizenship (2)",
    "livingTogether": "Living Together?",
    "residency_2": "Country Residence (2)",
    "residenceStatus_2": "Residential status (2)",
    "rentExpense_2": "Rent Expenses (2)",
    "rentFreq_2": "Rent freq (2)",
    "currentMortgage_2": "Current Mortgage (2)",
    "employment_2": "Employment (2)",
    "jobStartMonth_2": "Job Start Month (2)",
    "jobStartYear_2": "Job Start Year (2)",
    "occupation_2": "Occupation (2)",
    "currencySalary_2": "Currency Salary (2)",
    "salary_2": "Salary (2)",
    "salaryFreq_2": "Salary Freq (2)",
    "allowance_2": "Allowance (2)",
    "allowanceFreq_2": "Allowance freq (2)",
    "commission_2": "Commission (2)",
    "commFreq_2": "Commission freq (2)",
    "bonusThis_2": "Bonus this year (2)",
    "bonusLast_2": "Bonus last year (2)",

    "seMonth_2": "SE Month (2)",
    "seYear_2": "SE Year (2)",
    "industry_2": "Industry (2)",
    "seCurrency_2": "SE Currency (2)",
    "personalThisYear_2": "Personal this year (2)",
    "personalLastYear_2": "Personal last year (2)",
    "ownership_2": "Ownership (2)",

    "companyThisYear_2": "Company This Year (2)",
    "companyLastYear_2": "Company Last Year (2)",
    "anyOtherLoan_2": "Any Other Loan (2)",
    "1LCurrency_2": "1L Currency (2)",
    "1LLoanType_2": "1L Loan Type (2)",
    "1LLoanBalance_2": "1L Loan Balance (2)",
    "1LLoanTerm_2": "1L LoanTerm (2)",
    "1LRepayment_2": "1L Repayment (2)",
    "1LFreq_2": "1L Freq (2)",

    "cardCurrency2": "CC Currency (2)",
    "ccLimit_2_": "CC Limit (2)",
    "privateEducation": "Private Education"
    
};

