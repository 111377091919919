import { message } from "antd";
import dayjs from "dayjs";

export const dateTimeTransformer = (date: Date | string | any): string => {
    // If the input is an empty string or not a valid Date, return an empty string
    if (date === "") {
        return "";
    }

    return dayjs(date).format("MMMM D, YYYY h:mm A");
};

export const dateFormatter = (date: Date | string): string => {
    // If the input is an empty string or not a valid Date, return an empty string
    if (date === "") {
        return "";
    }

    return dayjs(date).format("YYYY-MM-DD");
};
export const displayError = (error: any): void => {
    console.log("error", error);

    const errorData = error?.data?.error;
    if (errorData) {
      if(typeof errorData === "string") {
        message.error(errorData);
      }else {
            const { details, message: errorMessage, statusCode } = errorData;

            if (details) {
                Object.keys(details).forEach(key => {
                    if (typeof details[key] === "object" && !Array.isArray(details[key])) {
                        Object.keys(details[key]).forEach(subKey => {
                            message.error(`${key}.${subKey} - ${details[key][subKey].join(", ")}`);
                        });
                    } else {
                        message.error(`${key} - ${details[key].join(", ")}`);
                    }
                });
            } else if (errorMessage) {
                message.error(errorMessage);
            } else {
                message.error("An unexpected error occurred");
            }
          }
    } else {
        message.error("An error occurred with no additional information provided.");
    }
};

export const displaySuccess = (successMsg: any = "Successfull"): void => {
    message.success(successMsg);
};

export const displayWarning = (warningMsg: any = "Warning"): void => {
    message.warning(warningMsg);
};
export const displayInfo = (infoMsg: any = "Info"): void => {
    message.info(infoMsg);
};

export const dateFormat = "YYYY-MM-DD";

export const splitName = (fullName: string) => {
    const parts = fullName.trim().split(/\s+/);
    if (parts.length === 1) {
        return { firstName: parts[0], lastName: "" };
    }
    const firstName = parts.shift();
    const lastName = parts.join(" ");

    return { firstName, lastName };
};

export const isObject = (variable: any) => {
    return variable !== null && typeof variable === "object";
};

export const deepCopy = <T>(obj: T): T => {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    const newObj: any = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        newObj[key] = deepCopy(obj[key]);
    }

    return newObj;
};

// src/utils/debounce.ts
export const debounce = <T extends (...args: any[]) => void>(func: T, delay: number): ((...args: Parameters<T>) => void) => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<T>) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const convertToNumber = (value: any) => {
    // Check if the value is already a number
    if (typeof value === "number") {
        return value;
    }
    // Handle null or undefined values
    if (value == null) {
        return 0;
    }
    // Convert value to a string and trim whitespace
    const strValue = String(value).trim();

    // Check if the trimmed string is empty or not a valid number
    if (strValue === "" || isNaN(parseFloat(strValue))) {
        return 0;
    }
    return Number(value.replace(/,/g, ""));
};

export const formatTitle = (title: string): string => {
    // Return empty string if input is empty
    if (title.trim() === "") {
        return "";
    }

    // Replace underscores with spaces
    let formattedTitle = title.replace(/_/g, " ");

    // Replace camel case with space-separated words
    formattedTitle = formattedTitle.replace(/([a-z])([A-Z])/g, "$1 $2");

    // Capitalize the first letter of each word
    formattedTitle = formattedTitle.replace(/\b\w/g, char => char.toUpperCase());

    return formattedTitle;
};
