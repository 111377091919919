import { forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { Col, Collapse, Row } from "antd";

import NeedAnalysisForm, { NeedAnalysisFormMethods, NeedAnalysisFormType } from "./NeedAnalysisForm";
import AIContent from "../AIContent";
import { ComplianceAPIResponse, ComplianceFieldTypes } from "../../types";
import PreferredLoanForm, { PreferredLoanFormMethods, PreferredLoanFormType } from "./PreferredLoanForm";

import { FormFieldDataType } from "@/pages/deals/components/DealForm";
import { themeConfig } from "@/config";


export interface ComplianceNotePDFMethods {
    needAnalysisFormMethods: NeedAnalysisFormMethods;
    preferredLoanFormMethods: PreferredLoanFormMethods;
}

interface ComplianceNotePDFProps {
    allValues: Partial<FormFieldDataType> | null;
    generateAIContent: (id: ComplianceFieldTypes) => void;

    loanObjectivesLoading: boolean;
    loanObjectiveData: any;

    loanRequirementsLoading: boolean;
    loanRequirementsData: any;

    loanCircumstancesLoading: boolean;
    loanCircumstancesData: any;

    loanFinancialAwarenessLoading: boolean;
    loanFinancialAwarenessData: any;

    submitNeedAnalysisRef: RefObject<HTMLButtonElement>;
    handleNeedAnalysisForm: (data: NeedAnalysisFormType) => void;
    setNeedAnalysisFormData?: (data: NeedAnalysisFormType) => void;

    submitPreferredLoanFormRef: RefObject<HTMLButtonElement>;
    handlePreferredLoanForm: (data: PreferredLoanFormType) => void;

    loanPrioritisedLoading: boolean;
    loanPrioritisedData: any;

    lenderLoanLoading: boolean;
    lenderLoanData: any;

    loanStructureLoading: boolean;
    loanStructureData: any;

    onAIContentUpdate: (id: ComplianceFieldTypes, updatedContent: string) => void;

    complianceGeneratedData?: ComplianceAPIResponse | null;
    viewUserInputPrompt?: (id: ComplianceFieldTypes) => void;
    viewSystemPrompt: (id: ComplianceFieldTypes) => void;

}

const ComplianceNotePDF = forwardRef<ComplianceNotePDFMethods, ComplianceNotePDFProps>(
    (
        {
            allValues,

            loanObjectivesLoading,
            loanObjectiveData,
            generateAIContent,
            loanRequirementsLoading,
            loanRequirementsData,

            loanCircumstancesLoading,
            loanCircumstancesData,

            loanFinancialAwarenessLoading,
            loanFinancialAwarenessData,

            submitNeedAnalysisRef,
            handleNeedAnalysisForm,
            setNeedAnalysisFormData,

            submitPreferredLoanFormRef,
            handlePreferredLoanForm,

            loanPrioritisedLoading,
            loanPrioritisedData,

            lenderLoanLoading,
            lenderLoanData,

            loanStructureLoading,
            loanStructureData,
            onAIContentUpdate,

            complianceGeneratedData,
            viewUserInputPrompt,
            viewSystemPrompt
        },
        ref
    ) => {
        const needAnalysisFormRef = useRef<NeedAnalysisFormMethods>(null);
        const preferredLoanFormRef = useRef<PreferredLoanFormMethods>(null);
        useImperativeHandle(ref, () => ({
            needAnalysisFormMethods: {
                triggerValidation: () =>
                    needAnalysisFormRef.current ? needAnalysisFormRef.current.triggerValidation() : Promise.resolve({ isValid: false, data: undefined })
            },
            preferredLoanFormMethods: {
                triggerValidation: () =>
                    preferredLoanFormRef.current ? preferredLoanFormRef.current.triggerValidation() : Promise.resolve({ isValid: false, data: undefined })
            }
        }));

        return (
            <div>
                <Row>
                    <Col xs={24} md={24}>
                        <div style={{ marginBottom: "1rem" }}>
                            <Collapse
                                defaultActiveKey={["1"]}
                                items={[
                                    {
                                        key: "1",
                                        label: "Needs Analysis",
                                        children: (
                                            <>
                                                <NeedAnalysisForm
                                                    dealsData={allValues}
                                                    handleFormSubmit={handleNeedAnalysisForm}
                                                    submitRef={submitNeedAnalysisRef}
                                                    setFormData={setNeedAnalysisFormData}
                                                    ref={needAnalysisFormRef}
                                                    complianceGeneratedData={complianceGeneratedData}
                                                />
                                                <Row gutter={themeConfig.rowGutterMd}>
                                                    <Col xs={24} md={12}>
                                                        <AIContent
                                                            title="Loan Objectives"
                                                            paragraph="Provide details of your client conversation in relation to their loan objective"
                                                            id="loan_objectives"
                                                            isLoading={loanObjectivesLoading}
                                                            aiData={loanObjectiveData}
                                                            generateAIContent={generateAIContent}
                                                            onAIContentUpdate={onAIContentUpdate}
                                                            viewUserInputPrompt={viewUserInputPrompt}
                                                            viewSystemPrompt={viewSystemPrompt}
                                                        />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <AIContent
                                                            title="Loan Requirements"
                                                            paragraph="Provide details of you client conversation in relation to their loan requirements"
                                                            id="loan_requirements"
                                                            isLoading={loanRequirementsLoading}
                                                            aiData={loanRequirementsData}
                                                            generateAIContent={generateAIContent}
                                                            onAIContentUpdate={onAIContentUpdate}
                                                            viewUserInputPrompt={viewUserInputPrompt}
                                                            viewSystemPrompt={viewSystemPrompt}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }
                                ]}
                            />
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <Collapse
                                defaultActiveKey={["2"]}
                                items={[
                                    {
                                        key: "2",
                                        label: "Loans, Securities & Commentary",
                                        children: (
                                            <>
                                                <Row gutter={themeConfig.rowGutterMd}>
                                                    <Col xs={24} md={12}>
                                                        <AIContent
                                                            id="loan_circumstances"
                                                            title="Circumstances, Objectives and Priorities"
                                                            isLoading={loanCircumstancesLoading}
                                                            aiData={loanCircumstancesData}
                                                            generateAIContent={generateAIContent}
                                                            onAIContentUpdate={onAIContentUpdate}
                                                            viewUserInputPrompt={viewUserInputPrompt}
                                                            viewSystemPrompt={viewSystemPrompt}
                                                        />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <AIContent
                                                            id="loan_financial_awareness"
                                                            title="Financial Awareness & Practices"
                                                            isLoading={loanFinancialAwarenessLoading}
                                                            aiData={loanFinancialAwarenessData}
                                                            generateAIContent={generateAIContent}
                                                            onAIContentUpdate={onAIContentUpdate}
                                                            viewUserInputPrompt={viewUserInputPrompt}
                                                            viewSystemPrompt={viewSystemPrompt}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }
                                ]}
                            />
                        </div>

                        <div style={{ marginBottom: "1rem" }}>
                            <Collapse
                                defaultActiveKey={["3"]}
                                items={[
                                    {
                                        key: "3",
                                        label: "Preferred Loan Features / Scenarios",
                                        children: (
                                            <>
                                                <PreferredLoanForm
                                                    ref={preferredLoanFormRef}
                                                    dealsData={allValues}
                                                    handleFormSubmit={handlePreferredLoanForm}
                                                    submitRef={submitPreferredLoanFormRef}
                                                    complianceGeneratedData={complianceGeneratedData}
                                                />
                                                <Row gutter={themeConfig.rowGutterMd}>
                                                    <Col xs={24} md={12}>
                                                        <AIContent
                                                            id="lender_loan"
                                                            title="Lender, Loan Amount & Interest Rate"
                                                            isLoading={lenderLoanLoading}
                                                            aiData={lenderLoanData}
                                                            generateAIContent={generateAIContent}
                                                            onAIContentUpdate={onAIContentUpdate}
                                                            viewUserInputPrompt={viewUserInputPrompt}
                                                            viewSystemPrompt={viewSystemPrompt}
                                                        />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <AIContent
                                                            id="loan_structure"
                                                            title="Loan Structure"
                                                            isLoading={loanStructureLoading}
                                                            aiData={loanStructureData}
                                                            generateAIContent={generateAIContent}
                                                            onAIContentUpdate={onAIContentUpdate}
                                                            viewUserInputPrompt={viewUserInputPrompt}
                                                            viewSystemPrompt={viewSystemPrompt}
                                                        />
                                                    </Col>
                                                </Row>

                                                <AIContent
                                                    id="loan_prioritised"
                                                    title="Prioritised Loan Features"
                                                    paragraph="Provide details of your client conversation in relation to their prioritised loan features"
                                                    isLoading={loanPrioritisedLoading}
                                                    aiData={loanPrioritisedData}
                                                    generateAIContent={generateAIContent}
                                                    onAIContentUpdate={onAIContentUpdate}
                                                    viewUserInputPrompt={viewUserInputPrompt}
                                                    viewSystemPrompt={viewSystemPrompt}
                                                />
                                            </>
                                        )
                                    }
                                ]}
                            />
                        </div>
                      
                    </Col>
                </Row>
            </div>
        );
    }
);

export default ComplianceNotePDF;
