import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "@/store";

import { ApiResponse, User } from "@/types";
import { UserPayload } from "../types";


export const accountApi = createApi({
    reducerPath: "accountApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        getUserDetail: builder.query<ApiResponse<Partial<User>>, void>({
            query: () => "api/user/me/"
        }),
        updateUser: builder.mutation<ApiResponse<any>, UserPayload>({
            query: body => ({
                url: "api/user/update/",
                method: "PATCH",
                body
            }),
        }),
    })
});
export const { useGetUserDetailQuery, useUpdateUserMutation } = accountApi;
