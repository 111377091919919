import { MenuType, MenuTypeWithoutIcon } from "../types";

export const MENU_ITEMS: MenuType[] = [
    {
        key: "1",
        label: "Home",
        link: "/",
        icon: "search"
    },
    {
        key: "2",
        label: "Contacts",
        link: "/contacts",
        icon: "user"
    },
    {
        key: "3",
        label: "Discovery",
        link: "/discovery",
        icon: "zoom"
    },
    {
        key: "4",
        label: "Deals",
        link: "/deals",
        icon: "document"
    },
    {
        key: "5",
        label: "Note Generation",
        icon: "form",
        children: [
            {
                key: "5.1",
                label: "Broker Notes",
                link: "/note-generation/broker-notes"
            },
            {
                key: "5.2",
                label: "Compliance Notes",
                link: "/note-generation/compliance-notes"
            },
            {
                key: "5.3",
                label: "Templates",
                link: "/note-generation/templates"
            }
        ]
    },
    {
        key: "7",
        label: "Policies",
        link: "/policies",
        icon: "fileDoneOutlined"
    },
    {
        key: "6",
        label: "Lender Wiki",
        icon: "folder",
        children: [
            {
                key: "6.1",
                label: "ANZ",
                link: "/lender-wiki/ANZ"
            },
            {
                key: "6.2",
                label: "CommBank",
                link: "/lender-wiki/CBA"
            },
            {
                key: "6.3",
                label: "Macquarie",
                link: "/lender-wiki/MACQUARIE"
            },
            {
                key: "6.4",
                label: "Heritage",
                link: "/lender-wiki/HERITAGE"
            },
            {
                key: "6.5",
                label: "BankWest",
                link: "/lender-wiki/BANKWEST"
            },
            {
                key: "6.6",
                label: "BOQ",
                link: "/lender-wiki/BOQ"
            },
            {
                key: "6.7",
                label: "HSBC",
                link: "/lender-wiki/HSBC"
            },
            {
                key: "6.8",
                label: "MAMoney",
                link: "/lender-wiki/MAMONEY"
            },
            {
                key: "6.9",
                label: "NAB",
                link: "/lender-wiki/NAB"
            },
            {
                key: "6.10",
                label: "St.George Bank",
                link: "/lender-wiki/SGB"
            },
            {
                key: "6.11",
                label: "Adelaide Bank",
                link: "/lender-wiki/ADELAIDE"
            },
            {
                key: "6.12",
                label: "Avantedge",
                link: "/lender-wiki/ADVANTEDGE"
            },
            {
                key: "6.13",
                label: "AFG",
                link: "/lender-wiki/AFG"
            },
            {
                key: "6.14",
                label: "AMP",
                link: "/lender-wiki/AMP"
            },
            {
                key: "6.15",
                label: "BankAu",
                link: "/lender-wiki/BANKAU"
            },
            {
                key: "6.16",
                label: "Bluestone",
                link: "/lender-wiki/BLUESTONE"
            },
            {
                key: "6.17",
                label: "Bank of Sydney",
                link: "/lender-wiki/BOS"
            },
            {
                key: "6.18",
                label: "Firstmac",
                link: "/lender-wiki/FIRSTMAC"
            },
            {
                key: "6.19",
                label: "Gateway",
                link: "/lender-wiki/GATEWAY"
            },
            {
                key: "6.20",
                label: "ING",
                link: "/lender-wiki/ING"
            },
            {
                key: "6.21",
                label: "LaTrobe",
                link: "/lender-wiki/LATROBE"
            },
            {
                key: "6.22",
                label: "Liberty",
                link: "/lender-wiki/LIBERTY"
            },
            {
                key: "6.23",
                label: "ME Bank",
                link: "/lender-wiki/MEBANK"
            },
            {
                key: "6.24",
                label: "MyState Bank",
                link: "/lender-wiki/MYSTATE"
            },
            {
                key: "6.25",
                label: "Pepper",
                link: "/lender-wiki/PEPPER"
            },
            {
                key: "6.26",
                label: "Resimac",
                link: "/lender-wiki/RESIMAC"
            },
            {
                key: "6.27",
                label: "Suncorp",
                link: "/lender-wiki/SUNCORP"
            },
            {
                key: "6.28",
                label: "Thinktank",
                link: "/lender-wiki/THINKTANK"
            },
            {
                key: "6.29",
                label: "Teachers Mutual Bank",
                link: "/lender-wiki/TMB"
            },
            {
                key: "6.30",
                label: "UBank",
                link: "/lender-wiki/UBANK"
            },
            {
                key: "6.31",
                label: "Virgin Money",
                link: "/lender-wiki/VIRGIN"
            },
            {
                key: "6.32",
                label: "Westpac",
                link: "/lender-wiki/WESTPAC"
            },
        ]
    }
];

export const FOOTER_MENU: MenuTypeWithoutIcon[] = [
    {
        key: "1",
        label: "Pro",
        link: "/pricing"
    },
    {
        key: "2",
        label: "Blog",
        link: "/blog"
    }
];
