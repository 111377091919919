import { FC } from "react";
import DetailViewContainer from "./DetailViewContainer";
import DetailViewTitle from "./DetailViewTitle";
import { Row } from "antd";
import InfoDisplay from "../InfoDisplay";



interface DetailViewPanelProps {
    title: string;
    hasBody?: boolean;
    // categorySource?: {[key:string]: string[] | string};
    categorySource: any;
    category?: string;
    data?: Record<string, any>;
    tableMapper?: Record<string, string>;
}

const DetailViewPanel:FC<DetailViewPanelProps> = ({title, hasBody=true, categorySource, category, tableMapper, data}) => {
    return (
        <>
            <div>
                <DetailViewContainer>
                    <DetailViewTitle title={title} />
                    {hasBody && categorySource && category &&  tableMapper && data && <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                                // @ts-ignore
                                {Array.isArray(categorySource[category]) && categorySource[category].map((item: string, index: number) => (
                                    <InfoDisplay
                                        key={index}
                                        label={tableMapper?.[item] ?? ''}
                                        value={typeof data[item] === 'string' ? data[item] : ''}
                                    />
                                ))}
                        </Row>
                    </div>}                    
                </DetailViewContainer>
            </div>
        </>
    );
};

export default DetailViewPanel;