
export const CASH_OUT_INVOLVED_OPTIONS = [
    { value: "assist_with_the_property_purchase_transaction", label: "Assist with the property purchase transaction" },
    { value: "non_structural_renovation_of_existing_properties", label: "Non-structural renovation of existing properties" },
    { value: "to_cash_buy_future_australian_property_outright", label: "To cash buy future Australian property outright" },
]

export const LOAN_STRUCTURE_OPTIONS = [
    {value:"standalone", label:"standalone"},
    {value:"cross_collateralised", label:"cross-collateralised"},
    {value:"multiple_securities", label:"multiple securities"}
]

export const BROKER_ROLE = [
    {value:"broker", label:"Broker"},
    {value:"processor", label:"Processor"}
]
export const DEAL_TYPE = [
    {value:"purchase", label:"Purchase"},
    {value:"refinance", label:"Refinance"},
]

export const SALARY_FREQUENCY = [
    {value:"annually", label:"Annually"},
    {value:"weekly", label:"Weekly"},
    {value:"fortnightly", label:"Fortnightly"},
]
export const OTHER_LOANS = [
    {value: "no", label: "No"},
    {value: "one", label: "One"},
    {value: "two", label: "Two"},
    {value: "three", label: "Three"},
    {value: "four", label: "Four"},
    {value: "five", label: "Five"}
]


export const DOCUMENT_IDENTIFICATION_OPTIONS = [
    { label: "Face to Face", value: "face_to_face" },
    { label: "Certified Persons", value: "certified_persons" },
    { label: "Branch", value: "branch" },
    { label: "Video over Internet", value: "video_over_internet" }
]

export const CLIENT_INTERVIEW_OPTIONS = [
    { label: "Face to Face", value: "face_to_face" },
    { label: "Video", value: "video" },
    { label: "Telephone", value: "telephone" },
    { label: "Email", value: "email" }
]
export const RATE_TYPE_OPTIONS = [
    { label: "Fixed Rate", value: "fixed_rate" },
    { label: "Variable Rate", value: "variable_rate" },
    { label: "Fixed & Variable Rate", value: "fixed_and_variable_rate" },
]

export const REPAYMENT_OPTIONS = [
    { label: "Interest Only", value: "interest_only" },
    { label: "Principal & Interest", value: "p_and_l_repayments" }
]

export const FREQUENCE_OPTIONS = [
    { label: "Monthly", value: "monthly" },
    { label: "Fortnightly", value: "fortnightly" },
    { label: "Weekly", value: "weekly" }
]

export const LENDER_UPDATED_OPTIONS = [
    { value: "aaa_finance", label: "AAA Finance" },
    { value: "anz_bank", label: "ANZ Bank" },
    { value: "boq", label: "BOQ" },
    { value: "cba", label: "CBA"  },
    { value: "heritage", label: "Heritage"  },
    { value: "st_george_bank", label: "St. George Bank" },
    { value: "macquarie_bank", label: "Macquarie Bank" },
    { value: "hsbc", label: "HSBC" },
    { value: "nab", label: "NAB"  },
    { value: "suncorp", label: "Suncorp"  },
    { value: "westpac", label: "Westpac"  },
    { value: "bc_invest", label: "BC Invest" },
    { value: "brighten", label: "Brighten"  },
    { value: "la_trobe", label: "LaTrobe"  },
    { value: "mezy", label: "MEZY"  },
    { value: "ma_money", label: "MA Money"  },
    { value: "bankwest", label: "Bankwest"  }
]

export const LOAN_PRICING_OPTIONS= [
    { value: "yes_applicable", label: "Yes, applicable" },
    { value: "not_yet_requested", label: "Not yet requested" },
    { value: "na", label: "N/A" },
]

export const LOAN_PURPOSE_OPTIONS = [
    {
        label: "Investment Property",
        value: "investment_property"
    },
    
    {
        label: "Investment Vacant Land",
        value: "investment_vacant_land"
    },
    {
        label: "Investment House & Land Construction",
        value: "investment_house_and_land_construction"
    },
    {
        label: "Owner-occupier Property Purchase",
        value: "owner_occupier_property_purchase"
    },
    {
        label: "Owner-occupier Property Pre-approval",
        value: "owner_occupier_property_pre_approval"
    },
    {
        label: "Owner-occupier Property Refinance",
        value: "owner_occupier_property_refinance"
    },
    {
        label: "Owner-occupier Property Refinance + Equity Release",
        value: "owner_occupier_property_refinance_equity_release"
    },
    {
        label: "Owner-occupier Property Equity Cash-out",
        value: "owner_occupier_property_equity_cash_out"
    },
    {
        label: "Owner-occupier Vacant Land",
        value: "owner_occupier_vacant_land"
    },
    {
        label: "Owner-Occupier House & Land Construction",
        value: "owner_occupier_house_and_land_construction"
    }
];

export const VALUATION_OPTIONS = [
    { value: "na_aip", label: "N/A, AIP" },
    { value: "completed", label: "Completed" },
    { value: "ordered", label: "Ordered" },
    { value: "to_be_ordered", label: "To Be Ordered" },
]

export const LIVING_SITUATION_OPTIONS = [
    {value: "renting", label: "I'm renting"},
    {value: "own_place", label: "I live in my own place"},
    {value: "rent_free_boarding_parent", label: "I live rent free / boarding (parent)"},
    {value: "rent_free_boarding_spouse", label: "I live rent free / boarding (spouse)"},
    {value: "rent_free_boarding_friend", label: "I live rent free / boarding (friend)"},
    {value: "rent_free_employer_provides", label: "I live rent free (employer provides)"}
];

/**
 * Retrieves the label associated with a given value from an array of options.
 *
 * This function searches through an array of options, each an object with a 'value' and 'label' property,
 * to find the label corresponding to the provided value. If no matching value is found, it returns an empty string.
 *
 * @param {string} value - The value for which the label is to be found.
 * @param {Array<{value: string, label: string}>} options - An array of objects where each object contains
 *        a 'value' and 'label' property.
 * @returns {string} The label associated with the input value, or an empty string if no match is found.
 */
export const getOptionLabel = (value:string, options:{"value":string, label:string}[]) => {
    const label = options.find(option => option.value === value)?.label ?? "";
    return label;
};

export const getOptionValue = (label:string, options:{value:string, label:string}[]) => {
    const value = options.find(option => option.label === label)?.value ?? "";
    return value;
};
