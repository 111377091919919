export const complianceBreadcrumbList = [
    {
        path: "/note-generation/compliance-notes",
        title: "Compliance Notes",
    },
    {
        title: "Generate"
    }
];

export const brokerNoteBreadcrumbList = [
    {
        path: "/note-generation/broker-notes",
        title: "Broker Notes",
    },
    {
        title: "Generate"
    }
];