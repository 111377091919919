import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "@/pages/layout/Layout";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import Loader from "../components/UI/Loader";
import { routesConfig } from "../config/route.config";
import { RouteConfig } from "@/types";
import { NoteGenerationRoutes } from "@/pages/noteGeneration";
import { LenderRoutes } from "@/pages/lender";
import { DealsRoutes } from "@/pages/deals";
import { ContactPage } from "@/pages/contacts";
import { PoliciesRoutes } from "@/pages/policies";

const renderRoute = (routeConfig: RouteConfig) => {
    const Element = routeConfig.element === "Layout" ? Layout : routeConfig.element;

    const RouteElement = routeConfig.protected ? (
        <ProtectedRoute>
            <Element />
        </ProtectedRoute>
    ) : (
        <Element />
    );

    return (
        <Route
            key={routeConfig.path}
            path={routeConfig.path}
            element={<React.Suspense fallback={routeConfig.fallback || <Loader />}>{RouteElement}</React.Suspense>}
        >
            {routeConfig.children && routeConfig.children.map(renderRoute)}
        </Route>
    );
};

const AppRouter: React.FC = () => {
    return (
        <Routes>
            {routesConfig.map(renderRoute)}
            <Route
                element={
                    <ProtectedRoute>
                        <Layout />
                    </ProtectedRoute>
                }
            >
                <Route path="/note-generation/*" element={<NoteGenerationRoutes />} />
                <Route path="/lender-wiki/*" element={<LenderRoutes />} />
                <Route path="/contacts" element={<ContactPage />} />
                <Route path="/deals/*" element={<DealsRoutes />} />
                <Route path="/policies/*" element={<PoliciesRoutes />} />
            </Route>
        </Routes>
    );
};

export default AppRouter;
