import { FC, useState } from "react";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

//Components
import PurchaseList from "./components/PurchaseList";
import RefinanceList from "./components/RefinanceList";


interface WebsiteDataListProps {
    getSelectedRowId?: (id: string) => void;
    handleCreateDeal?: (id:string) => void;
}
const WebsiteDataList: FC<WebsiteDataListProps> = ({ getSelectedRowId, handleCreateDeal}) => {
    const [activeTab, setActiveTab] = useState("purchase");
    const onChange = (key: string) => {
        setActiveTab(key)
      };
      
      const items: TabsProps['items'] = [
        {
          key: 'purchase',
          label: 'Online Purchase',
          children: <PurchaseList getSelectedRowId={getSelectedRowId} handleCreateDeal={handleCreateDeal} />,
        },
        {
          key: 'refinance',
          label: 'Online Refinance',
          children: <RefinanceList getSelectedRowId={getSelectedRowId} handleCreateDeal={handleCreateDeal} />,
        }
      ];
    return(
        <div className="raw-data-wrapper">
            <Tabs defaultActiveKey="purchase" items={items} onChange={onChange} />
        </div>
    )
}

export default WebsiteDataList;
