import { CopyOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip, Typography, theme } from "antd";
import { FC, useState } from "react";

const { useToken } = theme;
const { Text } = Typography;

interface InfoDisplayProps {
    label: string;
    value: string;
    leftColValue?: number;
    rightColValue?: number;
    labelAlign?: "left" | "right";
}

const InfoDisplay: FC<InfoDisplayProps> = ({ label, value, leftColValue = 12, rightColValue = 12, labelAlign = "right" }) => {
    const { token } = useToken();
    const [isHovered, setIsHovered] = useState(false);

    const handleCopy = async () => {
        try {
          await navigator.clipboard.writeText(value);
        } catch (err) {
          console.error('Failed to copy:', err);
        }
      };

    return (
        <Col xs={12} md={12}>
            <Row gutter={14}>
                <Col xs={24} md={leftColValue} style={{ textAlign: labelAlign }}>
                    <Text style={{ fontSize: "14px", fontWeight: "600" }}>{label} :</Text>
                </Col>
                <Col xs={24} md={rightColValue}>
                    <div
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ position: "relative", display: "inline-block" }}
                    >
                        <Text style={{ fontSize: "14px", color: token.colorTextSecondary }}>{value}</Text>
                        {isHovered && (
                            <Tooltip title="Copy" placement="top">
                                <CopyOutlined onClick={handleCopy} style={{ marginLeft: 8, cursor: "pointer", color: token.colorTextSecondary }} />
                            </Tooltip>
                        )}
                    </div>
                </Col>
            </Row>
        </Col>
    );
};

export default InfoDisplay;
