import { ReactNode } from "react";
import { Collapse } from "antd";

const BorderedDiv = ({ children }: { children: ReactNode }) => {
    return <div style={{ borderBottom: "1px solid #d9d9d9" }}>{children}</div>;
};

const CLabel = ({ title }: { title: string }) => {
    return <span style={{ fontWeight: "bold" }}>{title}</span>;
};

const DealSidebar = () => {
    return (
        <div style={{ marginBottom: "1rem" }}>
            <BorderedDiv>
                <Collapse
                    ghost
                    defaultActiveKey={["1"]}
                    items={[
                        {
                            key: "1",
                            label: <CLabel title="Broker Notes" />,
                            children: <div>Purchase Broker Notes</div>
                        }
                    ]}
                />
            </BorderedDiv>
            <BorderedDiv>
                <Collapse
                    ghost
                    defaultActiveKey={["2"]}
                    items={[
                        {
                            key: "2",
                            label: <CLabel title="Compliances Notes" />,
                            children: <div>Compliances Notes</div>
                        }
                    ]}
                />
            </BorderedDiv>

            <BorderedDiv>
                <Collapse
                    ghost
                    defaultActiveKey={["3"]}
                    items={[
                        {
                            key: "3",
                            label: <CLabel title="Templates" />,
                            children: <div>Gift Letter</div>
                        }
                    ]}
                />
            </BorderedDiv>

            <BorderedDiv>
                <Collapse
                    ghost
                    defaultActiveKey={["4"]}
                    items={[
                        {
                            key: "4",
                            label: <CLabel title="Query History" />,
                            children: <div>History List</div>
                        }
                    ]}
                />
            </BorderedDiv>

            <BorderedDiv>
                <Collapse
                    ghost
                    defaultActiveKey={["5"]}
                    items={[
                        {
                            key: "5",
                            label: <CLabel title="Others" />,
                            children: <div>Other Items</div>
                        }
                    ]}
                />
            </BorderedDiv>
        </div>
    );
};

export default DealSidebar;
