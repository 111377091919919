import { ReactNode } from "react";
import { theme } from "antd";

const { useToken } = theme;
const DetailViewContainer = ({ children }: { children: ReactNode }) => {
    const { token } = useToken();
    return <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>{children}</div>;
};

export default DetailViewContainer;
