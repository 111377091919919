import { forwardRef, RefObject, useEffect, useImperativeHandle } from "react";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DatePickerField, InputField, RadioGroupField, SelectField } from "@/components/Form";
import { Button, Row, Col } from "antd";
import Title from "antd/es/typography/Title";
import { DealFormType } from "@/pages/deals/types";
import { CASH_OUT_INVOLVED_OPTIONS, LENDER_UPDATED_OPTIONS, LOAN_PRICING_OPTIONS, LOAN_PURPOSE_OPTIONS, VALUATION_OPTIONS } from "@/devFrontData/optionList";
import { BrokerNoteResponse } from "../../types";

const loanDetailFormSchema = yup.object().shape({
    financeDue: yup.string(),
    settlementDate: yup.string(),
    loanDetailAddress: yup.string().required("Address is required"),
    lvr: yup.number().required("LVR is required"),
    purpose: yup.string().required("Purpose is required"),
    lender: yup.string().required("Lender is required"),
    cashOutReason: yup.string().required("Cash out reason is required"),
    pricing: yup.string().required("Pricing is required"),
    interestRate: yup.number().required("Interest rate is required").max(100, "Interest rate cannot exceed 100"),
    valuation: yup.string().required("Valuation is required"),
    propertyValue: yup.number().required("Property value is required"),
    loanAmount: yup.number().required("Property value is required"),
    offset: yup.string().required("Offset is required"),
    product: yup.string().required("Product is required"),
    loanTerm: yup.number().required("Loan term is required").max(100, "Loan term cannot exceed 100"),
});
export type LoanDetailFormType = yup.InferType<typeof loanDetailFormSchema>;

export interface LoanDetailFormMethods {
    triggerValidation: () => Promise<{ isValid: boolean; data?: LoanDetailFormType }>;
}

interface LoanDetailFormProps {
    handleFormSubmit: (data: LoanDetailFormType) => void;
    submitRef: RefObject<HTMLButtonElement>;
    setFormData?: (data: LoanDetailFormType) => void;
    dealsData?: Partial<DealFormType> | null;
    brokerNoteAPIDetail: BrokerNoteResponse | null;
}
const LoanDetailForm = forwardRef<LoanDetailFormMethods, LoanDetailFormProps>(({ handleFormSubmit, submitRef, setFormData, dealsData, brokerNoteAPIDetail }, ref) => {
    const {
        control,
        handleSubmit,
        watch,
        trigger,
        setFocus,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(loanDetailFormSchema),
        defaultValues: {
            financeDue: "",
            settlementDate: "",
            loanDetailAddress: ""
        }
    });

    useImperativeHandle(ref, () => ({
        triggerValidation: async () => {
            const result = await trigger();
            if (result) {
                return { isValid: true, data: getValues() };
            }
            return { isValid: false };
        }
    }));
    /**
     * When error is encountered it scrolls to the error field
     */
    useEffect(() => {
        const firstErrorField = Object.keys(errors)[0];
        if (firstErrorField) {
            setFocus(firstErrorField as keyof LoanDetailFormType);
        }
    }, [Object.keys(errors).length]);

    /**
     ** Passes the updated fields value to the parent component
     */
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            if (setFormData) {
                setFormData(updatedData);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

   

    /**
     * Sets deals data to Need Analysis Field
     */
    useEffect(() => {
        if(dealsData) {
            setValue("loanDetailAddress", dealsData?.security ?? '')
            setValue("purpose", dealsData?.purpose ?? '')
            setValue("lender", dealsData?.lender ?? '')
            if (dealsData?.lvr !== undefined){
                setValue('lvr', dealsData?.lvr)
            }
            setValue("cashOutReason", dealsData?.cashOutReason ?? '')
            setValue("pricing", dealsData?.pricing?? '')
            if (dealsData?.interestRate !== undefined){
                setValue('interestRate', dealsData?.interestRate)
            }
            setValue("valuation", dealsData?.valuation?? '')
            if (dealsData?.propertyValue !== undefined){
                setValue('propertyValue', dealsData?.propertyValue)
            }
            if (dealsData?.loanAmount !== undefined){
                setValue('loanAmount', dealsData?.loanAmount)
            }
            setValue("offset", dealsData?.offset ?? '')
            setValue("product", dealsData?.product ?? '')
            setValue("loanTerm", dealsData?.loanTerm ?? 0)
        }
        
    },[dealsData])

    useEffect(() => {
        if(brokerNoteAPIDetail){
            setValue("financeDue", brokerNoteAPIDetail?.loan_details?.finance_due_date)
            setValue("settlementDate", brokerNoteAPIDetail?.loan_details?.settlement_date)
            setValue("loanDetailAddress", brokerNoteAPIDetail?.loan_details?.loan_detail_address ?? "")
            console.log("loanDetailAddress", brokerNoteAPIDetail?.loan_details?.loan_detail_address)
        }
    }, [brokerNoteAPIDetail])

    const onSubmit: SubmitHandler<LoanDetailFormType> = data => {
        handleFormSubmit(data);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Title level={5}>Loan Details (Applicant 1)</Title>
                <Row gutter={20}>
                    <Col xs={24} md={8}>
                        <DatePickerField formDirection="row" control={control} label="Finance Due" name="financeDue" error={errors.financeDue?.message} />
                    </Col>
                    <Col xs={24} md={8}>
                        <DatePickerField
                            formDirection="row"
                            control={control}
                            label="Settlement Date"
                            name="settlementDate"
                            error={errors.settlementDate?.message}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <InputField
                            label="Address"
                            name="loanDetailAddress"
                            control={control}
                            error={errors.loanDetailAddress?.message ?? ""}
                            formDirection="row"
                            required={true}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <InputField
                            label="Loan Term (Years)"
                            name="loanTerm"
                            control={control}
                            error={errors.loanTerm?.message ?? ""}
                            formDirection="row"
                            required={true}
                            type="number"
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <InputField
                            label="LVR"
                            name="lvr"
                            control={control}
                            error={errors.lvr?.message ?? ""}
                            formDirection="row"
                            required={true}
                            suffix="%"
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <SelectField
                            required={true}
                            formDirection="row"
                            name="purpose"
                            control={control}
                            label="Purpose"
                            placeholder="Purpose"
                            error={errors.purpose?.message}
                            options={LOAN_PURPOSE_OPTIONS}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <SelectField
                            required={true}
                            formDirection="row"
                            name="lender"
                            control={control}
                            label="Lender"
                            placeholder="lender"
                            error={errors.lender?.message}
                            options={LENDER_UPDATED_OPTIONS}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <SelectField
                            name="cashOutReason"
                            control={control}
                            label="Cash-Out Involved? (If Applicable)"
                            placeholder="Cash-Out Involved"
                            error={errors.cashOutReason?.message}
                            formDirection="row"
                            options={CASH_OUT_INVOLVED_OPTIONS}
                            required={true}
                            popupMatchSelectWidth={false}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <SelectField
                            name="pricing"
                            control={control}
                            label="Pricing"
                            placeholder="Pricing"
                            error={errors.pricing?.message}
                            options={LOAN_PRICING_OPTIONS}
                            required={true}
                            formDirection="row"
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <InputField 
                            label="Interest rate" 
                            type="number" 
                            name="interestRate" 
                            placeholder="interest rate"
                            control={control} 
                            error={errors.interestRate?.message ?? ""}
                            required={true}
                            formDirection="row"
                            suffix="%"
                         />
                    </Col>
                    <Col xs={24} md={8}>
                        <SelectField
                            name="valuation"
                            control={control}
                            label="Valuation"
                            placeholder="valuation"
                            error={errors.valuation?.message}
                            options={VALUATION_OPTIONS}
                            required={true}
                            formDirection="row"
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <InputField 
                            label="Property Value" 
                            name="propertyValue" 
                            type="number" 
                            control={control} 
                            error={errors.propertyValue?.message ?? ""} 
                            required={true}
                            formDirection="row"
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <InputField 
                            label="Loan Amount" 
                            name="loanAmount" 
                            type="number" 
                            control={control} 
                            error={errors.loanAmount?.message ?? ""} 
                            required={true}
                            formDirection="row"
                            prefix="$"
                         />
                    </Col>
                    <Col xs={24} md={8}>
                        <RadioGroupField
                                name="offset"
                                control={control}
                                label="Offset"
                                error={errors.offset?.message}
                                required={true}
                                formDirection="row"
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            /> 
                     </Col>
                     <Col xs={24} md={8}>
                        <InputField 
                            label="Product" 
                            name="product" 
                            control={control} 
                            error={errors.product?.message ?? ""}  
                            required={true}
                            formDirection="row"
                        />
                    </Col>
                </Row>
                <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                    Form Submit
                </Button>
            </form>
        </div>
    );
});

export default LoanDetailForm;
