
export const creatDealbreadcrumbList = [
    {
        path: "/deals",
        title: "Deals"
    },
    {
        title: "Create Deal"
    }
];

export const updateDealbreadcrumbList = [
    {
        path: "/deals",
        title: "Deals"
    },
    {
        title: "Update Deal"
    }
];

export const DEAL_CATEGORY = {
    dealInfo: ["dealName", "dealType"],
    brokerDetail: ["brokerPrimaryContact", "brokerSecondaryContact"],
    contactDetail: ["contactEmail", "contactFullName", "contactPhone", "contactCitizenship", "contactResidency"],
    coApplicantDetail: [
        "secondaryContactEmail",
        "secondaryContactFullName",
        "secondaryContactPhone",
        "secondaryContactCitizenship",
        "secondaryContactResidency"
    ],

    loanDetail: [
        "date",
        "propertyValue",
        "loanAmount",
        "loanTerm",
        "product",
        "lender",
        "interestRate",
        "purpose",
        "lvr",
        "valuation",
        "pricing",
        "offset",
        "currentMortgage_1",
        "cashOutAmount",
        "anyLiabilities"
    ],
    applicantOccupation: [
        "occupation",
        "employment",
        "employer",
        "since",
        "currency",
        "fx",
        "salary",
        "salaryFreq",
        "calMonthSalary",
        "salaryDeduction",
        "allowances",
        "allowanceFreq",
        "calcAllowance",
        "commission",
        "commFreq",
        "calcCommission",
        "bonusThis",
        "bonusLast"
    ],
    coApplicantOccupation: [
        'occupation_2',
        'employment_2',
        'since_2',
        'currencySalary_2',
        'fx_2',
        'salary_2',
        'salaryFreq_2',
        'allowances_2',
        'calcAllowance2',
        'commission_2',
        'commFreq_2',
        'calcCommission2',
        'bonusThis_2',
        'bonusLast_2'
    ],
    familyDetails: [
        'livingStatus',
        'rentalExpense',
        'maritalStatus',
        'Kids',
        'kidsAge',
        'privateEducation',
        'purposeEquity',
        'security',
        'aipRentalyiel',
        'apiRent',
        'reduceLimit',
        'existingCustomer'
    ],
    cardDetail: [
        'ccLimit1',
        'ccCurrency1',
        'cardFXConversion1',
        'curentCCLimit1',
        'ccLimit2',
        'cardCurrency2',
        'cardFXConversion2',
        'curentCCLimit2',
        'ccTotalLimit',
        'ccNewLimit'
    ]
};

export const PURCHASE_TABLE_UTILS = {
    contactDetail: ['email'],
    coApplicantDetail: ['email_2'],
    loanDetail: ['date', 'purchasingUnder', 'purpose'],
    purchaseDetail: ['maxPurchasePrice'],
    propertiesDetail: ['austProperties'],
    propertiesDetail_secondary: ['address_2a'],
    equityDetail: ['osProperties'],
    employmentDetail: ['employment', 'occupation'],
    employmentDetail_secondary: ['employment_2', 'occupation_2'],
    otherLoanDetail: ['anyOtherLoan'],
    cardDetail: ['ccCurrency1', 'ccLimit1']
}
export const PURCHASE_DATA_CATEGORY = {
    contactDetail: ["firstName", "lastName", "email", "phone", "citizenship", "residency"],
    hasCoapplicant: "coApplicationStatus",
    coApplicantDetail: ["firstName_2", "lastName_2", "email_2", "phone_2", "citizenship_2", "residency_2"],
    loanDetail: ["date", "purchasingUnder", "propValue_1a", "propertyType", "purpose", "timeframe", "iRStructurePref", "repaymentPref", "currentMortgage_1"
     ],
    purchaseDetail:["maxPurchasePrice", "stateOfPurchase", "holdingPeriod", "maxsFundAvailable", "preferredAvailable", "bankSavings_1", "offset", "sharesInv"
        ,"cashGift",
    ],
    propertiesDetail: [ "austProperties", "address_1a", "owner_1a", "propValue_1a", "useType_1a", "rent_1a", "rentFreq_1a", "mortgaged_1a", "loanLimit_1a", 
        "lender_1a", "loanTerm_1a", "lr_1a", "rateType_1a"],
    propertiesDetail_secondary: ["address_2a", "owner_2a", "propValue_2a", "useType_2a", "rent_2a", "rentFreq_2a", "mortgaged_2a", "loanLimit_2a", "lender_2a", "loanTerm_2a"
        ,"lr_2a", "rateType_2a"
    ],
    equityDetail: ["releaseEquity", "osProperties", "country_1p", "propValue_1p", "mortgageBalance_1p", "iR_1p", "loanTerm_1p", "1pRent", "1pRentFreq"
        ,"1pOwner"
    ],
    employmentDetail: ["employment", "jobStartMonth", "jobStartYear", "occupation", "currency", "salary", "salaryFreq", "allowance", "allowanceFreq"
        ,"commission", "commFreq", "bonusThis", "bonusLast", "seMonth", "seYear", "industry", "currencySelfEmp_1", "personalThisYear", "personalLastYear",
        "ownership", "ebtThisYear_1", "ebtLastYear_1"
    ],
    employmentDetail_secondary: ["employment_2", "jobStartMonth_2", "jobStartYear_2", "occupation_2", "currencySalary_2", "salary_2", "salaryFreq_2", "allowance_2"
        ,"allowanceFreq_2", "commission_2", "commFreq_2", "bonusThis_2", "bonusLast_2"
    ],
    otherLoanDetail: ["anyOtherLoan", "anyOtherLoanCurrency", "anyOtherLoanType", "anyOtherLoanBalance", "anyOtherLoanTerms", "anyOtherLoanRepayment"
        ,"anyOtherLoanFrequency", "anyOtherLoan_2"
    ],
    cardDetail: ["ccCurrency1", "ccLimit1", "cardCurrency2", "ccLimit_2"]
}

export const REFINANCE_DATA_CATEGORY = {
    contactDetail: ["firstName", "lastName", "email", "phone", "citizenship", "residency"],
    hasCoapplicant: "coApplicationStatus",
    coApplicantDetail: ["firstName_2", "lastName_2", "email_2", "phone_2", "citizenship_2", "residency_2", "livingTogether"],
    loanDetail: ["date", "lowerRepayment", "payOffMortgageFaster", "getBetterRate", "moreLoanFeatures", "consolidateDebt", "cashOutEquity", "irPreference",
        "irRepaymentPreference", "currentMortgage",],
    employmentDetail: ["employment", "jobStartMonth", "jobStartYear", "occupation", "currency", "salary", "salaryFreq", "allowance", "allowanceFreq",
        "commission", "commFreq", "bonusThis", "bonusLast", 
    ],

    familyDetail: ["maritalStatus", "kids", "kidsAge_1", "kidsAge_2", "kidsAge_3", "kidsAge_4", "kidsAge_5", "privateEducation"],
    australianProperties: ["asProperties", "1aAddress", "1aMortgaged", "1aLoanBalance", "1aLR", "1aLoanTerm", "1aLender", "1aFixed", "1aOwner",
        "1aPropValue", "1aUseType", "1aRent", "1aRentFreq", "1aServiceRequired", "1aEquityAmount", "1aEquityPurpose"
    ],
    nonAustralianProperties: ["nonAusProperty", "1pCountry", "1pPropValue", "1pMortgageBalance", "1pIR", "1pLoanTerm", "1pRent", "1pRentFreq", "1pOwner"],
    seDetail: ["seMonth", "seYear", "industry", "seCurrency", "personalIncomeThisYear", "personalIncomeLastYear", "ownership", "companyIncomeThisYear"
        ,"companyIncomeLastYear"
    ],
    otherLoanDetail: ["anyOtherLoan", "anyOtherLoanCurrency", "anyOtherLoanType", "anyOtherLoanBalance", "anyOtherLoanTerms", "anyOtherLoanRepayment", "anyOtherLoanFrequency"],
    cardDetail: ["ccCurrency1", "ccLimit1", "cardCurrency2", "ccLimit_2"],
    employmentDetailSecondary: ["employment_2", "jobStartMonth_2", "jobStartYear_2", "occupation_2", "currencySalary_2", "salary_2",
        "salaryFreq_2", "allowance_2", "allowanceFreq_2", "commission_2", "commFreq_2", "bonusThis_2", "bonusLast_2", "rentExpense_2", "rentFreq_2"
    ],
    seDetailSecondary: ["seMonth_2", "seYear_2", "industry_2", "personalThisYear_2", "personalLastYear_2", "ownership_2", "companyThisYear_2", "companyLastYear_2"],
    loanDetailSecondary: ["currentMortgage_2"],
    anyOtherLoanDetailSecondary: ["anyOtherLoan_2", "1LCurrency_2", "1LLoanType_2", "1LLoanBalance_2", "1LLoanTerm_2", "1LRepayment_2", "1LFreq_2"]
}   