import { PURCHASE_DATA_MAPPER } from "@/devFrontData/websiteDataMapper";
import { REFINANCE_DATA_MAPPER } from "@/devFrontData/formMapper";

export const mapWebsiteKeyToCopilot = (data: Record<string, any>, type?:"purchase"|"refinance"): Record<string, any> => {
    let mappedObject: Record<string, any> = {};
    let dataMapper: Record<string, string>;

    switch (type) {
        case "refinance":
            dataMapper = REFINANCE_DATA_MAPPER;
            break;
        case "purchase":
        default:
            dataMapper = PURCHASE_DATA_MAPPER;
            break;
    }

    Object.keys(dataMapper).forEach((key: string) => {
        if (key in data) {
            mappedObject[dataMapper[key]] = data[key];
        } else {
            mappedObject[key] = null;
        }
    });
    console.log("mappedObject", mappedObject)
    return mappedObject;
};
