
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Breadcrumb, Button, Col, Drawer, Flex, Input, Row, Space, Tooltip } from "antd";
import Scrollbars from "rc-scrollbars";
//hooks
import { useUpdateDeal } from "@/hooks/useUpdateDeal";

//components
import { Container } from "@/components/Layout";
import AsideContainer from "@/components/Layout/AsideContainer";
import HeaderRow from "../../components/HeaderRow";
import { BackButton, CountTitle, PageTitle } from "@/components/UI";

//redux
import {  useGetOpportunityDetailQuery } from "@/store/apis/opportunityApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/store";
import { useGenerateBrokerNoteMutation, useGenerateBrokerNotePDFMutation, useGetBrokerNoteDetailQuery, useGetBrokerSystemPromptQuery, usePostBrokerNoteMutation, useUpdateBrokerNoteMutation, useUpdateBrokerSystemPromptMutation } from "../../api/brokerNoteGeneratorAPI";
import { setBrokerFormData } from "@/store/slices/brokerNote/brokerFormSlice";

//types
import { BrokerContactDetailsType, BrokerContactType, ContactType, DealFormType, DealInfoType } from "@/pages/deals/types";
import DealForm, { DealFormMethods, FormFieldDataType } from "@/pages/deals/components/DealForm";

import { dateFormatter, displayError, displayInfo, displaySuccess, formatTitle } from "@/utils/common.utils";
import { brokerNoteBreadcrumbList } from "../../constant";
import BrokerPDF, { BrokerNotePDFMethods } from "../../components/BrokerNotePdf";
import { LoanDetailFormMethods, LoanDetailFormType } from "../../components/BrokerNotePdf/LoanDetailForm";
import { ApplicantDetailsType } from "../../components/BrokerNotePdf/pdf/ApplicantDetail";
import { BrokerNoteFieldTypes, BrokerNoteFormType, BrokerNotePayload, BrokerNotePDFPayload, BrokerNoteResponse, SystemPromptType } from "../../types";
import HistorySidebar from "../../components/HistorySidebar";
import { useFetchAllUsersQuery } from "@/store/apis/userApi";
import { getBrokerUserContent, getLoanAmount, getStampDuty } from "../../utils";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import DetailListPanel from "@/pages/deals/components/DetailListPanel";
import { themeConfig } from "@/config";
import { FormTrigger } from "@/types";
import { FundsDetailFormType } from "../../components/BrokerNotePdf/pdf/FundsDetail";
import { dealDataMapper } from "../../utils/dealDataMapper";
import { getOptionLabel, LENDER_UPDATED_OPTIONS, LOAN_PRICING_OPTIONS } from "@/devFrontData/optionList";
import { set } from "react-hook-form";
import dayjs from "dayjs";
import BrokerNoteSidebar from "../../components/BrokerNote/BrokerNoteSidebar";
import { BROKER_TITLE_MAPPER } from "../../constant/list";
import SystemPromptForm from "../../components/SystemPromptForm";
import BrokerNotePromptsEditor from "../../components/BrokerNote/BrokerNotePromptsEditor";





const BrokerNoteGenerator = () => {
    const dispatch = useDispatch();
    const { requestId, noteID} = useParams<{ requestId: string, noteID?:string }>();
    const location = useLocation();
    
    
    const [pageType, setPageType] = useState<"create" | "update">("create");
    
    const [viewSystemPrompt, setViewSystemPrompt] = useState(false);
    const [selectedSystemPrompt, setSelectedSystemPrompt] = useState<SystemPromptType<BrokerNoteFieldTypes> | null>(null);

    const [viewUserPrompt, setViewUserPrompt] = useState(false);
    const [activeUserPrompt, setActiveUserPromt] = useState<string>("");
    const [activePromptId, setActivePromptId] = useState<BrokerNoteFieldTypes|null>(null);

    const [openDealFrom, setOpenDealFrom] = useState(false);
    const [viewDealDetail, setViewDealDetail] = useState(false);
    const brokerNotePDFRef = useRef<BrokerNotePDFMethods>(null);

    const dealsFormData = useSelector((state: RootState) => state.brokerForm);
    const [dealFieldData, setDealFieldData] = useState<DealInfoType | null>(null);
    const [brokerContactData, setBrokerContactData] = useState<BrokerContactType | null>(null);
    const [contactData, setContactData] = useState<ContactType | null>(null);
    const [secondaryContactData, setSecondaryContactData] = useState<ContactType | null>(null);

    const [brokerNoteAPIDetail, setBrokerNoteAPIDetail] = useState<BrokerNoteResponse | null>(null);
    const dealFormRef = useRef<DealFormMethods>(null);
    const submitRef = useRef<HTMLButtonElement>(null);
    const submitLoanDetailsRef = useRef<HTMLButtonElement>(null);
    const [dealDataMapped, setDealDataMapped] = useState<Partial<DealFormType>>({});

    const [formFieldsData, setFormFieldsData] = useState<FormFieldDataType | null>(null);

    const [generateType, setGenerateType] = useState<'single' | 'all'>('single');
    const [activeId, setActiveId] = useState<BrokerNoteFieldTypes | null>(null);

    const [loanDetailsFormData, setLoanDetailsFormData] = useState<LoanDetailFormType | null>(null);
    const [fundsFormData, setFundsFormData] = useState<FundsDetailFormType | null>(null);

    const [brokerDetail, setBrokerDetail] = useState<BrokerContactDetailsType | null>(null);
    const [applicantDetails, setApplicantDetails] = useState<ApplicantDetailsType |null>(null);

    const [loanPurposeData, setLoanPurposeData] = useState("");
    const [applicationOverData, setApplicationOverData] = useState("");
    const [livingConditionData, setLivingConditionData] = useState("");
    const [employmentIncomeData, setEmploymentIncomeData] = useState("");
    const [commitmentsData, setCommitmentsData] = useState("");
    const [othersData, setOthersData] = useState("");
    const [mitigantsData, setMitigantsData] = useState("");
    
    const [fetchLoanPurpose, {isLoading: loanPurposeLoading, isError: loanPurposeError }] = useGenerateBrokerNoteMutation();
    const [fetchApplicantOverview, {isLoading: applicantOverviewLoading, isError: applicantOverviewError }] = useGenerateBrokerNoteMutation();
    const [fetchLivingCondition, {isLoading: livingConditionLoading, isError: livingConditionError }] = useGenerateBrokerNoteMutation();
    const [fetchEmploymentIncome, {isLoading: employmentIncomeLoading, isError: employmentIncomeError }] = useGenerateBrokerNoteMutation();
    const [fetchCommitments, {isLoading: fetchCommitmentsLoading, isError: fetchCommitmentsError }] = useGenerateBrokerNoteMutation();
    const [fetchBrokerNoteOthers, {isLoading: fetchBrokerNoteOthersLoading, isError: fetchBrokerNoteOthersError }] = useGenerateBrokerNoteMutation();
    const [fetchMitigants, {isLoading: fetchMitigantsLoading, isError: fetchMitigantsError }] = useGenerateBrokerNoteMutation();

    /** 
     * fetch all system prompts
     */

    const {data: brokerNoteSystemPrompts, isLoading:brokerNotePromptsLoading, refetch: refetchBrokerNotePrompt} = useGetBrokerSystemPromptQuery(undefined, {refetchOnMountOrArgChange: true});
   

    useEffect(() => {
        if (noteID) {
          setPageType("update");
        } else {
          setPageType("create");
        }
      }, [noteID]);
    
    const [postBrokerNotePDF, {isLoading: postBrokerNotePDFLoading}] = useGenerateBrokerNotePDFMutation();
    const [postBrokerNote, { isLoading: postBrokerNoteLoading }] = usePostBrokerNoteMutation();
    const [updateBrokerNote, {isLoading: updateBrokerNoteLoading}] = useUpdateBrokerNoteMutation();
    const {data: brokerNoteDetailData,
        error: brokerNoteDetailErrors,
        isLoading: brokerNoteDetailLoading,
        refetch: refetchBrokerNoteDetail
    } = useGetBrokerNoteDetailQuery(noteID!, {skip: !noteID, refetchOnMountOrArgChange: true })

    /**
     * fetch deals detail
     */
    const {
        data: dealsData,
        error: dealsDataError,
        isLoading: dealsDataLoading,
        refetch: refetchDealsData
    } = useGetOpportunityDetailQuery(requestId!, { skip: !requestId , refetchOnMountOrArgChange: true });

    /**
     * Update deals detail
     */
    const { handleDealUpdate, isLoading, isSuccess } = useUpdateDeal();

    useEffect(() => {
        if (isSuccess) {
            refetchDealsData();
            setOpenDealFrom(false);
        }
    }, [isSuccess, refetchDealsData]);

    useEffect(() => {
        if (brokerNoteDetailData && !brokerNoteDetailLoading && noteID) {
            const {data} = brokerNoteDetailData
            setLoanPurposeData(data.loan_purpose_note ?? "")
            setApplicationOverData(data.applicant_overview_note ?? "")
            setLivingConditionData(data.living_condition_note ?? "")
            setEmploymentIncomeData(data.employment_income_note ?? "")
            setCommitmentsData(data.commitments_note ?? "")
            setOthersData(data.other_note ?? "")
            setMitigantsData(data.mitigants_note ?? "")
            setBrokerNoteAPIDetail(data)
        }
    },[noteID, brokerNoteDetailData, brokerNoteDetailLoading])

    /**
     * Watches for changes in the data fetched from the API request for deals.
     * When the deals data is available, not loading, and a request ID is present:
     * - Resets form fields and related state.
     * - Updates deal-related state with data from `dealsData`.
     * - If primary or secondary contact data is present, updates contact state accordingly.
     * - If additional JSON data exists and has more than two keys, updates form fields data.
     */
    useEffect(() => {
        if (dealsData && !dealsDataLoading && requestId) {
            setBrokerContactData(null);
            setContactData(null);
            setSecondaryContactData(null);
            setFormFieldsData(null);
            setBrokerDetail(null);

            if (dealFormRef.current) {
                dealFormRef.current.resetForm();
            }
            setDealFieldData({
                dealName: dealsData?.name,
                dealType: dealsData?.type
            });
            setBrokerDetail({
                broker: dealsData?.primary_processor ?? null,
                processor: dealsData?.secondary_processor ?? null
             });

            setBrokerContactData({
                primaryContact: dealsData?.primary_processor?.email ?? "",
                secondaryContact: dealsData?.secondary_processor?.email ?? ""
            });

            let initialContactData;
            if (dealsData.primary_contact) {
                initialContactData= {
                    name: dealsData?.primary_contact.name,
                    email: dealsData?.primary_contact.email,
                    phone: dealsData?.primary_contact.phone,
                    residency: dealsData?.primary_contact.residency,
                    citizenship: dealsData?.primary_contact.citizenship,
                    
                }
                setContactData(initialContactData);
            }

            let initialSecondaryContactData;
            if (dealsData.secondary_contact) {
                initialSecondaryContactData = {
                    name: dealsData?.secondary_contact.name,
                    email: dealsData?.secondary_contact.email,
                    phone: dealsData?.secondary_contact.phone,
                    residency: dealsData?.secondary_contact.residency,
                    citizenship: dealsData?.secondary_contact.citizenship,
                }
                setSecondaryContactData(initialSecondaryContactData);
            }
            setApplicantDetails({
                primaryContact: dealsData?.primary_contact?? null,
                secondaryContact: dealsData?.secondary_contact?? null,
                otherContact: null,
            })

            if (dealsData?.json_data && Object.keys(dealsData?.json_data).length > 2) {
                setFormFieldsData(dealsData?.json_data as FormFieldDataType);
            }
            const dealsMappedData = dealDataMapper({
                dealData:dealsData?.json_data as FormFieldDataType, 
                contactData:initialContactData, 
                secondaryContactData:initialSecondaryContactData 
            });

            setDealDataMapped(dealsMappedData)
        }
    }, [dealsData, dealsDataLoading, requestId]);

    /**
     * 
     * @param data Updated Deal Form data
     * updated Compliance Form data
     */
    const handleSetFormData = (data: DealFormType) => {
        dispatch(setBrokerFormData(data))
    };

    /**
     * Triggers DealsForm button to submit the form
     */
    const handleSaveDeal = () => {
        if (submitRef.current) {
            submitRef.current.click();
        } 
    };


    /**
     * Handles the submission of the Deal Form.
     * @param {Object} params - The parameters for the function.
     * @param {DealFormType} params.formData - The data from the Deal Form.
     */
    const handleFormSubmit = ({ formData }: { formData: DealFormType }) => {
        handleDealUpdate({formData, requestId: requestId ?? ""});
    };


    const generateLoanPurpose = ({ updatedData }: { updatedData: LoanDetailFormType }) => {
        fetchLoanPurpose({type:"loan_purpose", data:{...dealDataMapped, ...updatedData,} })
        .unwrap()
        .then((response) => {
            setLoanPurposeData(response.choices[0].message.content)
        });
    }

    const generateApplicantOverview = () => {
        fetchApplicantOverview({type:"applicant_overview", data:{...loanDetailsFormData, ...dealDataMapped} as BrokerNoteFormType })
        .unwrap()
        .then((response) => {
            setApplicationOverData(response.choices[0].message.content)
        });
    }

    const generateLivingCondition = () => {
        fetchLivingCondition({type:"living_condition", data:{...loanDetailsFormData, ...dealDataMapped,}  as BrokerNoteFormType  })
        .unwrap()
        .then((response) => {
            setLivingConditionData(response.choices[0].message.content)
        });
    }

    const generateEmploymentIncome = () => {
        fetchEmploymentIncome({type:"employment_income", data:{...loanDetailsFormData, ...dealDataMapped,}  as BrokerNoteFormType  })
        .unwrap()
        .then((response) => {
            setEmploymentIncomeData(response.choices[0].message.content)
        });
    }

    const generateCommitments = () => {
        fetchCommitments({type:"commitments", data:{...loanDetailsFormData, ...dealDataMapped,}  as BrokerNoteFormType  })
        .unwrap()
        .then((response) => {
            setCommitmentsData(response.choices[0].message.content)
        });
    }

    const generateOthers = () => {
        fetchBrokerNoteOthers({type:"others", data:{...loanDetailsFormData, ...dealDataMapped,}  as BrokerNoteFormType  })
        .unwrap()
        .then((response) => {
            setOthersData(response.choices[0].message.content)
        });
    }

    const generateMitigants = () => {
        const generatedOutput = {
            generatedPurpose: loanPurposeData,
            generatedApplicantOverview: applicationOverData,
            generatedLivingConditions: livingConditionData,
            generatedEmploymentIncome: employmentIncomeData,
            generateCommitments: commitmentsData,
        }
        fetchMitigants({type:"mitigants", data:{...loanDetailsFormData, ...dealDataMapped, ...generatedOutput} as BrokerNoteFormType  })
        .unwrap()
        .then((response) => {
            setMitigantsData(response.choices[0].message.content)
        });
    }

    const triggerLoanDetailForm = async (): Promise<FormTrigger<LoanDetailFormType>> => {
        if (brokerNotePDFRef.current) {
            try {
                const loanDetailformDetail = await brokerNotePDFRef.current.loanDetailFormMethods.triggerValidation();
                if (loanDetailformDetail.isValid) {
                    setLoanDetailsFormData(loanDetailformDetail.data as LoanDetailFormType)
                }
                return loanDetailformDetail;
            } catch (error) {
                return { isValid: false, data: undefined };
            }
        } else {
            return { isValid: false, data: undefined };
        }
    };

    const triggerFundsDetailForm = async (): Promise<FormTrigger<FundsDetailFormType>> => {
        if (brokerNotePDFRef.current) {
            try {
                const fundsFormDetail = await brokerNotePDFRef.current.fundsDetailFormMethods.triggerValidation();
                if (fundsFormDetail.isValid) {
                    setFundsFormData(fundsFormDetail.data as FundsDetailFormType)
                }
                return fundsFormDetail;
            } catch (error) {
                return { isValid: false, data: undefined };
            }
        } else {
            return { isValid: false, data: undefined };
        }
    };

    const handleGenerateAIContent  = async (id: BrokerNoteFieldTypes) => {
        setGenerateType("single");
        setActiveId(id);
        switch (id) {
            case "loan_purpose":
                const { isValid: loanFormIsValid1, data: loanDetailFormData1 } = await triggerLoanDetailForm();
                if (loanFormIsValid1) {
                    generateLoanPurpose({ updatedData: loanDetailFormData1 as LoanDetailFormType });
                }
                break;
            case "applicant_overview":
                generateApplicantOverview()
                break;
            case "living_condition":
                generateLivingCondition()
                break;
            case "employment_income":
                generateEmploymentIncome()
                break;
            case "commitments":
                generateCommitments()
                break;
            case "others":
                generateOthers()
                break;
            case "mitigants":
                handleMitigantsGeneration()
                break;
            default:
                console.log("Invalid field type");
                break;
        }
    };

    const canGenerateMitigants = () =>  loanPurposeData && applicationOverData && livingConditionData && employmentIncomeData && commitmentsData;
    
    useEffect(() => {
        if (canGenerateMitigants()) {
            generateMitigants();
        }
    }, [loanPurposeData, applicationOverData, livingConditionData, employmentIncomeData, commitmentsData]);
    
    const handleMitigantsGeneration = () => {
        if (canGenerateMitigants()) {
            generateMitigants();
        } else {
            displayInfo('Please generate others fields before generating Mitigants')
        }
    };

    const handleGenerateAllNotes = async() => {
        setGenerateType('all');
        const { isValid: loanFormIsValid, data: loanDetailFormData } = await triggerLoanDetailForm();
        if (loanFormIsValid) {
            generateLoanPurpose({ updatedData: loanDetailFormData as LoanDetailFormType });
        }
        generateApplicantOverview();
        generateLivingCondition();
        generateEmploymentIncome();
        generateCommitments();
        generateOthers();
    };

    const handleLoanDetailForm = (data:LoanDetailFormType) => {
        setLoanDetailsFormData(data);
    }   
    const updateLoanDetailFormData = (data:LoanDetailFormType) => {
        setLoanDetailsFormData((prevData) => ({...prevData,...data}));
    };

    

    const onAIContentUpdate = (id:BrokerNoteFieldTypes, updatedContent:string) => {
        switch (id) {
            case "loan_purpose":
                setLoanPurposeData(updatedContent)
                break;
            case "applicant_overview":
                setApplicationOverData(updatedContent)
                break;
            case "living_condition":
                setLivingConditionData(updatedContent)
                break;
            case "employment_income":
                setEmploymentIncomeData(updatedContent)
                break;
            case "commitments":
                setCommitmentsData(updatedContent)
                break;
            case "others":
                setOthersData(updatedContent)
                break;
            case "mitigants":
                setMitigantsData(updatedContent)
                break;
            default:
                console.log("Invalid field type");
                break;
        }
    };

    const handleViewUserInputPrompt = (id: BrokerNoteFieldTypes) => {
       setActivePromptId(id);
       const userPrompt =  getBrokerUserContent({...dealDataMapped, ...loanDetailsFormData}  as BrokerNoteFormType , id);
       setActiveUserPromt(userPrompt);
       setViewUserPrompt(true);
    };

    const handleCloseUserPrompt = () => {
        setViewUserPrompt(false);
        setActivePromptId(null);
        setActiveUserPromt("")
    };

    const handlePrintNote = async () => {
        const { isValid: loanFormIsValid, data: loanDetailFormData } = await triggerLoanDetailForm();
        if (!loanFormIsValid) {
            return
        }
        const payload:BrokerNotePDFPayload = {
            date: dayjs().format("DD/MM/YYYY"),
            processor: {
                name: brokerDetail?.broker?.fullname ?? "",
                email: brokerDetail?.broker?.email ?? "",
                phone: brokerDetail?.broker?.phone ?? "",
            },
            secondary_processor: {
                name: brokerDetail?.processor?.fullname ?? "",
                email: brokerDetail?.processor?.email ?? "",
                phone: brokerDetail?.processor?.phone ?? "",
            },
            primary_contact: {
                name: dealDataMapped?.contactFullName,
                email: dealDataMapped?.contactEmail ?? "",
                phone: dealDataMapped?.contactPhone,
                residency: dealDataMapped?.contactResidency,
                citizenship: dealDataMapped?.contactCitizenship,
                employer: dealDataMapped?.employer ?? ""
            },
            co_applicant_status: dealDataMapped?.coApplicationStatus ?? false,
            secondary_contact: {
                name: dealDataMapped?.secondaryContactFullName?? "",
                email: dealDataMapped?.secondaryContactEmail?? "",
                phone: dealDataMapped?.secondaryContactPhone,
                residency: dealDataMapped?.secondaryContactResidency,
                citizenship: dealDataMapped?.secondaryContactCitizenship,
                employer: dealDataMapped?.employment_2 ?? ""
            },
            loan_details: {
                finance_due_date: dateFormatter(loanDetailFormData?.financeDue ?? ""),
                settlement_date: dateFormatter(loanDetailFormData?.settlementDate ?? ""),
                lender:  getOptionLabel(loanDetailFormData?.lender ?? "", LENDER_UPDATED_OPTIONS) ,
                loan_term: loanDetailFormData?.loanTerm,
                property_value: loanDetailFormData?.propertyValue,
                interest_rate: loanDetailFormData?.interestRate,
                loan_purpose: loanDetailFormData?.purpose ?? "",
                loan_amount: loanDetailFormData?.loanAmount,
                product: loanDetailFormData?.product ?? "",
                lvr: loanDetailFormData?.lvr,
                valuation: loanDetailFormData?.valuation ?? "",
                pricing: getOptionLabel(loanDetailFormData?.pricing ?? "", LOAN_PRICING_OPTIONS),
                offset: loanDetailFormData?.offset ?? "",
                loan_detail_address: loanDetailFormData?.loanDetailAddress ?? "",
            },
            fund_detail: {
                loan_amount: getLoanAmount(dealDataMapped?.lvr ?? 0, dealDataMapped?.propertyValue ?? 0),
                cash_out_amount: dealDataMapped?.cashOutAmount,
                stamp_duty: getStampDuty(dealDataMapped?.propertyValue ?? 0)
            },
            generated_loan_purpose: loanPurposeData,
            generated_applicant_overview: applicationOverData,
            generated_living_condition: livingConditionData,
            generated_employment_income: employmentIncomeData,

            generated_commitments: commitmentsData,
            generated_others: othersData,
            generated_mitigants: mitigantsData
        }
        try {
            await postBrokerNotePDF(payload);
        } catch (error) {
            console.error("Error generating PDF:", error);
            displayError(error)
        }
    };

    const handleSaveBrokerNote = async() => {
        const { isValid: loanFormIsValid, data: loanDetailFormData } = await triggerLoanDetailForm();
        const { isValid: isFundsDetailFormValid, data: fundsDetailFormData } =  await triggerFundsDetailForm();

        if (!loanFormIsValid || !isFundsDetailFormValid) {
            return
        }
        if (requestId) {
            let payload: BrokerNotePayload = {
                opportunity_id: requestId,
                date: dayjs().format("DD/MM/YYYY"),
                processor: { name: "", email: dealDataMapped?.brokerPrimaryContact ?? "", phone: ""},
                primary_contact: {
                    name: dealDataMapped?.contactFullName,
                    email: dealDataMapped?.contactEmail ?? "",
                    phone: dealDataMapped?.contactPhone,
                    residency: dealDataMapped?.contactResidency,
                    citizenship: dealDataMapped?.contactCitizenship,
                    employer: dealDataMapped?.employer ?? ""
                },
                co_applicant_status: dealDataMapped?.coApplicationStatus ?? false,
                secondary_contact: {
                    name: dealDataMapped?.secondaryContactFullName?? "",
                    email: dealDataMapped?.secondaryContactEmail?? "",
                    phone: dealDataMapped?.secondaryContactPhone,
                    residency: dealDataMapped?.secondaryContactResidency,
                    citizenship: dealDataMapped?.secondaryContactCitizenship,
                    employer: dealDataMapped?.employment_2 ?? ""
                },
                loan_details: {
                    finance_due_date: dateFormatter(loanDetailFormData?.financeDue ?? ""),
                    settlement_date: dateFormatter(loanDetailFormData?.settlementDate ?? ""),
                    lender:  loanDetailFormData?.lender,
                    loan_term: loanDetailFormData?.loanTerm,
                    property_value: loanDetailFormData?.propertyValue,
                    interest_rate: loanDetailFormData?.interestRate,
                    loan_purpose: loanDetailFormData?.purpose,
                    loan_amount: loanDetailFormData?.loanAmount,
                    product: loanDetailFormData?.product,
                    lvr: loanDetailFormData?.lvr,
                    valuation: loanDetailFormData?.valuation ,
                    pricing: loanDetailFormData?.pricing,
                    offset: loanDetailFormData?.offset,
                    loan_detail_address: loanDetailFormData?.loanDetailAddress,
                    cash_out_reason: loanDetailFormData?.cashOutReason
                },
                funds_complete: {
                    loan_amount: getLoanAmount(dealDataMapped?.lvr ?? 0, dealDataMapped?.propertyValue ?? 0),
                    cash_out_amount: dealDataMapped?.cashOutAmount,
                    stamp_duty: getStampDuty(dealDataMapped?.propertyValue ?? 0)
                },
                funds_available: {
                    bank_1: fundsDetailFormData?.bank1,
                    bank_2: fundsDetailFormData?.bank2,
                    bank_3: fundsDetailFormData?.bank3,
                    shares: fundsDetailFormData?.shares,
                    cash_gift: fundsDetailFormData?.cashGift
                },
                
                loan_purpose_note: loanPurposeData,
                applicant_overview_note: applicationOverData,
                living_condition_note: livingConditionData,
                employment_income_note: employmentIncomeData,
                commitments_note: commitmentsData,
                other_note: othersData,
                mitigants_note: mitigantsData,
            };

            if(!noteID && pageType === 'create') {
                postBrokerNote(payload)
                .unwrap()
                .then(response => {
                    console.log("response", response)
                    displaySuccess("Successfully saved broker notes");
                })
                .catch(error => {
                    displayError(error);
                });
            } 

            if(noteID && pageType === "update"){
                updateBrokerNote({...payload, brokerNoteId:noteID})
                .unwrap()
                .then(response => {
                    displaySuccess("Successfully updated broker notes");
                })
                .catch(error => {
                    displayError(error);
                });
            }
            
            
        }
    };

    const handleViewSystemPrompt = (id: BrokerNoteFieldTypes) => {
        setViewSystemPrompt(true);
        const selectedPrompt = brokerNoteSystemPrompts?.data.find(prompt => prompt.prompt_type === id)
        if(selectedPrompt){
            setSelectedSystemPrompt(selectedPrompt as SystemPromptType<BrokerNoteFieldTypes>)
        }
     };

     const handleSystemPromptUpdate = (state: boolean) => {
        if(state) {
            refetchBrokerNotePrompt();
            setSelectedSystemPrompt(null);
        }
      
     };

     const handleSystemPromptClose = () => {
        setViewSystemPrompt(false);
        setSelectedSystemPrompt(null);
     };

    return (
        <>
            <AsideContainer sideContent={<BrokerNoteSidebar />}>
                <Container fullWidth={true} align="left">
                    <Row>
                        <Col xs={24} md={24}>
                            <Flex justify="space-between" align="center" style={{ marginBottom: "1rem" }}>
                            <Breadcrumb>
                                {brokerNoteBreadcrumbList.map((breadcrumb, index) => (
                                    <Breadcrumb.Item key={index}>
                                        {breadcrumb.path ? <Link to={breadcrumb.path}>{breadcrumb.title}</Link> : breadcrumb.title}
                                    </Breadcrumb.Item>
                                ))}
                                </Breadcrumb>
                                <BackButton />
                            </Flex>
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col xs={24} md={24}>
                            <HeaderRow>
                                <Flex gap={10} align="center">
                                    <PageTitle title="Broker Notes" style={{marginBottom:0}} />
                                    <CountTitle>{dealsData?.name}</CountTitle>
                                    <Tooltip title="Edit Deal">
                                        <Button size="small" icon={<EditOutlined />} onClick={() => setOpenDealFrom(true)} />
                                    </Tooltip>
                                    <Tooltip title="View Deal">
                                        <Button size="small" icon={<EyeOutlined />} onClick={() => setViewDealDetail(true)} />
                                    </Tooltip>
                                </Flex>

                                <Flex gap={10}>                                
                                    <Button type="primary" onClick={handlePrintNote} loading={postBrokerNotePDFLoading}>Print</Button>
                                    <Button type="primary" onClick={handleGenerateAllNotes} loading={ 
                                        applicantOverviewLoading || loanPurposeLoading || livingConditionLoading || employmentIncomeLoading || fetchCommitmentsLoading || fetchBrokerNoteOthersLoading || fetchMitigantsLoading
                                        }>
                                            Generate All
                                    </Button>
                                    <Button type="primary" onClick={handleSaveBrokerNote} loading={postBrokerNoteLoading || updateBrokerNoteLoading} >Save Note</Button>
                                </Flex>
                            </HeaderRow>
                            <Scrollbars style={{ height: "calc(100vh - 200px)" }} autoHide={true}>
                                <BrokerPDF
                                        allValues={formFieldsData}
                                        loanDetailFormValue={loanDetailsFormData}
                                        loanPurposeData={loanPurposeData}
                                        loanPurposeDataLoading={loanPurposeLoading}
                                        applicationOverviewData={applicationOverData}
                                        applicationOverviewDataLoading={applicantOverviewLoading}
                                        livingCDData={livingConditionData}
                                        livingCDDataLoading={livingConditionLoading}
                                        empIncomeData={employmentIncomeData}
                                        empIncomeDataLoading={employmentIncomeLoading}
                                        commitData={commitmentsData}
                                        commitDataLoading={fetchCommitmentsLoading}
                                        otherData={othersData}
                                        otherDataLoading={fetchBrokerNoteOthersLoading}
                                        mitiData={mitigantsData}
                                        mitiDataLoading={fetchMitigantsLoading}
                                        generateAIContent={handleGenerateAIContent}

                                        brokerDetail={brokerDetail}
                                        applicantDetails={applicantDetails}

                                        submitLoanDetailRef={submitLoanDetailsRef}
                                        handleLoanDetailForm={handleLoanDetailForm}
                                        setLoanDetailFormData={updateLoanDetailFormData}

                                        ref={brokerNotePDFRef}

                                        onAIContentUpdate={onAIContentUpdate}
                                        viewUserInputPrompt={handleViewUserInputPrompt}
                                        brokerNoteAPIDetail={brokerNoteAPIDetail}
                                        viewSystemPrompt={handleViewSystemPrompt}
                                    />
                            </Scrollbars>
                        </Col>
                    </Row>
                </Container>
            </AsideContainer>
            <Drawer title={dealsData?.name ?? ""} onClose={() => setViewDealDetail(false)} open={viewDealDetail} width={themeConfig.drawer.xxl}>
                <DetailListPanel dealDetail={dealsData?.json_data as DealFormType} />
            </Drawer>
            <Drawer
                title="Update Deal"
                onClose={() => setOpenDealFrom(false)}
                open={openDealFrom}
                width={themeConfig.drawer.xxl}
                extra={
                    <Space>
                        <Button onClick={handleSaveDeal} loading={isLoading} type="primary">
                            Save Deal
                        </Button>
                    </Space>
                }
            >
                <DealForm
                    handleFormSubmit={handleFormSubmit}
                    submitRef={submitRef}
                    formFieldsData={formFieldsData}
                    initialValues={dealsFormData}
                    dealData={dealFieldData}
                    brokerContact={brokerContactData}
                    ref={dealFormRef}
                    contactData={contactData}
                    secondaryContactData={secondaryContactData}

                />
            </Drawer>

            <Drawer 
                open={viewUserPrompt} 
                onClose={handleCloseUserPrompt}
                title={formatTitle(activePromptId ?? "")}
                >
                    <div>
                        <Input.TextArea rows={20} readOnly={true} value={activeUserPrompt} />
                    </div>
            </Drawer>
           
            {brokerNoteSystemPrompts && <Drawer
                open={viewSystemPrompt}
                onClose={handleSystemPromptClose}
                size="large"
                title="Broker Note System Prompts"
                >
                 
                 <BrokerNotePromptsEditor handlePromptUpdateState={handleSystemPromptUpdate} isPromptsLoading={brokerNotePromptsLoading} systemPrompts={brokerNoteSystemPrompts?.data} selectedPromptId={selectedSystemPrompt?.prompt_type} />
            </Drawer>}
        </>
    );
};

export default BrokerNoteGenerator;