import { FormFieldDataType } from "@/pages/deals/components/DealForm";
import { ContactType, DealFormType } from "@/pages/deals/types";


export const dealDataMapper = ({ dealData, contactData, secondaryContactData }:
     { 
        dealData: FormFieldDataType; 
        contactData: ContactType | undefined;
        secondaryContactData: ContactType | undefined;
    }): Partial<DealFormType> => {
        console.log("ContactType", contactData)
    return {
        contactFullName: contactData?.name ?? "",
        contactCitizenship: contactData?.citizenship,
        contactResidency: contactData?.residency,
        secondaryContactFullName: secondaryContactData?.name,
        secondaryContactCitizenship: secondaryContactData?.citizenship,
        secondaryContactResidency: secondaryContactData?.residency,
        purpose: dealData?.purpose,
        lender: dealData?.lender,
        lvr: dealData?.lvr,
        employment: dealData?.employment,
        occupation: dealData?.occupation,
        employment_2:  dealData?.employment_2,
        occupation_2: dealData?.occupation_2,
        calMonthSalary:  dealData?.calMonthSalary,
        calcAllowance: dealData?.calcAllowance,
        calcCommission: dealData?.calcCommission,
        bonusThis: dealData?.bonusThis,
        calMonthSalary_2: dealData?.calMonthSalary_2,
        calcAllowance2: dealData?.calcAllowance2,
        calcCommission2: dealData?.calcCommission2,
        bonusThis_2: dealData?.bonusThis_2,
        anyLiabilities: dealData?.anyLiabilities,
        curentCCLimit1: dealData?.curentCCLimit1,
        maritalStatus: dealData?.maritalStatus,
        kids: dealData?.kids,
        kidsAge: dealData?.kidsAge,
        livingStatus: dealData?.livingStatus,
        currentMortgage_1: dealData?.currentMortgage_1,
        rentalExpense: dealData?.rentalExpense,
        purposeEquity: dealData?.purposeEquity,
        rateType_1a: dealData?.rateType_1a,
        offset: dealData?.offset,
        existingCustomer: dealData?.existingCustomer,
        cashOutReason: dealData?.cashOutReason,
        pricing: dealData?.pricing,
        interestRate: dealData?.interestRate,
        valuation: dealData?.valuation,
        employer: dealData?.employer,
        since: dealData?.since,
        currency: dealData?.currency
    };
};
