import React from "react";
import { Controller, Control } from "react-hook-form";
import { Radio, theme } from "antd";
import { ErrorText } from "../UI";

const { useToken } = theme;

interface RadioGroupFieldProps {
    label?: string;
    control: Control<any>;
    name: string;
    options: Array<{ label: string; value: any }>;
    error?: string;
    formDirection?: "row" | "column";
    required?: boolean; 
}

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({ label, control, name, options, error, formDirection = "column", required=false }) => {
    const { token } = useToken();
    return (
        <div className={`form-control ${formDirection}`}>
            {label && (
                    <div className={`form-label-wrap ${required ? 'required' : ''}`}>
                        <label className="form-label" style={{ color: token.colorTextSecondary }}>
                            {label}
                        </label>
                    </div>
                )}
            <div className="input-containter">
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Radio.Group {...field}>
                            {options.map(option => (
                                <Radio key={option.value} value={option.value}>
                                    {option.label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    )}
                />
                {error && <ErrorText error={error} />}
            </div>
        </div>
    );
};

export default RadioGroupField;
