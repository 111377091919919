import { FC, useState } from "react";
import { Flex, Skeleton } from 'antd';
import { ChatSession } from "../../types";
import COPILOT from "@/assets/copilot.png";
import styles from "./Policy.module.scss";

interface ChatSidebarProps {
    sessions: ChatSession[] | undefined;
    isLoading: boolean;
    handleActiveSession: (id:string) => void;
    handleCreateNewSession: () => void;
}
const ChatSidebar:FC<ChatSidebarProps> = ({sessions, isLoading, handleActiveSession, handleCreateNewSession}) => {
    const [activeItem, setActiveItem] = useState<string|null>(null)
    const handleGetMessages = (sessionId:string) => {
        setActiveItem(sessionId)
        handleActiveSession(sessionId)
    };

    const createNewChatSession = () => {
        setActiveItem(null)
        handleCreateNewSession()
    };

    return (
        <div className={styles.chatSidebar}>
            <div className={`${styles.chatSession}`} style={{marginBottom:"1rem"}} onClick={createNewChatSession}>
                <Flex gap={10} align="center">
                    <div><img src={COPILOT} alt="" style={{width:"15px"}} /> </div>
                    <div>Copilot</div>
                </Flex>
            </div>
            {isLoading && <Skeleton />}
            {!isLoading && sessions && sessions.map(s => {
                return (
                    <div key={s.id} className={`${styles.chatSession} ${activeItem === s.id ? styles.active:''}`} onClick={() => handleGetMessages(s.id)}>
                        <div className={styles.sessionTitle}>{s.title}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ChatSidebar;