// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "@/store";
import { ApiResponse } from "@/types";
import { BrokerNoteFieldTypes, BrokerNoteFormType, BrokerNotePayload, BrokerNotePDFPayload, BrokerNoteResponse, SystemPromptPayload, SystemPromptType } from "@/pages/noteGeneration/types";

import { getBrokerUserContent } from "@/pages/noteGeneration/utils";


export const brokerNoteGeneratorAPI = createApi({
    reducerPath: "brokerNoteGeneratorAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        generateBrokerNote: builder.mutation<any, {type: BrokerNoteFieldTypes, data:BrokerNoteFormType}>({
            query: ({ type, data }) => {
                const userContent = getBrokerUserContent(data, type);
               

                const postData = {
                    "user_content": userContent,
                    "broker_note_field": type
                };

                return {
                    url: "api/broker-note/v2/generate/",
                    method: "POST",
                    body: postData
                };
            },
            transformResponse: (response: ApiResponse<any>):any => {
                return response.data;
            }
        }),

        generateBrokerNotePDF: builder.mutation<Blob, BrokerNotePDFPayload>({
            query: (body) => ({
                url: "api/broker-note/generate/pdf/",
                method: "POST",
                body,
                responseHandler: (response) => response.blob(),
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const pdfBlob = await queryFulfilled;
                    const url = window.URL.createObjectURL(pdfBlob.data);
                    window.open(url, '_blank');
                } catch (error) {
                    console.error("Failed to generate PDF:", error);
                }
            },
        }),
        
        postBrokerNote: builder.mutation<ApiResponse<BrokerNoteResponse>, BrokerNotePayload>({
            query: (body) => ({
                url: "api/broker-note/",
                method: "POST",
                body
            }),
        }),
        updateBrokerNote: builder.mutation<ApiResponse<BrokerNoteResponse>,BrokerNotePayload & {brokerNoteId:string}>({
            query: body => ({
                url: `api/broker-note/${body.brokerNoteId}`,
                method: "PATCH",
                body
            }),

        }),
        getBrokerNoteDetail: builder.query<ApiResponse<BrokerNoteResponse>, string>({
            query: (brokerNoteId) => `api/broker-note/${brokerNoteId}`
        }),
        getAllBrokerNote: builder.query<ApiResponse<BrokerNoteResponse[]>,{latest?:boolean} | void>({
            query: (searchParams) => {
                const queryParts = [];
                if (searchParams) {
                    if (searchParams.latest) {
                        queryParts.push(`latest=${encodeURIComponent(searchParams.latest)}`);
                    }
                }
               const queryString = queryParts.length > 0 ? `?${queryParts.join('&')}` : '';
               return `api/broker-note${queryString}`;
            }
        }),
        getBrokerSystemPrompt: builder.query<ApiResponse<SystemPromptType<BrokerNoteFieldTypes>[]>, void>({
           query: () => `api/broker-note/system-prompt/`
        }),
        updateBrokerSystemPrompt: builder.mutation<ApiResponse<SystemPromptType<BrokerNoteFieldTypes>>, SystemPromptPayload>({
            query: body => ({
                url: `api/broker-note/system-prompt/${body.promptId}`,
                method: "PATCH",
                body
            }),
        })  
        
    })
});
export const { useGenerateBrokerNoteMutation,
     useGenerateBrokerNotePDFMutation, 
     usePostBrokerNoteMutation, 
     useGetAllBrokerNoteQuery,
     useUpdateBrokerNoteMutation, 
     useGetBrokerNoteDetailQuery,
     useGetBrokerSystemPromptQuery,
     useUpdateBrokerSystemPromptMutation 
    } = brokerNoteGeneratorAPI;
