import { CSSProperties } from "react";
import { Row, Col, theme, Skeleton } from "antd"
import { FileAddOutlined, RocketOutlined, SnippetsOutlined, UsergroupAddOutlined } from "@ant-design/icons"


import { Container } from "@/components/Layout"

import { useGetDashboardDataQuery } from "../api";
const { useToken } = theme;

import DashboardCard from "../components/DashboardCard";

export const Dashboard = () => {
    const {data: dashboardApiData, isLoading: dashboardApiDataLoading}=useGetDashboardDataQuery(undefined, {refetchOnMountOrArgChange: true});

    const {token} = useToken();
    const iconStyle:CSSProperties = {color: token.colorPrimary, fontSize:"24px"}
    return (
        <div style={{width:"100%", alignSelf:"baseline", paddingTop:"1rem"}}>
            <Container fullWidth={true}>
                <Row gutter={20}>
                    <Col md={6} xs={24}>
                       {dashboardApiData && <DashboardCard icon={<UsergroupAddOutlined style={iconStyle} />} title="TOTAL CONTACTS" count={dashboardApiData.data.contact_count} />}
                       {dashboardApiDataLoading && <Skeleton avatar paragraph={{ rows: 4 }} />}
                    </Col>
                    <Col md={6} xs={24}>
                       {dashboardApiData && <DashboardCard icon={<RocketOutlined style={iconStyle} />} title="TOTAL DEALS" count={dashboardApiData.data.opportunity_count} />}
                       {dashboardApiDataLoading && <Skeleton avatar paragraph={{ rows: 4 }} />}
                    </Col>
                    <Col md={6} xs={24}>
                       {dashboardApiData && <DashboardCard icon={<FileAddOutlined style={iconStyle} />} title="TOTAL COMPLIANCE NOTE" count={dashboardApiData.data.compliance_note_count} />}
                       {dashboardApiDataLoading && <Skeleton avatar paragraph={{ rows: 4 }} />}
                    </Col>
                    <Col md={6} xs={24}>
                       {dashboardApiData && <DashboardCard icon={<SnippetsOutlined style={iconStyle} />} title="TOTAL BROKER NOTE NOTE" count={dashboardApiData.data.broker_note_count} />}
                       {dashboardApiDataLoading && <Skeleton avatar paragraph={{ rows: 4 }} />}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
