import { FC } from "react";
import { DeleteOutlined, EyeFilled, EyeOutlined, FileTextOutlined, FormOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip, Modal } from "antd";
import { useParams } from "react-router-dom";
type ActionButtonType = 'select' | 'delete' | 'view' | 'create'

interface ActionButtonsRendererProps {
    buttons: ActionButtonType [];
    selectTooltip?: string;
    onAction: (data: any ) => void;
    handleDelete?: (data: any) => void;
    onView?: (data: any) => void;
    onCreate?: (data: any) => void;
    data: any;
    // other props...
}

const ActionButtonsRenderer: FC<ActionButtonsRendererProps> = ({ onAction, handleDelete, onView,onCreate, data, selectTooltip = "Populate Fields", buttons }) => {
    const { requestId } = useParams<{ requestId?: string }>();
    const hasSelect = buttons.includes('select');
    const hasDelete = buttons.includes('delete');
    const hasView = buttons.includes('view');
    const hasCreate = buttons.includes('create')
    
    const processItem = () => {
        onAction(data);
    };
    const delteItem = () => {
        if(handleDelete){
            handleDelete(data)
        }
        
    };

    const viewItem = () => {
        if(onView){
            onView(data)
        }
    }

    const createItem = () => {
        if(onCreate){
            onCreate(data)
        }
    }

    return (
        <Flex gap="small">
            {requestId && <Tooltip title={selectTooltip}>
                <Button size="small" onClick={processItem} type="text" shape="circle" icon={<FileTextOutlined />} />
            </Tooltip>}
            {hasDelete && <Tooltip title="Delete Deal">
                <Button size="small" onClick={delteItem} type="text" danger shape="circle" icon={<DeleteOutlined />} />
            </Tooltip>}
            {hasView && <Tooltip title="View Deal">
                <Button size="small" onClick={viewItem} type="text" shape="circle" icon={<EyeOutlined />} />
            </Tooltip>}
            {hasCreate && <Tooltip title="Create Deal">
                <Button size="small" onClick={createItem} type="text" shape="circle" icon={<FormOutlined />} />
            </Tooltip>}
        </Flex>
    );
};

export default ActionButtonsRenderer;
