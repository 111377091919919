import { FC } from "react";
import { DeleteOutlined, FileTextOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, DiffOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip } from "antd";

export type ActionButtonType = "delete" | "view" | "edit" | "complianceNote" | "brokerNote"

interface ActionButtonsRendererProps {
    buttons: ActionButtonType[];
    handleDelete?: (data: any) => void;
    handleEdit?: (data: any) => void;
    handleView?: (data: any) => void;
    handleGenerateComplianceNote?: (data: any) => void;
    handleGenerateBrokerNote?: (dayjson: any) => void;
    tooltipTitles?: {
        view?: string;
        edit?: string;
        complianceNote?: string;
        brokerNote?: string;
        delete?: string;
    };
    data: any;
}

const ActionButtonsRenderer: FC<ActionButtonsRendererProps> = ({
    handleDelete,
    handleEdit,
    handleView,
    handleGenerateComplianceNote,
    handleGenerateBrokerNote,
    data,
    tooltipTitles = {
        view: "View",
        edit: "Edit",
        complianceNote: "Generate Compliance Note",
        brokerNote: "Generate Broker Note",
        delete: "Delete"
    },
    buttons
}) => {
    const hasDelete = buttons.includes("delete");
    const hasView = buttons.includes("view");
    const hasEdit = buttons.includes("edit");
    const hasComplianceNote = buttons.includes("complianceNote");
    const hasBrokerNote = buttons.includes("brokerNote");

    const delteItem = () => {
        if (handleDelete) {
            handleDelete(data);
        }
    };
    const editItem = () => {
        if (handleEdit) {
            handleEdit(data);
        }
    };
    const viewItem = () => {
        if (handleView) {
            handleView(data);
        }
    };

    const generateComplianceNote = () => {
        if (handleGenerateComplianceNote) {
            handleGenerateComplianceNote(data);
        }
    };

    const generateBrokerNote = () => {
        if (handleGenerateBrokerNote) {
            handleGenerateBrokerNote(data);
        }
    };

    return (
        <Flex gap="small">
            {hasView && (
                <Tooltip title={tooltipTitles.view}>
                    <Button size="small" onClick={viewItem} type="text" icon={<EyeOutlined />} />
                </Tooltip>
            )}

            {hasEdit && (
                <Tooltip title={tooltipTitles.edit}>
                    <Button size="small" onClick={editItem} type="text" icon={<EditOutlined />} />
                </Tooltip>
            )}
            {hasComplianceNote && (
                <Tooltip title={tooltipTitles.complianceNote}>
                    <Button size="small" onClick={generateComplianceNote} type="text" icon={<FilePdfOutlined />} />
                </Tooltip>
            )}

            {hasBrokerNote && (
                <Tooltip title={tooltipTitles.brokerNote}>
                    <Button size="small" onClick={generateBrokerNote} type="text" icon={<DiffOutlined />} />
                </Tooltip>
            )}

            {hasDelete && (
                <Tooltip title={tooltipTitles.delete}>
                    <Button size="small" onClick={delteItem} type="text" danger icon={<DeleteOutlined />} />
                </Tooltip>
            )}
        </Flex>
    );
};

export default ActionButtonsRenderer;
