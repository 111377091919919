import { FC, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { countries, getEmojiFlag, TCountryCode, getCountryCode } from "countries-list";
import { ContactType, DealFormType, SalaryFrequency } from "@/pages/deals/types";
import { InputField, SelectField } from "@/components/Form";
import { UserPromptFormTypes } from "@/pages/noteGeneration/types";
import { FormFieldDataType } from "@/pages/deals/components/DealForm";
import { CoutriesOptionType } from "@/pages/deals/components/DealForm/forms/BasicDetailForm";
import { SALARY_FREQUENCY } from "@/devFrontData/optionList";
import { calculateMonthlyAmount } from "@/pages/deals/utils";

const schema = yup.object().shape({
    contactCitizenship: yup.string().nullable().notRequired(),
    contactResidency: yup.string().nullable().notRequired(),
    employment: yup.string(),
    occupation: yup.string(),
    secondaryContactCitizenship: yup.string().nullable().notRequired(),
    secondaryContactResidency: yup.string().nullable().notRequired(),
    employment_2: yup.string(),
    occupation_2: yup.string(),
    salaryFreq: yup.string(),
    salary: yup.number(),
    calMonthSalary: yup.number(),
    allowances: yup.number(),
    allowanceFreq: yup.string(),
    calcAllowance: yup.number(),
    commission: yup.number(),
    commFreq: yup.string(),
    calcCommission: yup.number(),
    bonusThis: yup.number(),
    salary_2: yup.number(),
    salaryFreq_2: yup.string(),
    calMonthSalary_2: yup.number(),
    allowances_2: yup.number(),
    allowanceFreq_2: yup.string(),
    calcAllowance2: yup.number(),

    commission_2: yup.number(),
    commFreq_2: yup.string(),
    calcCommission2: yup.number(),
});

type formType = yup.InferType<typeof schema>;

interface ComplianceLCFormProps {
    isLoading: boolean;
    formId: UserPromptFormTypes;
    formData?: Partial<FormFieldDataType> | null;
    contactData: ContactType | null;
    secondaryContactData: ContactType | null;
    handleFormSubmit: ({ id, data }: { id: UserPromptFormTypes; data: Partial<DealFormType> }) => void;
}

const ComplianceLCForm: FC<ComplianceLCFormProps> = ({ formId, formData, contactData, secondaryContactData, handleFormSubmit, isLoading }) => {
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });
    const [countryOptions, setCountryOptions] = useState<CoutriesOptionType[]>([]);

    useEffect(() => {
        let autoCompleteOptions = [];
        for (let country in countries) {
            autoCompleteOptions.push({
                value: country,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{getEmojiFlag(country as TCountryCode)}</span> &nbsp; {countries[country as TCountryCode]?.name}
                    </div>
                )
            });
        }
        setCountryOptions(autoCompleteOptions);
    }, []);

    useEffect(() => {
        if (formData) {
            setValue("employment", formData?.employment ?? "");
            setValue("occupation", formData?.occupation ?? "");
            setValue("employment_2", formData?.employment_2 ?? "");
            setValue("occupation_2", formData?.occupation_2 ?? "");
            setValue("salaryFreq", formData?.salaryFreq ?? "");
            setValue("salary", formData?.salary ?? 0);
            setValue("calMonthSalary", formData?.calMonthSalary ?? 0);
            setValue("allowances", formData?.allowances ?? 0);
            setValue("allowanceFreq", formData?.allowanceFreq ?? "");
            setValue("calcAllowance", formData?.calcAllowance ?? 0);
            setValue("commission", formData?.commission ?? 0);
            setValue("commFreq", formData?.commFreq ?? "");
            setValue("calcCommission", formData?.calcCommission ?? 0);
            setValue("bonusThis", formData?.bonusThis ?? 0);

            setValue("salary_2", formData?.salary_2 ?? 0);
            setValue("salaryFreq_2", formData?.salaryFreq_2 ?? "");
            setValue("calMonthSalary_2", formData?.calMonthSalary_2 ?? 0);

            setValue("allowances_2", formData?.allowances_2 ?? 0);
            setValue("allowanceFreq_2", formData?.allowanceFreq_2 ?? "");
            setValue("calcAllowance2", formData?.calcAllowance2 ?? 0);

            setValue("commission_2", formData?.commission_2 ?? 0);
            setValue("commFreq_2", formData?.commFreq_2 ?? "");
            setValue("calcCommission2", formData?.calcCommission2 ?? 0);


        }
    }, [formData]);

    useEffect(() => {
        if (contactData) {
            setValue("contactResidency", getCountryCode(contactData?.residency ?? "") as string);
            setValue("contactCitizenship", contactData?.citizenship ?? "");
        }
    }, [contactData]);

    useEffect(() => {
        if (contactData) {
            setValue("secondaryContactResidency", getCountryCode(secondaryContactData?.residency ?? "") as string);
            setValue("secondaryContactCitizenship", secondaryContactData?.residency ?? "");
        }
    }, [secondaryContactData]);

    const watchSalary = watch("salary");
    const watchSalaryFreq = watch("salaryFreq");
    const watchSalary2 = watch("salary_2");
    const watchSalaryFreq2 = watch("salaryFreq_2");

    const watchAllowance = watch("allowances");
    const watchAllowanceFreq = watch("allowanceFreq");
    const watchAllowance2 = watch("allowances_2");
    const watchAllowanceFreq2 = watch("allowanceFreq_2");

    const watchCommission = watch("commission");
    const watchCommissionFreq = watch("commFreq");
    const watchCommission2 = watch("commission_2");
    const watchCommissionFreq2 = watch("commFreq_2");

    useEffect(() => {
        if (watchSalary && watchSalaryFreq) {
            const baseSalary = calculateMonthlyAmount(watchSalaryFreq as SalaryFrequency, watchSalary);
            setValue("calMonthSalary", baseSalary);
        }
    }, [watchSalary, watchSalaryFreq]);

    useEffect(() => {
        if (watchAllowance && watchAllowanceFreq) {
            const baseAllowance = calculateMonthlyAmount(watchAllowanceFreq as SalaryFrequency, watchAllowance);
            setValue("calcAllowance", baseAllowance);
        }
    }, [watchAllowance, watchAllowanceFreq]);

    useEffect(() => {
        if (watchCommission && watchCommissionFreq) {
            const baseCommission = calculateMonthlyAmount(watchCommissionFreq as SalaryFrequency, watchCommission);
            setValue("calcCommission", baseCommission);
        }
    }, [watchCommission, watchCommissionFreq]);

    useEffect(() => {
        if (watchSalary2 && watchSalaryFreq2) {
            const baseSalary = calculateMonthlyAmount(watchSalaryFreq2 as SalaryFrequency, watchSalary2);
            setValue("calMonthSalary_2", baseSalary);
        }
    }, [watchSalary2, watchSalaryFreq2]);

    useEffect(() => {
        if (watchAllowance2 && watchAllowanceFreq2) {
            const baseAllowance = calculateMonthlyAmount(watchAllowanceFreq2 as SalaryFrequency, watchAllowance2);
            setValue("calcAllowance2", baseAllowance);
        }
    }, [watchAllowance2, watchAllowanceFreq2]);

    useEffect(() => {
        if (watchCommission2 && watchCommissionFreq2) {
            const baseCommission = calculateMonthlyAmount(watchCommissionFreq2 as SalaryFrequency, watchCommission2);
            setValue("calcCommission2", baseCommission);
        }
    }, [watchCommission2, watchCommissionFreq2]);

    const onSubmit: SubmitHandler<formType> = data => {
        handleFormSubmit({ id: formId, data });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xl={24}>
                    <InputField
                        label="Nationality1"
                        name="contactCitizenship"
                        control={control}
                        error={errors.contactCitizenship?.message ?? ""}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <SelectField
                        options={countryOptions}
                        label="Residency1"
                        name="contactResidency"
                        control={control}
                        error={errors.contactResidency?.message ?? ""}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <InputField name="Employment1" control={control} label="Employment" error={errors.employment?.message} formDirection="row" />
                </Col>
                <Col xl={24}>
                    <InputField name="Occupation1" control={control} label="Occupation" error={errors.occupation?.message} formDirection="row" />
                </Col>

                <Col xl={24}>
                    <InputField prefix="$" name="salary" control={control} label="Salary" error={errors.salary?.message} formDirection="row" />
                </Col>

                <Col xl={24}>
                    <SelectField
                        name="salaryFreq"
                        control={control}
                        label="Salary Frequency"
                        placeholder="Salary Frequency"
                        error={errors.salaryFreq?.message}
                        options={SALARY_FREQUENCY}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <InputField
                        prefix="$"
                        name="calMonthSalary"
                        variant="filled"
                        readonly={true}
                        control={control}
                        label="Average Salary1"
                        error={errors.calMonthSalary?.message}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <InputField prefix="$" name="allowances" control={control} label="Allowances" error={errors.allowances?.message} formDirection="row" />
                </Col>
                <Col xl={24}>
                    <SelectField
                        name="allowanceFreq"
                        control={control}
                        label="Allowance Frequency"
                        placeholder="Allowance Frequency"
                        error={errors.allowanceFreq?.message}
                        options={SALARY_FREQUENCY}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <InputField
                        prefix="$"
                        name="calcAllowance"
                        variant="filled"
                        readonly={true}
                        control={control}
                        label="Average Allowance1"
                        error={errors.calcAllowance?.message}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <InputField
                        prefix="$"
                        type="number"
                        name="commission"
                        control={control}
                        label="Commission"
                        error={errors.commission?.message}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <SelectField
                        name="commFreq"
                        control={control}
                        label="Commission Frequency"
                        placeholder="Commission Frequency"
                        error={errors.commFreq?.message}
                        options={SALARY_FREQUENCY}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <InputField
                        prefix="$"
                        name="calcCommission"
                        variant="filled"
                        readonly={true}
                        control={control}
                        label="Average Commission1"
                        error={errors.calcCommission?.message}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <InputField
                        prefix="$"
                        name="bonusThisYear1"
                        control={control}
                        type="number"
                        label="Bonus this year"
                        error={errors.bonusThis?.message}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <InputField
                        label="Nationality2"
                        name="secondaryContactCitizenship"
                        control={control}
                        error={errors.secondaryContactCitizenship?.message ?? ""}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <SelectField
                        options={countryOptions}
                        label="Residency2"
                        name="secondaryContactResidency"
                        control={control}
                        error={errors.secondaryContactResidency?.message ?? ""}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <InputField label="Employment2" name="employment_2" control={control} error={errors.employment_2?.message} formDirection="row" />
                </Col>

                <Col xl={24}>
                    <InputField label="Occupation2" name="occupation_2" control={control} error={errors.occupation_2?.message} formDirection="row" />
                </Col>

                <Col xl={24}>
                    <InputField prefix="$" name="salary_2" control={control} label="Salary2" error={errors.salary_2?.message} formDirection="row" />
                </Col>

                <Col xl={24}>
                    <SelectField
                        name="salaryFreq_2"
                        control={control}
                        label="Salary Frequency2"
                        placeholder="Salary Frequency"
                        error={errors.salaryFreq_2?.message}
                        options={SALARY_FREQUENCY}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <InputField
                        prefix="$"
                        readonly={true}
                        variant="filled"
                        name="calMonthSalary_2"
                        control={control}
                        label="Average Salary2"
                        error={errors.calMonthSalary_2?.message}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <InputField prefix="$" name="allowances_2" control={control} label="Allowances2" error={errors.allowances_2?.message} formDirection="row" />
                </Col>
                <Col xl={24}>
                    <SelectField
                        name="allowanceFreq_2"
                        control={control}
                        label="Allowance Frequency2"
                        placeholder="Allowance Frequency"
                        error={errors.allowanceFreq_2?.message}
                        options={SALARY_FREQUENCY}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <InputField
                        prefix="$"
                        name="calcAllowance2"
                        variant="filled"
                        readonly={true}
                        control={control}
                        label="Average Allowance2"
                        error={errors.calcAllowance2?.message}
                        formDirection="row"
                    />
                </Col>


                <Col  xl={24}>
                    <InputField prefix="$" name="commission_2" control={control} label="Commission2" error={errors.commission_2?.message}  formDirection="row"/>
                </Col>
                <Col  xl={24}>
                    <SelectField
                        name="commFreq_2"
                        control={control}
                        label="Commission Frequency2"
                        placeholder="Commission Frequency"
                        error={errors.commFreq_2?.message}
                        options={SALARY_FREQUENCY}
                        formDirection="row"
                    />
                </Col>
                <Col  xl={24}>
                    <InputField prefix="$" name="calcCommission2" variant="filled" readonly={true} control={control} label="Average Commission2" error={errors.calcCommission2?.message} formDirection="row" />
                </Col>

                <Col xl={24}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Update
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default ComplianceLCForm;
