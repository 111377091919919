import { Card, theme, Typography } from "antd"

import {  FC, ReactNode } from "react";

const { useToken } = theme;
const {Title, Text} = Typography;


interface DashboardCardProps {
    icon: ReactNode;
    title: string;
    count: number;
}

const DashboardCard:FC<DashboardCardProps> = ({icon, title, count}) => {
    const {token} = useToken();
    return (
        <Card>
            <div style={{marginBottom:"1rem"}}>
                <div style={{display:"inline-flex", alignItems:"center",borderRadius:"100%", justifyContent:"center", width:"50px", height:"50px", background:"rgb(243,243,243)"}}>
                    {icon}
                </div>
            </div>
            <Text style={{color:token.colorTextSecondary}}>{title}</Text>
            <Title  style={{marginTop:"5px", fontWeight:"600"}} level={4}>{count}</Title>
        </Card>
    )
}

export default DashboardCard;