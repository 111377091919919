import { Row, theme } from "antd";
import Title from "antd/es/typography/Title";
import { FC, ReactNode } from "react";
import { DealFormType } from "../../types";
import { DEAL_CATEGORY } from "../../constant";
import { formatTitle } from "@/utils/common.utils";
import InfoDisplay from "@/components/UI/InfoDisplay";

const { useToken } = theme;

const DetailContainer = ({ children }: { children: ReactNode }) => {
    const { token } = useToken();
    return <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>{children}</div>;
};

const DetailTitle = ({ title }: { title: string }) => {
    const { token } = useToken();
    return (
        <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>
            <Title level={4} style={{ fontWeight: "400", margin: "0", padding: "1rem 0" }}>
                {title}
            </Title>
        </div>
    );
};

interface DetailListPanelProps {
    dealDetail: DealFormType;
}

const DetailListPanel: FC<DetailListPanelProps> = ({ dealDetail }) => {
    return (
        <>
            <div>
                <DetailContainer>
                    <DetailTitle title="Broker Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {DEAL_CATEGORY.brokerDetail.map((item: string, index) => {
                                return (
                                    <InfoDisplay
                                        key={index}
                                        label={formatTitle(item)}
                                        value={(dealDetail[item as keyof DealFormType] as string) ?? ""}
                                        // leftColValue={24}
                                        // rightColValue={24}
                                        // labelAlign="left"
                                    />
                                );
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Contact Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {DEAL_CATEGORY.contactDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={formatTitle(item)} value={(dealDetail[item as keyof DealFormType] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Loan Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {DEAL_CATEGORY.loanDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={formatTitle(item)} value={(dealDetail[item as keyof DealFormType] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Applicant Occupation" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {DEAL_CATEGORY.applicantOccupation.map((item: string, index) => {
                                return <InfoDisplay key={index} label={formatTitle(item)} value={(dealDetail[item as keyof DealFormType] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Family Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {DEAL_CATEGORY.familyDetails.map((item: string, index) => {
                                return <InfoDisplay key={index} label={formatTitle(item)} value={(dealDetail[item as keyof DealFormType] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                <DetailContainer>
                    <DetailTitle title="Card Detail" />
                    <div style={{ padding: "1rem 0" }}>
                        <Row gutter={24}>
                            {DEAL_CATEGORY.cardDetail.map((item: string, index) => {
                                return <InfoDisplay key={index} label={formatTitle(item)} value={(dealDetail[item as keyof DealFormType] as string) ?? ""} />;
                            })}
                        </Row>
                    </div>
                </DetailContainer>

                {dealDetail?.coApplicationStatus && (
                    <>
                        <DetailContainer>
                            <DetailTitle title="Co Applicant Detail" />
                            <div style={{ padding: "1rem 0" }}>
                                <Row gutter={24}>
                                    {DEAL_CATEGORY.coApplicantDetail.map((item: string, index) => {
                                        return (
                                            <InfoDisplay
                                                key={index}
                                                label={formatTitle(item)}
                                                value={(dealDetail[item as keyof DealFormType] as string) ?? ""}
                                            />
                                        );
                                    })}
                                </Row>
                            </div>
                        </DetailContainer>

                        <DetailContainer>
                            <DetailTitle title="Co Applicant Occupation" />
                            <div style={{ padding: "1rem 0" }}>
                                <Row gutter={24}>
                                    {DEAL_CATEGORY.coApplicantOccupation.map((item: string, index) => {
                                        return (
                                            <InfoDisplay
                                                key={index}
                                                label={formatTitle(item)}
                                                value={(dealDetail[item as keyof DealFormType] as string) ?? ""}
                                            />
                                        );
                                    })}
                                </Row>
                            </div>
                        </DetailContainer>
                    </>
                )}
            </div>
        </>
    );
};

export default DetailListPanel;
