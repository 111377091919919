import { FC, useMemo, useRef, useState } from "react";
import { Skeleton } from "antd";

import { dateTimeTransformer } from "@/utils/common.utils";

//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

//Store
import { useGetAllComplianceNoteQuery } from "@/store/apis/complianceNoteApi";
import ActionButtonsRenderer, { ActionButtonType } from "@/pages/deals/components/ActionButtonsRenderer";
import Scrollbars from "rc-scrollbars";

const TextCellRenderer: FC<{ value: string }> = ({ value }) => {
    return <Scrollbars style={{height:"200px"}}>{value}</Scrollbars>;
  };

  interface ComplianceNoteTableProps {
    actionButtons?: ActionButtonType[];
    viewComplianceNote?: (data:any) => void;
    editComplianceNote?: (data:any) => void;
  }

const ComplianceNoteTable:FC<ComplianceNoteTableProps> = ({actionButtons, viewComplianceNote, editComplianceNote}) => {
    const gridRef = useRef<any>(null);
    const { data, isError, isLoading, isSuccess } = useGetAllComplianceNoteQuery(undefined, {refetchOnMountOrArgChange: true});  

    const handleView = (data:any) => {
        if(viewComplianceNote){
            viewComplianceNote(data)
        }
    }
    const handleEdit = (data:any) => {
        if(editComplianceNote) {
            editComplianceNote(data)
        }
    }

    const formatDate = (params: any) => {
        return dateTimeTransformer(params.value)
      };

    const [colDefs, setColDefs] = useState<ColDef[]>(() => {

        const columns: ColDef[] =  [
            {
                headerName: "Deal Name",
                field: "opportunity.name",
                headerTooltip: "Deal Name",
            },
             {
                headerName: "Updated At",
                field: "updated_at",
                valueFormatter:formatDate
            },
            {
                headerName: "Created By",
                field: "created_by",
                filter: true
            },
            {
                headerName: "Updated By",
                field: "updated_by",
                filter: true
            },
            {
                headerName: "Lender Note",
                field: "lender_loan_note",
                headerTooltip: "Generated Lender Note",
                cellRendererParams: TextCellRenderer,
                filter: false
            },
            {
                headerName: "Actions",
                cellRenderer: ActionButtonsRenderer,
                cellRendererParams: {
                    buttons:actionButtons,
                    handleView: handleView,
                    handleEdit: handleEdit,
                },
                width: 150,
                suppressMenu: true,
                pinned: "right",
                filter: false,
            }
        ];
    
        return columns;
    });

    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    return (
        <>
            <div>
                {isLoading ? (
                    <Skeleton />
                ) : (
                    <div className="ag-theme-quartz" style={{ height: "calc(100vh - 280px)" }}>
                        <AgGridReact 
                            defaultColDef={defaultColDef} 
                            tooltipInteraction={true} 
                            ref={gridRef} 
                            rowData={data?.data} 
                            columnDefs={colDefs} 
                            sideBar={"columns"} 
                            pagination={true} 
                            paginationPageSize={20} 
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ComplianceNoteTable;
