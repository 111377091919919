import { Col, Flex, Input, Row, } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AsideNoteheading from "./AsideNoteHeading";

import { getFundsRequired, getLoanAmount, getStampDuty } from "@/pages/noteGeneration/utils";
import { DealFormType } from "@/pages/deals/types";
import Title from "antd/es/typography/Title";
import { InputField } from "@/components/Form";
import { themeConfig } from "@/config";
import { LoanDetailFormType } from "../LoanDetailForm";
import { BrokerNoteResponse } from "@/pages/noteGeneration/types";

const fundsDetailSchema = yup.object().shape({
    bank1: yup.number(),
    bank2: yup.number(),
    bank3: yup.number(),
    shares: yup.number(),
    cashGift: yup.number(),
});

export type FundsDetailFormType = yup.InferType<typeof fundsDetailSchema>;

export interface FundsDetailMethods {
    triggerValidation: () => Promise<{ isValid: boolean; data?: FundsDetailFormType }>;
}
interface FundsDetailsProps {
    handleFormSubmit?: (data: FundsDetailFormType) => void;
    setFormData?: (data: FundsDetailFormType) => void;
    allValues: Partial<DealFormType> | null;
    loanDetailFormValue: LoanDetailFormType | null;
    brokerNoteAPIDetail: BrokerNoteResponse | null;
}

const FundsDetail= forwardRef<FundsDetailMethods, FundsDetailsProps>(({ allValues, loanDetailFormValue, handleFormSubmit, setFormData, brokerNoteAPIDetail }, ref) => {
    const {
        control,
        handleSubmit,
        watch,
        trigger,
        setFocus,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(fundsDetailSchema),
        defaultValues: {
            bank1: 0,
            bank2: 0,
            bank3: 0,
            shares: 0,
            cashGift: 0,
        }
    });
    const [totalFunds, setTotalFunds] = useState(0);

    useImperativeHandle(ref, () => ({
        triggerValidation: async () => {
            const result = await trigger();
            if (result) {
                return { isValid: true, data: getValues() };
            }
            return { isValid: false };
        }
    }));

    /**
     * When error is encountered it scrolls to the error field
     */
    useEffect(() => {
        const firstErrorField = Object.keys(errors)[0];
        if (firstErrorField) {
            setFocus(firstErrorField as keyof FundsDetailFormType);
        }
    }, [Object.keys(errors).length]);

    /**
     ** Passes the updated fields value to the parent component
     */
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const total = Object.values(value).reduce((acc, current) => acc + Number(current), 0);
            setTotalFunds(total);
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            if (setFormData) {
                setFormData(updatedData);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if(brokerNoteAPIDetail) {
            setValue("bank1", brokerNoteAPIDetail?.funds_available?.bank_1)
            setValue("bank2", brokerNoteAPIDetail?.funds_available?.bank_2)
            setValue("bank3", brokerNoteAPIDetail?.funds_available?.bank_3)
            setValue("shares", brokerNoteAPIDetail?.funds_available?.shares)
            setValue("cashGift", brokerNoteAPIDetail?.funds_available?.cash_gift)
        }
    }, [brokerNoteAPIDetail]);

    const onSubmit: SubmitHandler<FundsDetailFormType> = data => {
        if(handleFormSubmit){
            handleFormSubmit(data)
        }
    };
    return (
        <Row gutter={themeConfig.rowGutterMd} style={{ marginBottom: "10px" }}>
            <Col xs={24} md={24}>
                <AsideNoteheading title="Funds to Complete" />
            </Col>
            <Col xs={24} md={24}>
                <Row gutter={themeConfig.rowGutterMd}>
                    <Col xs={12} md={12}>
                        <table className="info-table">
                            <thead>
                                <tr>
                                    <th colSpan={2} style={{ textAlign: "center" }}>
                                        Funds to Complete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Purchase Price:</td>
                                    <td>{loanDetailFormValue?.propertyValue ?? ""}</td>
                                </tr>
                                <tr>
                                    <td>Less: Loan Amount:</td>
                                    <td>{`(${getLoanAmount(loanDetailFormValue?.lvr ?? 0, loanDetailFormValue?.propertyValue ?? 0)})`}</td>
                                </tr>
                                <tr>
                                    <td>Less: Cash Out Amount:</td>
                                    <td>{`(${allValues?.cashOutAmount})`}</td>
                                </tr>
                                <tr>
                                    <td>Add: Stamp, Duty, mics:</td>
                                    <td>{getStampDuty(loanDetailFormValue?.propertyValue ?? 0)}</td>
                                </tr>
                                <tr>
                                    <td>Funds Required:</td>
                                    <td>{getFundsRequired(allValues as DealFormType)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col xs={12} md={12}>
                        <Title style={{ margin: "0 0  1rem"}} level={5}>Funds Available</Title>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputField
                                label="BANK #001"
                                name="bank1"
                                control={control}
                                error={errors.bank1?.message ?? ""}
                                formDirection="row"
                                prefix="$"
                                style={{marginBottom:"10px"}}
                            />
                            <InputField
                                label="BANK #002"
                                name="bank2"
                                control={control}
                                error={errors.bank2?.message ?? ""}
                                formDirection="row"
                                prefix="$"
                                style={{marginBottom:"10px"}}
                            />
                            <InputField
                                label="BANK #003"
                                name="bank3"
                                control={control}
                                error={errors.bank3?.message ?? ""}
                                formDirection="row"
                                prefix="$"
                                style={{marginBottom:"10px"}}
                            />
                            <InputField
                                label="SHARES #0001"
                                name="shares"
                                control={control}
                                error={errors.shares?.message ?? ""}
                                formDirection="row"
                                prefix="$"
                                style={{marginBottom:"10px"}}
                            />
                            <InputField
                                label="Cash Gift - parents"
                                name="cashGift"
                                control={control}
                                error={errors.cashGift?.message ?? ""}
                                formDirection="row"
                                prefix="$"
                                style={{marginBottom:"10px"}}
                            />
                        </form>
                        <Flex justify="space-between">
                            <Title level={5} style={{margin: 0, flex:"0 0 40%", maxWidth: "40%"}}>Total</Title>
                            <Input readOnly={true} value={totalFunds} prefix="$" />
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

export default FundsDetail;
