import { Col, Row, Typography } from "antd";
import { NoteHeading } from "@/components/UI";
import { FC } from "react";

import { DealFormType, BrokerContactDetailsType } from "@/pages/deals/types";

const { Text } = Typography;

interface ProcessorContactDetailProps {
    allValues: Partial<DealFormType> | null;
    brokerDetail: BrokerContactDetailsType | null;
}
const ProcessorContactDetail: FC<ProcessorContactDetailProps> = ({ allValues, brokerDetail }) => {
    console.log("brokerDetail", brokerDetail)
    return (
        <div style={{paddingBottom:"1rem"}}>
            <NoteHeading title="Processor Contact Details" />
            <Row gutter={8}>
                <Col xs={24} md={6}></Col>
                <Col xs={24} md={6}>
                    <Text strong>Name</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text strong>Email</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text strong>Phone</Text>
                </Col>
            </Row>
            {brokerDetail?.broker && <Row gutter={8}>
                <Col xs={24} md={6}>
                    <Text type="secondary">Primary Broker</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{brokerDetail?.broker?.fullname}</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{brokerDetail?.broker?.email}</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{brokerDetail?.broker.phone}</Text>
                </Col>
            </Row>}
            {brokerDetail?.processor && <Row gutter={8}>
                <Col xs={24} md={6}>
                    <Text type="secondary">Processor</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{brokerDetail?.processor?.fullname}</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{brokerDetail?.processor?.email}</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{brokerDetail?.processor.phone}</Text>
                </Col>
            </Row>}
        </div>
    );
};

export default ProcessorContactDetail;
