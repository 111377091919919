import { configureStore } from "@reduxjs/toolkit";
//slices


import auth from "./slices/auth/authSlice";
import opportunityForm from "./slices/opportunity/opportunityFormSlice";
import complianceForm from "./slices/compliance/complianceFormSlice";
import common from "./slices/common/commonSlice";
import brokerForm from "./slices/brokerNote/brokerFormSlice";

//apis
import { loanRequestApi } from "./apis/loanApi";
import { forexApi } from "./apis/forexApi";

import { authApi } from "./apis/authApi";
import { brokerNoteApi } from "./apis/brokerNoteApi";
import { anzServiceApi } from "./apis/anzApi";
import { opportunityServiceApi } from "./apis/opportunityApi";
import { complianceNoteApi } from "./apis/complianceNoteApi";
import { userApi } from "./apis/userApi";
import { dashboardApi } from "@/pages/dashboard/api";
import { brokerNoteGeneratorAPI } from "@/pages/noteGeneration/api/brokerNoteGeneratorAPI";
import { utilsApi } from "./apis/utilsApi";
import { accountApi } from "@/pages/account/api/accountApi";
import { policiesAPI } from "@/pages/policies/api/policiesAPI";

export const store = configureStore({
    reducer: {
        [loanRequestApi.reducerPath]: loanRequestApi.reducer,
        [forexApi.reducerPath]: forexApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [brokerNoteApi.reducerPath]: brokerNoteApi.reducer,
        [anzServiceApi.reducerPath]: anzServiceApi.reducer,
        [opportunityServiceApi.reducerPath]: opportunityServiceApi.reducer,
        [complianceNoteApi.reducerPath]: complianceNoteApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [brokerNoteGeneratorAPI.reducerPath]: brokerNoteGeneratorAPI.reducer,
        [utilsApi.reducerPath]: utilsApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [policiesAPI.reducerPath]: policiesAPI.reducer,
        auth: auth,
        opportunityForm: opportunityForm,
        complianceForm: complianceForm,
        common: common,
        brokerForm: brokerForm
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            loanRequestApi.middleware,
            forexApi.middleware,
            authApi.middleware,
            brokerNoteApi.middleware,
            anzServiceApi.middleware,
            opportunityServiceApi.middleware,
            complianceNoteApi.middleware,
            userApi.middleware,
            dashboardApi.middleware,
            brokerNoteGeneratorAPI.middleware,
            utilsApi.middleware,
            accountApi.middleware,
            policiesAPI.middleware
        )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
