import { InputField, RadioGroupField, SelectField, DatePickerField } from "@/components/Form";
import { DealFormType, FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC } from "react";
import { CASH_OUT_INVOLVED_OPTIONS, LENDER_UPDATED_OPTIONS, LOAN_PRICING_OPTIONS, LOAN_PURPOSE_OPTIONS, RATE_TYPE_OPTIONS, VALUATION_OPTIONS } from "@/devFrontData/optionList";

interface LoanDetailFormProps extends FormPropsType{
    watchFields: Partial<DealFormType>
}
const LoanDetailForm: FC<LoanDetailFormProps> = ({watchFields, control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <DatePickerField control={control} label="Date" name="date" error={errors.date?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Property Value" name="propertyValue" type="number" control={control} error={errors.propertyValue?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Loan Amount" name="loanAmount" type="number" control={control} error={errors.loanAmount?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Loan Term (Years)" name="loanTerm" type="number" control={control} error={errors.loanTerm?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Product" name="product" control={control} error={errors.product?.message ?? ""} />
            </Col>
            
            <Col xs={24} md={12}>
                <SelectField
                    name="lender"
                    control={control}
                    label="Lender"
                    placeholder="lender"
                    error={errors.lender?.message}
                    options={LENDER_UPDATED_OPTIONS}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Interest rate" type="number" name="interestRate" control={control} error={errors.interestRate?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="purpose"
                    control={control}
                    label="Purpose"
                    placeholder="Loan Purpose"
                    error={errors.purpose?.message}
                    options={LOAN_PURPOSE_OPTIONS}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="LVR" name="lvr" type="number" suffix="%" control={control} error={errors.lvr?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="valuation"
                    control={control}
                    label="Valuation"
                    placeholder="valuation"
                    error={errors.valuation?.message}
                    options={VALUATION_OPTIONS}
                />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="pricing"
                    control={control}
                    label="Pricing"
                    placeholder="Pricing"
                    error={errors.pricing?.message}
                    options={LOAN_PRICING_OPTIONS}
                />
            </Col>
            <Col xs={24} md={12}>
                    <RadioGroupField
                                name="offset"
                                control={control}
                                label="Offset"
                                error={errors.offset?.message}
                            
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            /> 
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Current Mortgage" name="currentMortgage_1" control={control} error={errors.currentMortgage_1?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Cash Out Amount" name="cashOutAmount" type="number" control={control} error={errors.cashOutAmount?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <RadioGroupField
                    name="anyLiabilities"
                    control={control}
                    label="Any Liabilities"
                    error={errors.anyLiabilities?.message}
                    options={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" }
                    ]}
                />
            </Col>

            {watchFields.anyLiabilities == "yes" &&  <Col xs={24} md={12}>
                <InputField label="Details of debt" name="detailsOfDebt" control={control} error={errors.detailsOfDebt?.message ?? ""} />
            </Col>}

            <Col xs={24} md={12}>
                <SelectField
                    name="rateType_1a"
                    control={control}
                    label="RateType 1a"
                    placeholder="RateType 1a"
                    error={errors.rateType_1a?.message}
                    options={RATE_TYPE_OPTIONS}
                />
            </Col>
            <Col xs={24} md={12}>
                    <SelectField
                        name="cashOutReason"
                        control={control}
                        label="Cash-Out Involved? (If Applicable)"
                        placeholder="Cash-Out Involved"
                        error={errors.cashOutReason?.message}
                        formDirection="row"
                        options={CASH_OUT_INVOLVED_OPTIONS}
                    />
            </Col>
        </Row>
    );
};

export default LoanDetailForm;
