import { FC, useEffect, useState } from "react";
import Scrollbars from "rc-scrollbars";
import { DownloadOutlined, FilePdfOutlined, KeyOutlined } from "@ant-design/icons";
import { Button, List, Checkbox, Flex, Space, message } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";

interface BankDocument {
    name: string;
    url: string;
    size: number;
    last_modified: string;
}

interface DocumentListInterface {
    documents: BankDocument[];
    onDocumentSelect: (selectedDocuments: string[]) => void;
    viewDocument: (doc: string) => void;
    selectedAll: (selectedAll: boolean) => void;
}

const DocumentList: FC<DocumentListInterface> = ({ documents, onDocumentSelect, viewDocument, selectedAll }) => {
    const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
    const [sortedFilteredDocs, setSortedFilteredDocs] = useState<BankDocument[]>([]);

    useEffect(() => {
        // [REMOVE IN FUTURE] Filter out documents containing 'Factsheet' in their name and sort by size in descending order
        // const filtered = documents.filter(doc => !doc.name.includes('Factsheet'));
        const filtered = documents;
        const sorted = filtered.sort((a, b) => b.size - a.size);
        setSortedFilteredDocs(sorted);

        if (sorted.length > 0 && selectedDocs.length === 0) {
            const allDocNames = sorted.map(doc => doc.name);
            setSelectedDocs(allDocNames);
        }
    }, [documents]);

    useEffect(() => {
        if (selectedDocs.length > 0) {
            onDocumentSelect(selectedDocs);
        }
        selectedAll(selectedDocs.length === sortedFilteredDocs.length); // Invoke selectedAll with true or false
    }, [selectedDocs, onDocumentSelect, selectedAll, sortedFilteredDocs.length]);

    const handleSelect = (name: string, isChecked: boolean) => {
        if (isChecked) {
            setSelectedDocs(prev => [...prev, name]);
        } else {
            if (selectedDocs.length > 1) {
                setSelectedDocs(prev => prev.filter(item => item !== name));
            } else {
                message.error("At least one document must be selected.");
                return; // Prevent unselecting the last document
            }
        }
    };

    const handleViewDocument = (doc: string) => {
        viewDocument(doc);
    };

    return (
        <div>
            <Space direction="horizontal" style={{ marginBottom: "10px" }}>
                <Checkbox
                    indeterminate={selectedDocs.length > 0 && selectedDocs.length < sortedFilteredDocs.length}
                    checked={selectedDocs.length === sortedFilteredDocs.length}
                    onChange={e => {
                        const { checked } = e.target;
                        if (checked) {
                            const allNames = sortedFilteredDocs.map(doc => doc.name);
                            setSelectedDocs(allNames);
                            onDocumentSelect(allNames);
                        } else {
                            const firstDoc = sortedFilteredDocs[0].name;
                            setSelectedDocs([firstDoc]);
                            onDocumentSelect([firstDoc]);
                        }
                    }}
                >
                    {selectedDocs.length === sortedFilteredDocs.length ? "Unselect All" : "Select All"}
                </Checkbox>
            </Space>

            <Scrollbars style={{ height: "calc(100vh - 200px)" }}>
                <List
                    itemLayout="vertical"
                    dataSource={sortedFilteredDocs}
                    renderItem={item => (
                        <List.Item>
                            <Flex align="flex-start" gap={10}>
                                <Checkbox checked={selectedDocs.includes(item.name)} onChange={e => handleSelect(item.name, e.target.checked)} />
                                <div style={{ paddingLeft: "5px" }}>
                                    <FilePdfOutlined style={{ fontSize: "20px", color: "#c41d7f" }} />
                                    <Title style={{ marginTop: "5px", fontSize: "13px", lineHeight: "18px" }}>{item.name}</Title>
                                    <Button
                                        type="link"
                                        onClick={() => handleViewDocument(item.url)}
                                        icon={<KeyOutlined color="secondary" />}
                                        color="secondary"
                                    />
                                    <Button type="link" href={item.url} target="_blank" icon={<DownloadOutlined />} />
                                </div>
                            </Flex>
                        </List.Item>
                    )}
                />
            </Scrollbars>
        </div>
    );
};

export default DocumentList;
