import { CSSProperties, FC } from "react";
import { theme, Typography } from "antd";

const {useToken} =theme;
const {Text} = Typography;
interface SubTitleProps {
    title: string;
    style?: CSSProperties;
}
const SubTitle:FC<SubTitleProps> = ({title, style}) => {
    const {token} = useToken();
    return (
        <Text style={{color:token.colorTextSecondary, ...style}}>{title}</Text>
    );
};

export default SubTitle;