import { Navigate, Route, Routes } from "react-router-dom";

import Deals from "./Deals";
import DealDetail from "./DealDetail";
import AsideContainer from "@/components/Layout/AsideContainer";
import DealSidebar from "../components/DealSidebar";
import DealCreate from "./DealCreate";

export const DealsRoutes = () => {
    return (
        <div style={{width:"100%", alignSelf:"baseline"}}>
            <AsideContainer sideContent={<DealSidebar />}>
                <Routes>
                    <Route path="/" element={<Deals />} />
                    <Route path="/create/:requestId?" element={<DealCreate />} />
                    <Route path=":requestId" element={<DealDetail />} />
                    <Route path="*" element={<Navigate to="." />} />
                </Routes>
            </AsideContainer>
        </div>
    );
};
