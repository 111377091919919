
import { FC, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { Button, Flex, Input } from "antd";

const { TextArea } = Input;

interface SystemPromptFormProps {
 prompt: string;
 getUpdatedPrompt?: (prompt: string) => void;
 isLoading?:boolean;
};

const SystemPromptForm:FC<SystemPromptFormProps> = ({prompt, getUpdatedPrompt, isLoading}) => {
    const {userData} = useSelector((state: RootState) => state.auth);  
    const [editedContent, setEditedContent] = useState<string>(prompt);

    useEffect(() => {
        setEditedContent(prompt);
    }, [prompt]); 

    const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditedContent(event.target.value);
    };

    const handlePromptSubmit = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(getUpdatedPrompt){
            getUpdatedPrompt(editedContent);
        }
    };
    return (
        <>
            <form onSubmit={handlePromptSubmit}>
                    <TextArea
                        value={editedContent}
                        onChange={handleContentChange}
                        rows={9}
                        readOnly={!userData?.isAdmin}
                    />
                    <Flex justify="flex-end">
                        {userData?.isAdmin && <Button type="primary" style={{marginTop:"1rem"}}  htmlType="submit" loading={isLoading} >
                                Update
                            </Button>}
                    </Flex>
            </form>
        </>
    );
};

export default SystemPromptForm;