import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Tooltip, Typography, Skeleton, Alert, Drawer } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

import Scrollbars from "rc-scrollbars";

//redux
import { useGetAllComplianceNoteQuery } from "@/store/apis/complianceNoteApi";
import { ComplianceAPIResponse } from "../types";
import { dateTimeTransformer } from "@/utils/common.utils";

import {
    CASH_OUT_INVOLVED_OPTIONS,
    DOCUMENT_IDENTIFICATION_OPTIONS,
    getOptionLabel,
    LOAN_PURPOSE_OPTIONS,
    RATE_TYPE_OPTIONS,
    REPAYMENT_OPTIONS,
    LENDER_UPDATED_OPTIONS
} from "@/devFrontData/optionList";
import { themeConfig } from "@/config";
import { COMPLIANCE_FIELD_LABELS } from "../constant/list";
import ComplianceDetailPanel from "./ComplianceDetailPanel";
const { Title, Text } = Typography;

interface ComplianceListInterface {
    compliance: ComplianceAPIResponse;
    viewItem: (compliance: ComplianceAPIResponse) => void;
    editItem: (compliance: ComplianceAPIResponse) => void;
}
const ComplianceListItem: FC<ComplianceListInterface> = ({ compliance, viewItem, editItem }) => {
    return (
        <div style={{ borderBottom: "1px solid #d9d9d9", padding:"12px 0" }}>
            <Title level={5} style={{ marginBottom: "2px", marginTop: "1rem" }}>
                {compliance.opportunity.name}
            </Title>
            <Text color="secondary" style={{ display: "inline-block", fontSize: "11px", lineHeight: "13px" }}>
                Last Generated {dateTimeTransformer(compliance.updated_at)}
            </Text>
            <Flex gap={5}>
                <Tooltip title="View">
                    <Button onClick={() => viewItem(compliance)} size="small" icon={<EyeOutlined />} />
                </Tooltip>
                <Tooltip title="Edit">
                    <Button size="small"  onClick={() => editItem(compliance)} icon={<EditOutlined />} />
                </Tooltip>
            </Flex>
        </div>
    );
};

const ComplianceSidebar = () => {
    const navigate = useNavigate();
    const {
        data: complianceApiResponse,
        isError: complianceApiError,
        isSuccess: complianceApiSuccess,
        isLoading: complianceApiLoading
    } = useGetAllComplianceNoteQuery({ latest: true });

    const [viewItem, setViewItem] = useState<boolean>(false);
    const [activeComplianceNote, setActiveComplianceNote] = useState<any>(null);

    const handleViewItem = (compliance: any) => {
        console.log("handleViewItem", compliance);

        setActiveComplianceNote((prevData: any) => {
            return {
                ...compliance,
                document_identification_method: getOptionLabel(compliance?.document_identification_method ?? "", DOCUMENT_IDENTIFICATION_OPTIONS),
                cash_out_involved: getOptionLabel(compliance?.cash_out_involved ?? "", CASH_OUT_INVOLVED_OPTIONS),
                rate_type: getOptionLabel(compliance?.rate_type ?? "", RATE_TYPE_OPTIONS),
                repayment_type: getOptionLabel(compliance?.rate_type ?? "", REPAYMENT_OPTIONS),
                loan_scenario_lender_1: getOptionLabel(compliance?.loan_scenario_lender_1 ?? "", LENDER_UPDATED_OPTIONS),
                loan_scenario_lender_2: getOptionLabel(compliance?.loan_scenario_lender_2 ?? "", LENDER_UPDATED_OPTIONS),
                loan_scenario_lender_3: getOptionLabel(compliance?.loan_scenario_lender_3 ?? "", LENDER_UPDATED_OPTIONS),
                lvr: compliance?.opportunity?.json_data?.lvr,
                purpose: getOptionLabel(compliance?.opportunity?.json_data?.purpose ?? "", LOAN_PURPOSE_OPTIONS),
                date: compliance?.opportunity?.json_data?.date,
                name: compliance?.opportunity?.name
            };
        });

        setViewItem(true);
    };

    const handleEditItem = (data:ComplianceAPIResponse) => {
        navigate(`/note-generation/compliance-notes/${data.opportunity.id}/${data.id}`);
    };

    const handleDrawerClose = () => {
        setViewItem(false);
        setActiveComplianceNote(null);
    };

    if (complianceApiError) {
        return <Alert message="Error fetching compliance notes" type="error" />;
    }

    return (
        <>
            <div style={{ padding: "0 10px" }}>
                <Title level={5} style={{ marginBottom: "0" }}>
                    Last 3 Notes
                </Title>
                <Scrollbars style={{ height: "calc(100vh - 100px)" }}>
                    {complianceApiResponse && (
                        <div style={{ marginBottom: "1rem" }}>
                            {complianceApiResponse?.data.length > 0 ? (complianceApiResponse?.data.map(complianceNote => {
                                return <ComplianceListItem key={complianceNote.id} compliance={complianceNote} viewItem={handleViewItem} editItem={handleEditItem}  />;
                            })) : (<Text>No notes to show...</Text>)}
                        </div>
                    )}
                    {complianceApiLoading && <Skeleton />}
                </Scrollbars>
                {viewItem && (
                    <Drawer title={activeComplianceNote?.name ?? ""} onClose={handleDrawerClose} open={viewItem} width={themeConfig.drawer.xxl}>
                        <ComplianceDetailPanel data={activeComplianceNote} tableMapper={COMPLIANCE_FIELD_LABELS} />
                    </Drawer>
                )}
            </div>
        </>
    );
};

export default ComplianceSidebar;
