// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { DealType } from "@/types";
import { ContactType, Deal } from "@/pages/deals/types";

import { ApiResponse } from "@/types";
import { DealPayload } from "@/pages/deals/types";



export const opportunityServiceApi = createApi({
    reducerPath: "opportunityApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        postOpportunityServiceHistory: builder.mutation<ApiResponse<any>, DealPayload>({
            query: body => ({
                url: "api/opportunity/",
                method: "POST",
                body
            }),
            transformResponse: (response: ApiResponse<any>): any => response
        }),
        updateDeal: builder.mutation<ApiResponse<any>, DealPayload>({
            query: body => ({
                url: `api/opportunity/${body.requestId}`,
                method: "PATCH",
                body
            }),
            transformResponse: (response: ApiResponse<any>): any => response
        }),
        getOpportunityServiceHistory: builder.query<Record<string, any>[], void>({
            query: () => "api/opportunity/",
            transformResponse: (response: ApiResponse<any>): any => {
                return response.data;
            }
        }),
        getAllOpportunityServiceHistory: builder.query<Record<string, any>[], void>({
            query: () => "api/opportunity/all/",
            transformResponse: (response: ApiResponse<any>): any => {
                return response.data;
            }
        }),
        getOpportunityDetail: builder.query<Deal, string>({
            query: requestId => `api/opportunity/${requestId}`,
            transformResponse: (response: ApiResponse<Deal>) => response.data
        }),
        deleteOpportunityService: builder.mutation<any, string>({
            query: (requestId) => ({
                url: `api/opportunity/${requestId}`,
                method: 'DELETE'
            })
        }),
        // contacts
        getAllDealContacts: builder.query<ContactType[], void>({
            query:() => "api/opportunity/contact/",
            transformResponse: (response: ApiResponse<any>): any => {
                return response.data;
            }
        }),
        postContact: builder.mutation<ApiResponse<any>, ContactType>({
            query: body => ({
                url: "api/opportunity/contact/",
                method: "POST",
                body
            })
        }),
        getOrCreateContact: builder.mutation<ApiResponse<ContactType>, ContactType>({
            query: body => ({
                url: "api/opportunity/contact/create-or-retrieve/",
                method: "POST",
                body
            })
        })
    })
});
export const { 
    usePostOpportunityServiceHistoryMutation, 
    useUpdateDealMutation, 
    useGetOpportunityServiceHistoryQuery, 
    useGetAllOpportunityServiceHistoryQuery, 
    useGetOpportunityDetailQuery,
    useDeleteOpportunityServiceMutation ,
    useGetAllDealContactsQuery,
    usePostContactMutation,
    useGetOrCreateContactMutation
} = opportunityServiceApi;
