
export const COMPLIANCE_CATEGORY = {
    needAnalysisInputs: ['document_identification_method', 'client_interview_method', 'date', 
        'estimated_settlement_date', 'facility_amount', 'lvr', 'cash_out_involved', 'purpose'],
    preferredLoanInputs: ['rate_type', 'repayment_type', 'repayment_frequency', 'offset', 'loan_structure', 
        'loan_scenario_lender_1', 'loan_scenario_lender_2', 'loan_scenario_lender_3'],
    generatedNotes:['loan_objective_note', 'loan_requirement_note', 'loan_circumstances_note', 'loan_financial_awareness_note', 'lender_loan_note', 'loan_structure_note', 'loan_prioritised_note']
}
export const COMPLIANCE_FIELD_LABELS = {
    'document_identification_method': 'Method of Document Identification',
    'client_interview_method': 'Method of Client Interview',
    'date': 'Date Credit Guide Was Provided (Within 90 days of assessment)',
    'estimated_settlement_date': 'Estimated Settlement Date',
    'facility_amount': 'Facility Amount',
    'lvr': 'LVR',
    'cash_out_involved': 'Cash-out Involved',
    'purpose': 'Purpose of Loan',
    'rate_type': 'Priority 1 (Rate Type)',
    'repayment_type': 'Priority 2 (Repayment Type)',
    'repayment_frequency': 'Priority 3 (Repayment Frequency)',
    'offset': 'Offset',
    'loan_structure': 'Loan Structure',
    'loan_scenario_lender_1': 'Lender 1 (Proceeding With)',
    'loan_scenario_lender_2': 'Lender 2',
    'loan_scenario_lender_3': 'Lender 3',
    'loan_objective_note': 'Loan Objectives',
    'loan_requirement_note': 'Loan Requirements',
    'loan_circumstances_note': 'Circumstances, Objectives and Priorities',
    'loan_financial_awareness_note': 'Financial Awareness & Practices',
    'lender_loan_note': 'Lender, Loan Amount & Interest Rate',
    'loan_structure_note': 'Loan Structure',
    'loan_prioritised_note': 'Prioritised Loan Features'
}

export const COMPLIANCE_TITLE_MAPPER = {
    "loan_objectives": "Loan Objectives",
    "loan_requirements": "Loan Requirements",
    "loan_circumstances": "Circumstances, Objectives and Priorities",
    "loan_financial_awareness": "Financial Awareness & Practices",
    "loan_prioritised":"Prioritised Loan Features",
    "lender_loan":" Lender, Loan Amount & Interest Rate",
    "loan_structure": "Loan Structure",
    "goals_objectives": "Goals Objectives"
}

export const BROKER_CATEGORY = {
    loanDetails: ['finance_due_date', 'settlement_date', 'loan_detail_address', 'loan_term', 'lvr', 'loan_purpose', 
        'lender', 'cash_out_reason', 'pricing', 'interest_rate', 'valuation', 'property_value', 'loan_amount', 'offset', 'product'],
    fundsComplete:["cash_out_amount", "loan_amount", "stamp_duty"],
    fundsAvailable:["bank_1", "bank_2", "bank_3", "cash_gift", "shares"],
    generatedNotes: ["loan_purpose_note", "applicant_overview_note", "living_condition_note", "employment_income_note", "commitments_note", "other_note", "mitigants_note"]
    
}

export const BROKER_FIELD_LABELS = {
    "finance_due_date": "Finance Due Date",
    "settlement_date": "Settlement Date",
    "loan_detail_address": "Address",
    "loan_term": "Loan Term",
    "lvr": "LVR",
    "loan_purpose": "Loan Purpose",
    "lender": "Lender",
    "cash_out_reason": "Cash-out Reason",
    "pricing": "Pricing",
    "interest_rate": "Interest Rate",
    "valuation": "Valuation",
    "property_value": "Property Value",
    "loan_amount": "Loan Amount",
    "offset": "Offset",
    "product": "Product",
    "cash_out_amount": "Less: Cash Out Amount",
    "stamp_duty": "Stamp Duty",
    "bank_1": "Bank 1",
    "bank_2": "Bank 2",
    "bank_3": "Bank 3",
    "cash_gift": "Cash Gift",
    "shares": "Shares",
    "loan_purpose_note": "Overview / Loan Purpose",
    "applicant_overview_note": "Applicant Overview",
    "living_condition_note": "Living Condition",
    "employment_income_note": "Employment Income",
    "commitments_note": "Commitments",
    "other_note": "Other",
    "mitigants_note": "Mitigants"
}

export const BROKER_TITLE_MAPPER = {
    "loan_purpose":"Overview / Loan Purpose",
    "applicant_overview":"Applicant Overview",
    "living_condition":"Living Conditions",
    "employment_income":"Employment / Income",
    "commitments":"Commitments",
    "others":"Other",
    "mitigants":"Mitigants"
}