import { FC, forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { Col, Row, theme } from "antd";
// components
import { NoteHeading } from "@/components/UI";
import PDFHeading from "./pdf/PDFHeading";
import ProcessorContactDetail from "./pdf/ProcessorContactDetail";
import ApplicantDetail, { ApplicantDetailsType } from "./pdf/ApplicantDetail";
import LoanDetail from "./pdf/LoanDetail";
import AIBrokerNote from "./pdf/AIBrokerNote";
import FundsDetail, { FundsDetailMethods } from "./pdf/FundsDetail";

import { BrokerNoteFieldTypes, BrokerNoteResponse } from "../../types";
import LoanDetailForm, { LoanDetailFormMethods, LoanDetailFormType } from "./LoanDetailForm";
import LoanDetailTable from "./LoanDetailTable";
import { FormFieldDataType } from "@/pages/deals/components/DealForm";
import { themeConfig } from "@/config";
const { useToken } = theme;

export interface BrokerNotePDFMethods {
    loanDetailFormMethods: LoanDetailFormMethods;
    fundsDetailFormMethods: FundsDetailMethods;
}
interface BrokerPDFProps {
    generateAIContent: (id: BrokerNoteFieldTypes) => void;

    allValues: Partial<FormFieldDataType> | null;

    loanPurposeData: string;
    loanPurposeDataLoading: boolean;

    applicationOverviewData: string;
    applicationOverviewDataLoading: boolean;

    livingCDData: string;
    livingCDDataLoading: boolean;

    empIncomeData: string;
    empIncomeDataLoading: boolean;

    commitData: string;
    commitDataLoading: boolean;

    otherData: string;
    otherDataLoading: boolean;

    mitiData: string;
    mitiDataLoading: boolean;

    brokerDetail: any;

    applicantDetails: ApplicantDetailsType | null;

    submitLoanDetailRef: RefObject<HTMLButtonElement>;
    handleLoanDetailForm: (data: LoanDetailFormType) => void;
    setLoanDetailFormData?: (data: LoanDetailFormType) => void;
    loanDetailFormValue: LoanDetailFormType | null,

    onAIContentUpdate: (id: BrokerNoteFieldTypes, updatedContent: string) => void;
    viewUserInputPrompt?: (id: BrokerNoteFieldTypes) => void;
    brokerNoteAPIDetail: BrokerNoteResponse | null;
    viewSystemPrompt: (id: BrokerNoteFieldTypes) => void;
}
const BrokerPDF = forwardRef<BrokerNotePDFMethods, BrokerPDFProps>(
    (
        {
            allValues,
            loanPurposeData,
            loanPurposeDataLoading,
            applicationOverviewData,
            applicationOverviewDataLoading,
            livingCDData,
            livingCDDataLoading,
            empIncomeData,
            empIncomeDataLoading,
            commitData,
            commitDataLoading,
            otherData,
            otherDataLoading,
            mitiData,
            mitiDataLoading,
            brokerDetail,
            applicantDetails,

            generateAIContent,
            submitLoanDetailRef,
            handleLoanDetailForm,
            setLoanDetailFormData,
            onAIContentUpdate,
            loanDetailFormValue,
            viewUserInputPrompt,
            brokerNoteAPIDetail,
            viewSystemPrompt
        },
        ref
    ) => {
        const loanDetailFormRef = useRef<LoanDetailFormMethods>(null);
        const fundsDetailFormRef = useRef<FundsDetailMethods>(null);

        useImperativeHandle(ref, () => ({
            loanDetailFormMethods: {
                triggerValidation: () =>
                    loanDetailFormRef.current ? loanDetailFormRef.current.triggerValidation() : Promise.resolve({ isValid: false, data: undefined })
            },
            fundsDetailFormMethods: {
                triggerValidation: () =>
                    fundsDetailFormRef.current ? fundsDetailFormRef.current.triggerValidation() : Promise.resolve({ isValid: false, data: undefined })
            },
        }));

        return (
            <div style={{overflow: "hidden"}}>
                <PDFHeading allValues={allValues} />
                <ProcessorContactDetail allValues={allValues} brokerDetail={brokerDetail} />
                <ApplicantDetail allValues={allValues} applicantDetails={applicantDetails} />
                <LoanDetailForm
                    ref={loanDetailFormRef}
                    handleFormSubmit={handleLoanDetailForm}
                    submitRef={submitLoanDetailRef}
                    setFormData={setLoanDetailFormData}
                    dealsData={allValues}
                    brokerNoteAPIDetail={brokerNoteAPIDetail}
                />
               

                <div>
                    <NoteHeading title="Broker Notes" style={{ marginBottom: "1rem" }} />
                    <Row gutter={themeConfig.rowGutterMd}>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Overview / Loan Purpose"
                                loading={loanPurposeDataLoading}
                                aiData={loanPurposeData}
                                onAIContentUpdate={onAIContentUpdate}
                                viewUserInputPrompt={viewUserInputPrompt}
                                viewSystemPrompt={viewSystemPrompt}
                                id="loan_purpose"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Applicant Overview"
                                loading={applicationOverviewDataLoading}
                                aiData={applicationOverviewData}
                                onAIContentUpdate={onAIContentUpdate}
                                viewUserInputPrompt={viewUserInputPrompt}
                                viewSystemPrompt={viewSystemPrompt}
                                id="applicant_overview"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Living Conditions"
                                loading={livingCDDataLoading}
                                aiData={livingCDData}
                                onAIContentUpdate={onAIContentUpdate}
                                viewUserInputPrompt={viewUserInputPrompt}
                                viewSystemPrompt={viewSystemPrompt}
                                id="living_condition"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Employment / Income"
                                loading={empIncomeDataLoading}
                                aiData={empIncomeData}
                                onAIContentUpdate={onAIContentUpdate}
                                viewUserInputPrompt={viewUserInputPrompt}
                                viewSystemPrompt={viewSystemPrompt}
                                id="employment_income"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Commitments"
                                loading={commitDataLoading}
                                aiData={commitData}
                                id="commitments"
                                onAIContentUpdate={onAIContentUpdate}
                                viewSystemPrompt={viewSystemPrompt}
                                viewUserInputPrompt={viewUserInputPrompt}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <FundsDetail loanDetailFormValue={loanDetailFormValue} allValues={allValues} ref={fundsDetailFormRef} brokerNoteAPIDetail={brokerNoteAPIDetail} />
                        </Col>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Other"
                                loading={otherDataLoading}
                                aiData={otherData}
                                id="others"
                                onAIContentUpdate={onAIContentUpdate}
                                viewUserInputPrompt={viewUserInputPrompt}
                                viewSystemPrompt={viewSystemPrompt}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AIBrokerNote
                                generateAIContent={generateAIContent}
                                title="Mitigants"
                                loading={mitiDataLoading}
                                aiData={mitiData}
                                id="mitigants"
                                onAIContentUpdate={onAIContentUpdate}
                                viewSystemPrompt={viewSystemPrompt}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
);

export default BrokerPDF;
