import { useMsal, useAccount } from "@azure/msal-react";
import {  Dropdown,  Avatar, Drawer } from "antd";
import {  EditOutlined, LogoutOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";
import { storageSession } from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { clearAuthToken } from "@/store/slices/auth/authSlice";
import UpdateProfile from "@/pages/account/components/UpdateProfile";
import { displaySuccess } from "@/utils/common.utils";

const iconStyle = { fontSize: "16px" };
const items: MenuProps["items"] = [
    // {
    //     key: "1",
    //     icon: <ContainerOutlined style={iconStyle} />,
    //     label: "My Plan"
    // },
    // {
    //     key: "2",
    //     label: "Settings",
    //     icon: <SettingOutlined style={iconStyle} />
    // },
    {
        key: "3",
        label: "Update Profile",
        icon: <EditOutlined style={iconStyle} />
    },
    {
        type: "divider"
    },
    {
        key: "4",
        icon: <LogoutOutlined style={iconStyle} />,
        label: "Log Out"
    }
];

const ProfileMenu = () => {
    const authState = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const { accounts, instance } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [openDrawer, setOpenDrawer] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (authState && authState.userData?.name) {
            const nameParts = authState.userData?.name.split(" ");
            setName(nameParts[0][0] + nameParts[nameParts.length - 1][0]);
        } else {
            setName("");
        }
    }, [authState]);

    const handleMenuClick: MenuProps["onClick"] = e => {
        if (e.key === "3") {
            setOpenDrawer(true);
        }
        if (e.key === "4") {
            dispatch(clearAuthToken());
            navigate("/auth");
        }
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const getUpdateProfileMsg = (msg:string) => {
        setOpenDrawer(false);
        displaySuccess(msg);
    };
    return (
        <>
            <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]} overlayStyle={{ width: "300px" }} overlayClassName="test">
                <a onClick={e => e.preventDefault()}>
                    <Avatar style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }} gap={4}>
                        {name}
                    </Avatar>
                </a>
            </Dropdown>
            <Drawer title="Update Profile" onClose={handleCloseDrawer} open={openDrawer} size="large">
                <UpdateProfile getUpdateProfileMsg={getUpdateProfileMsg} />
             </Drawer>
        </>
    );
};

export default ProfileMenu;
