import { InputField, RadioGroupField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC } from "react";
import SelectField from "@/components/Form/SelectField";
import DatePickerField from "@/components/Form/DatePickerField";
import { SALARY_FREQUENCY } from "@/devFrontData/optionList";

const OccupationForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <InputField name="occupation" control={control} label="Occupation" error={errors.occupation?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField name="employment" control={control} label="Employment" error={errors.employment?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField name="employer" control={control} label="Employer" error={errors.employer?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField name="since" control={control} label="Start Date" error={errors.since?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="currency"
                    control={control}
                    label="Currency"
                    placeholder="Currency"
                    error={errors.currency?.message}
                    options={[
                        { label: "AUD", value: "aud" },
                        { label: "USD", value: "usd" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField variant="filled" name="fx" control={control} label="FX" error={errors.fx?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="salary" control={control} label="Salary" error={errors.salary?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="salaryFreq"
                    control={control}
                    label="Salary Frequency"
                    placeholder="Salary Frequency"
                    error={errors.salaryFreq?.message}
                    options={SALARY_FREQUENCY}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="calMonthSalary" variant="filled" readonly={true} control={control} label="Base Salary" error={errors.calMonthSalary?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="salaryDeduction" control={control} label="Deduction" error={errors.salaryDeduction?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="allowances" control={control} label="Allowances" error={errors.allowances?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="allowanceFreq"
                    control={control}
                    label="Allowance Frequency"
                    placeholder="Allowance Frequency"
                    error={errors.allowanceFreq?.message}
                    options={SALARY_FREQUENCY}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="calcAllowance" variant="filled" readonly={true} control={control} label="Calc Allowance" error={errors.calcAllowance?.message} />
            </Col>


            <Col xs={24} md={12}>
                <InputField prefix="$" type="number" name="commission" control={control} label="Commission" error={errors.commission?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="commFreq"
                    control={control}
                    label="Commission Frequency"
                    placeholder="Commission Frequency"
                    error={errors.commFreq?.message}
                    options={SALARY_FREQUENCY}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="calcCommission" variant="filled" readonly={true} control={control} label="Calc Commission" error={errors.calcCommission?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="bonusThis" control={control} type="number" label="Bonus this year" error={errors.bonusThis?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="bonusLast" control={control} type="number" label="Bonus last year" error={errors.bonusLast?.message} />
            </Col>
            
            <Col xs={24} md={12}>
                <InputField name="maxPurchasePrice" control={control} type="number" label="Max Purchase Price" error={errors.maxPurchasePrice?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField suffix="%" name="aipRentalyiel" variant="filled" readonly={true} control={control} label="AIP Rental Yield" error={errors.aipRentalyiel?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="apiRent" variant="filled" readonly={true} control={control} label="AIP rent/m" error={errors.apiRent?.message} />
            </Col>
        </Row>
    );
};

export default OccupationForm;
