import { Navigate, Route, Routes } from "react-router-dom";
import Policies from "./Policies";

export const PoliciesRoutes = () => {
    return(
        <div style={{width:"100%", alignSelf:"baseline"}}>
            <Routes>
                <Route path="/" element={<Policies />} />
                <Route path="*" element={<Navigate to="." />} />
            </Routes>
        </div>
    )
}
