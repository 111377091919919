// src/store/apis/utilsAPi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from "..";

export const utilsApi = createApi({
  reducerPath: 'utilsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token; // Access token from auth slice
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    }
}),
  endpoints: (builder) => ({
    getForexExchangeData: builder.query({
      query: ({ requestCurrency, requestDate }) => ({
        url: '/convert',
        params: {
          from: 'AUD',
          to: requestCurrency,
          date: requestDate,
          amount: 1,
        },
      }),
      transformResponse: (response: any) => response.result,
    }),
  }),
});

export const { useGetForexExchangeDataQuery } = utilsApi;