import { theme } from "antd";
import COPILOT from "@/assets/copilot.png";
const {useToken} = theme;
const CopilotIcon = () => {
    const {token} = useToken();
    return (
        <div style={{
            width:"32px",
            height:"32px",
            border: `1px solid #ececec`,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius:"100%",
            paddingLeft:"5px"
        }}>
            <img src={COPILOT} alt="" style={{ height: "17px",  }} />
        </div>
    );
};

export default CopilotIcon;