import { FC, useEffect, useRef, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
interface ReactPDFViewerProps {
    fileUrl: string;
    initialPageNumber: number;
    height?: any;
}
const ReactPDFViewer: React.FC<ReactPDFViewerProps> = ({ fileUrl, initialPageNumber, height= "50vh"}) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);
    const viewerRef = useRef<HTMLDivElement>(null);
    const thumbnailsRef = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
      const delayScroll = setTimeout(() => {
        if (numPages && thumbnailsRef.current[initialPageNumber - 1]) {
          thumbnailsRef.current[initialPageNumber - 1]!.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);  

      return () => clearTimeout(delayScroll);
    }, [numPages, initialPageNumber]);  

    // useEffect(() => {
    //     const handleScroll = () => {
    //       const container = viewerRef.current;
    //       if (container) {
    //         const { scrollTop, offsetHeight } = container;
    //         const currentPage = Math.round(scrollTop / offsetHeight) + 1;
    //         setPageNumber(currentPage <= numPages ? currentPage as number : numPages as number);
    //       }
    //     };
  
    //     const viewer = viewerRef.current;
    //     viewer?.addEventListener('scroll', handleScroll);
  
    //     return () => viewer?.removeEventListener('scroll', handleScroll);
    //   }, [numPages]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
        thumbnailsRef.current = Array(numPages).fill(null); 
    }

    return (
        <div style={{ display: "flex", height, position:"relative", paddingTop:"1rem" }}>
            <div style={{ overflowY: "auto", width: "100%",  }}>
                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                 
                        {Array.from(new Array(numPages), (el, index) => (
                            <div
                                ref={el => (thumbnailsRef.current[index] = el)}
                                key={`thumb_${index + 1}`}
                                onClick={() => setPageNumber(index + 1)}
                                style={{ cursor: "pointer", padding: "10px" }}
                            >
                                <Page pageNumber={index + 1}  />
                            </div>
                        ))}
                    
                     
                </Document>
                {/* <div style={{ textAlign: "center",  position:"absolute", right:0, top:0 }}>
                             Page {pageNumber} of {numPages}
                     </div> */}
            </div>
            {/* <div style={{ flexGrow: 1, padding: "10px", overflowY: "auto" }}>
                <Document file={fileUrl}>
                    <Page  pageNumber={pageNumber} width={document.documentElement.clientWidth * 0.75}>
                    
                    </Page>
                </Document>
                <div style={{ textAlign: "center", padding: "10px" }}>
                    Page {pageNumber} of {numPages || 0}
                </div>
            </div> */}
        </div>
    );
};

export default ReactPDFViewer;
