import { FC, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DealFormType } from "@/pages/deals/types";
import { InputField, SelectField } from "@/components/Form";
import { CASH_OUT_INVOLVED_OPTIONS, LOAN_PURPOSE_OPTIONS } from "@/devFrontData/optionList";
import { UserPromptFormTypes } from "@/pages/noteGeneration/types";
import { FormFieldDataType } from "@/pages/deals/components/DealForm";

const schema = yup.object().shape({
    purpose: yup.string(),
    cashOutReason: yup.string(),
    lvr: yup.number()
});

type formType = yup.InferType<typeof schema>;

interface ComplianceLoanObjectiveFormProps {
    isLoading: boolean;
    formId: UserPromptFormTypes;
    formData?:  Partial<FormFieldDataType> | null;
    handleFormSubmit: ({id,data}:{id:UserPromptFormTypes, data:Partial<DealFormType>}) => void;
};

const ComplianceLoanObjectiveForm: FC<ComplianceLoanObjectiveFormProps> = ({formId, formData, handleFormSubmit, isLoading }) => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            purpose: "",
            cashOutReason: "",
            lvr: 0
        }
    });

    useEffect(() => {
        if (formData) {
            setValue("cashOutReason", formData?.cashOutReason ?? "");
            setValue("lvr", formData?.lvr ?? 0);
            setValue("purpose", formData?.purpose ?? "");
        }
    }, [formData]);

    const onSubmit: SubmitHandler<formType> = data => {
        handleFormSubmit({id: formId, data});
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xl={24}>
                    <SelectField
                        name="purpose"
                        control={control}
                        label="Purpose"
                        placeholder="Loan Purpose"
                        error={errors.purpose?.message}
                        options={LOAN_PURPOSE_OPTIONS}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <SelectField
                        name="cashOutReason"
                        control={control}
                        label="Cash-Out Involved? (If Applicable)"
                        placeholder="Cash-Out Involved"
                        error={errors.cashOutReason?.message}
                        formDirection="row"
                        options={CASH_OUT_INVOLVED_OPTIONS}
                    />
                </Col>
                <Col xl={24}>
                    <InputField formDirection="row" label="LVR" name="lvr" type="number" suffix="%" control={control} error={errors.lvr?.message ?? ""} />
                </Col>
                <Col xl={24}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Update
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default ComplianceLoanObjectiveForm;
