import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Breadcrumb, Button, Col, Drawer, Flex, Input, Row, Space, Tooltip } from "antd";
import Scrollbars from "rc-scrollbars";
//hooks
import { useUpdateDeal } from "@/hooks/useUpdateDeal";

//components
import { Container } from "@/components/Layout";
import AsideContainer from "@/components/Layout/AsideContainer";
import ComplianceSidebar from "../../components/ComplianceSidebar";
import HeaderRow from "../../components/HeaderRow";
import { BackButton, CountTitle, PageTitle } from "@/components/UI";
import DOMPurify from "dompurify";
//redux
import { useGetOpportunityDetailQuery } from "@/store/apis/opportunityApi";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useGenerateComplianceNoteMutation, useGetComplianceNoteDetailQuery, useGetComplianceSystemPromptQuery, usePostComplianceNoteMutation, useUpdateComplianceNoteMutation, useUpdateComplianceSystemPromptMutation } from "@/store/apis/complianceNoteApi";

//types
import { BrokerContactType, ContactType, DealFormType, DealInfoType } from "@/pages/deals/types";
import DealForm, { DealFormMethods, FormFieldDataType } from "@/pages/deals/components/DealForm";
import ComplianceNotePDF, { ComplianceNotePDFMethods } from "../../components/ComplianceNotePdf";
import {  ComplianceAPIResponse, ComplianceFieldTypes, ComplianceFormType, CompliancePayload, SystemPromptType } from "../../types";
import { NeedAnalysisFormType } from "../../components/ComplianceNotePdf/NeedAnalysisForm";
import { PreferredLoanFormType } from "../../components/ComplianceNotePdf/PreferredLoanForm";

import { displayError, displaySuccess, formatTitle } from "@/utils/common.utils";
import { complianceBreadcrumbList } from "../../constant";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import DealDetailPanel from "@/pages/deals/components/DetailListPanel";
import { themeConfig } from "@/config";
import { FormTrigger } from "@/types";
import { dealDataMapper } from "../../utils/dealDataMapper";
import { getComplianceUserContent } from "../../utils";
import SystemPromptForm from "../../components/SystemPromptForm";
import { COMPLIANCE_TITLE_MAPPER } from "../../constant/list";
import CompliancePromptsEditor from "../../components/ComplianceNote/CompliancePromptsEditor";
import UserPromptForm from "../../components/UserPromptForm";

const ComplianceGenerator = () => {
    const { requestId, complianceID } = useParams<{ requestId: string, complianceID?:string }>();
  
    const [viewSystemPrompt, setViewSystemPrompt] = useState(false);
    const [selectedSystemPrompt, setSelectedSystemPrompt] = useState<SystemPromptType<ComplianceFieldTypes> | null>(null);
    

    const [pageType, setPageType] = useState<"create" | "update">("create");
    const [complianceGeneratedData, setComplianceGeneratedData] = useState<ComplianceAPIResponse | null>(null);

    const [viewUserPrompt, setViewUserPrompt] = useState(false);
    const [activeUserPrompt, setActiveUserPromt] = useState<string>("");
    const [activePromptId, setActivePromptId] = useState<ComplianceFieldTypes|null>(null);

    const [viewDealDetail, setViewDealDetail] = useState(false);
    const [openDealFrom, setOpenDealFrom] = useState(false);
    const complianceNotePDFRef = useRef<ComplianceNotePDFMethods>(null);
    const [dealDataMapped, setDealDataMapped] = useState<Partial<DealFormType>>({});

    const dealsFormData = useSelector((state: RootState) => state.complianceForm);
    const [dealFieldData, setDealFieldData] = useState<DealInfoType | null>(null);
    const [brokerContactData, setBrokerContactData] = useState<BrokerContactType | null>(null);
    const [contactData, setContactData] = useState<ContactType | null>(null);
    const [secondaryContactData, setSecondaryContactData] = useState<ContactType | null>(null);

    const dealFormRef = useRef<DealFormMethods>(null);
    const submitRef = useRef<HTMLButtonElement>(null);
    const submitPreferredLoanFormRef = useRef<HTMLButtonElement>(null);
    const submitNeedAnalysisRef = useRef<HTMLButtonElement>(null);

    const [formFieldsData, setFormFieldsData] = useState<FormFieldDataType | null>(null);

    const [generateType, setGenerateType] = useState<"single" | "all">("single");
    const [activeId, setActiveId] = useState<ComplianceFieldTypes | null>(null);
    const [needAnalysisFormData, setNeedAnalysisFormData] = useState<NeedAnalysisFormType | null>(null);
    const [preferredFormData, setPreferredFormData] = useState<PreferredLoanFormType | null>(null);

    const [loanObjectiveData, setLoanObjectiveData] = useState("");
    const [loanRequirementsData, setLoanRequirementsData] = useState("");
    const [loanCircumstancesData, setLoanCircumstancesData] = useState("");
    const [loanFinancialAwarenessData, setLoanFinancialAwarenessData] = useState("");
    const [loanPrioritisedData, setLoanPrioritisedData] = useState("");
    const [lenderLoanData, setLenderLoanData] = useState("");
    const [loanStructureData, setLoanStructureData] = useState("");

    /** 
     * fetch all system prompts
     */

    const {data: complianceSystemPrompts, isLoading:compliancePromptsLoading, refetch: refetchCompliancePrompt} = useGetComplianceSystemPromptQuery(undefined, {refetchOnMountOrArgChange: true});

    /**
     * fetch deals detail
     */
    const {
        data: dealsData,
        error: dealsDataError,
        isLoading: dealsDataLoading,
        refetch: refetchDealsData
    } = useGetOpportunityDetailQuery(requestId!, { skip: !requestId, refetchOnMountOrArgChange: true });

    /**
     * Update deals detail
     */
    const { handleDealUpdate, isLoading, isSuccess } = useUpdateDeal();

    useEffect(() => {
        if (isSuccess) {
            refetchDealsData();
            setOpenDealFrom(false);
        }
    }, [isSuccess, refetchDealsData]);

    const [postComplianceNote, { isLoading: postComplianceLoading }] = usePostComplianceNoteMutation();
    const [updateComplianceNote, {isLoading: updateCompliaceLoading}] = useUpdateComplianceNoteMutation();
    const {data: complianceDetailData,
        error: complianceDetailErrors,
        isLoading: complianceDetailLoading,
        refetch: refetchComplianceDetail
    } = useGetComplianceNoteDetailQuery(complianceID!, {skip: !complianceID, refetchOnMountOrArgChange: true })

    const [fetchLoanObjectives, { isLoading: loanObjectivesLoading, isError: loanObjectivesError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanRequirements, { isLoading: loanRequirementsLoading, isError: loanRequirementsError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanCircumstance, { isLoading: loanCircumstancesLoading, isError: loanCircumstancesError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanFinancialAwareness, { isLoading: loanFinancialAwarenessLoading, isError: loanFinancialAwarenessError }] =
        useGenerateComplianceNoteMutation();
    const [fetchLoanPrioritised, { isLoading: loanPrioritisedLoading, isError: loanPrioritisedError }] = useGenerateComplianceNoteMutation();
    const [fetchLenderLoan, { isLoading: lenderLoanLoading, isError: lenderLoanError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanStructure, { isLoading: loanStructureLoading, isError: loanStructureError }] = useGenerateComplianceNoteMutation();

    useEffect(() => {
        if (complianceID) {
          setPageType("update");
        } else {
          setPageType("create");
        }
      }, [complianceID]);

    useEffect(() => {
        if (complianceDetailData && !complianceDetailLoading && complianceID) {
            const {data} = complianceDetailData
            setLoanObjectiveData(data.loan_objective_note ?? "")
            setLoanRequirementsData(data.loan_requirement_note ?? "")
            setLoanCircumstancesData(data.loan_circumstances_note ?? "")
            setLoanFinancialAwarenessData(data.loan_financial_awareness_note ?? "")
            setLoanPrioritisedData(data.loan_prioritised_note ?? "")
            setLenderLoanData(data.lender_loan_note ?? "")
            setLoanStructureData(data.loan_structure_note ?? "")
            setComplianceGeneratedData(data)
        }
    },[complianceID, complianceDetailData, complianceDetailLoading])
      
    /**
     * Watches for changes in the data fetched from the API request for deals.
     * When the deals data is available, not loading, and a request ID is present:
     * - Resets form fields and related state.
     * - Updates deal-related state with data from `dealsData`.
     * - If primary or secondary contact data is present, updates contact state accordingly.
     * - If additional JSON data exists and has more than two keys, updates form fields data.
     */
    useEffect(() => {
        if (dealsData && !dealsDataLoading && requestId) {
            setBrokerContactData(null);
            setContactData(null);
            setSecondaryContactData(null);
            setFormFieldsData(null);

            if (dealFormRef.current) {
                dealFormRef.current.resetForm();
            }
            setDealFieldData({
                dealName: dealsData?.name,
                dealType: dealsData?.type
            });

            setBrokerContactData({
                primaryContact: dealsData?.primary_processor?.email ?? "",
                secondaryContact: dealsData?.secondary_processor?.email ?? ""
            });

            let initialContactData;
            if (dealsData.primary_contact) {
                initialContactData= {
                    name: dealsData?.primary_contact.name,
                    email: dealsData?.primary_contact.email,
                    phone: dealsData?.primary_contact.phone,
                    residency: dealsData?.primary_contact.residency,
                    citizenship: dealsData?.primary_contact.citizenship,
                    
                }
                setContactData(initialContactData);
            }

            let initialSecondaryContactData;
            if (dealsData.secondary_contact) {
                initialSecondaryContactData = {
                    name: dealsData?.secondary_contact.name,
                    email: dealsData?.secondary_contact.email,
                    phone: dealsData?.secondary_contact.phone,
                    residency: dealsData?.secondary_contact.residency,
                    citizenship: dealsData?.secondary_contact.citizenship,
                }
                setSecondaryContactData(initialSecondaryContactData);
            }

            if (dealsData?.json_data && Object.keys(dealsData?.json_data).length > 2) {
                setFormFieldsData(dealsData?.json_data as FormFieldDataType);
            }
            const dealsMappedData = dealDataMapper({
                dealData:dealsData?.json_data as FormFieldDataType, 
                contactData:initialContactData, 
                secondaryContactData:initialSecondaryContactData 
            });

            setDealDataMapped(dealsMappedData)
        }
    }, [dealsData, dealsDataLoading, requestId]);

    /**
     * Triggers DealsForm button to submit the form
     */
    const handleSaveDeal = () => {
        if (submitRef.current) {
            submitRef.current.click();
        }
    };

    /**
     * Handles the submission of the Deal Form.
     * @param {Object} params - The parameters for the function.
     * @param {DealFormType} params.formData - The data from the Deal Form.
     */
    const handleFormSubmit = ({ formData }: { formData: DealFormType }) => {
        handleDealUpdate({ formData, requestId: requestId ?? "" });
    };

    const generateLoanObjective = ({ updatedData }: { updatedData: NeedAnalysisFormType }) => {
        fetchLoanObjectives({ type: "loan_objectives", data: updatedData })
            .unwrap()
            .then(response => {
                setLoanObjectiveData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const generateLoanRequirement = ({ updatedData }: { updatedData: NeedAnalysisFormType }) => {
        fetchLoanRequirements({ type: "loan_requirements", data: { ...dealDataMapped, ...updatedData } as ComplianceFormType })
            .unwrap()
            .then(response => {
                setLoanRequirementsData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const generateLoanCircumstances = ({ updatedData }: { updatedData: NeedAnalysisFormType }) => {
        fetchLoanCircumstance({ type: "loan_circumstances", data:  {...dealDataMapped, ...updatedData}   as ComplianceFormType  } )
            .unwrap()
            .then(response => {
                setLoanCircumstancesData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const generateLoanFinancialAwareness = () => {
        fetchLoanFinancialAwareness({ type: "loan_financial_awareness", data: dealDataMapped  as ComplianceFormType  })
            .unwrap()
            .then(response => {
                setLoanFinancialAwarenessData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const generateLoanPrioritised = ({ updatedData }: { updatedData: PreferredLoanFormType }) => {
        fetchLoanPrioritised({ type: "loan_prioritised", data: { ...dealDataMapped, ...updatedData }  as ComplianceFormType })
            .unwrap()
            .then(response => {
                setLoanPrioritisedData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const generateLenderLoan = () => {
        fetchLenderLoan({ type: "lender_loan", data: dealDataMapped  as ComplianceFormType })
            .unwrap()
            .then(response => {
                setLenderLoanData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const generateLoanStructure = ({ updatedData }: { updatedData: PreferredLoanFormType }) => {
     
        fetchLoanStructure({ type: "loan_structure", data: { ...dealDataMapped, ...updatedData }  as ComplianceFormType  })
            .unwrap()
            .then(response => {
                setLoanStructureData(DOMPurify.sanitize(response.choices[0].message.content));
            });
    };

    const triggerNeedAnalysisForm = async (): Promise<FormTrigger<NeedAnalysisFormType>> => {
        if (complianceNotePDFRef.current) {
            try {
                const needAnalysisFormDetail = await complianceNotePDFRef.current.needAnalysisFormMethods.triggerValidation();
                if (needAnalysisFormDetail.isValid) {
                    setNeedAnalysisFormData(needAnalysisFormDetail?.data as NeedAnalysisFormType);
                }
                return needAnalysisFormDetail;
            } catch (error) {
                return { isValid: false, data: undefined };
            }
        } else {
            return { isValid: false, data: undefined };
        }
    };

    const triggerPreferredLoanForm = async (): Promise<FormTrigger<PreferredLoanFormType>> => {
        if (complianceNotePDFRef.current) {
            try {
                const preferredLoanFormDetail = await complianceNotePDFRef.current.preferredLoanFormMethods.triggerValidation();
                

                if (preferredLoanFormDetail.isValid) {
                    setPreferredFormData(preferredLoanFormDetail?.data as PreferredLoanFormType);
                }
                return preferredLoanFormDetail;
            } catch (error) {
                return { isValid: false, data: undefined };
            }
        } else {
            return { isValid: false, data: undefined };
        }
    }; 

    const handleGenerateAIContent = async (id: ComplianceFieldTypes) => {
        setGenerateType("single");
        setActiveId(id);
        // dealFormRef.current?.getAllUpdatedValues();
        // /**
        //  * Trigger validation in sharedForm component and get validation results
        //  *
        //  * @returns {boolean} isValid - `true` if ther form is valid, `false` otherwise
        //  */
        // if (dealFormRef.current) {
        //     isValid = await dealFormRef.current.triggerValidation();
        // }
        switch (id) {
            case "loan_objectives":
                const { isValid: needAnalysisFormValid1, data: needAnalysisFormData1 } = await triggerNeedAnalysisForm();
                if (needAnalysisFormValid1) {
                    generateLoanObjective({ updatedData: needAnalysisFormData1 as NeedAnalysisFormType });
                }
                break;
            case "loan_requirements":
                const { isValid: needAnalysisFormValid2, data: needAnalysisFormData2 } = await triggerNeedAnalysisForm();
                if (needAnalysisFormValid2) {
                    generateLoanRequirement({ updatedData: needAnalysisFormData2 as NeedAnalysisFormType });
                }
                break;
            case "loan_circumstances":
                const { isValid: needAnalysisFormValid3, data: needAnalysisFormData3 } = await triggerNeedAnalysisForm();
                if (needAnalysisFormValid3) {
                    generateLoanCircumstances({ updatedData: needAnalysisFormData3 as NeedAnalysisFormType });
                }
                break;
            case "loan_financial_awareness":
                generateLoanFinancialAwareness();
                break;
            case "loan_prioritised":
                const { isValid: preferredLoanFormValid1, data: preferredLoanFormData1 } = await triggerPreferredLoanForm();
                if (preferredLoanFormValid1) {
                    generateLoanPrioritised({ updatedData: preferredLoanFormData1 as PreferredLoanFormType });
                }
                break;
            case "lender_loan":
                generateLenderLoan();
                break;
            case "loan_structure":
                const { isValid: preferredLoanFormValid2, data: preferredLoanFormData2 } = await triggerPreferredLoanForm();
                if (preferredLoanFormValid2) {
                    generateLoanStructure({ updatedData: preferredLoanFormData2 as PreferredLoanFormType });
                }
                break;
            default:
                console.log("Invalid field type");
                break;
        }
    };

    const handleNeedAnalysisForm = (data: NeedAnalysisFormType) => {
        setNeedAnalysisFormData(data);
    };

    const updateNeedAnalysisFormData = (data: NeedAnalysisFormType) => {
        setNeedAnalysisFormData(prevData => ({ ...prevData, ...data }));
    };

    const handlePreferredLoanForm = (data: PreferredLoanFormType) => {
        setPreferredFormData(data);
    };



    const handleGenerateAllNotes = async () => {
        setGenerateType("all");
        const { isValid: needAnalysisFormValid, data: needAnalysisFormData } = await triggerNeedAnalysisForm();
        if (needAnalysisFormValid) {
            generateLoanObjective({ updatedData: needAnalysisFormData as NeedAnalysisFormType });
            generateLoanRequirement({ updatedData: needAnalysisFormData as NeedAnalysisFormType });
            generateLoanCircumstances({ updatedData: needAnalysisFormData as NeedAnalysisFormType });
        }
        generateLoanFinancialAwareness();
        generateLenderLoan();
        const { isValid: preferredLoanFormValid, data: preferredLoanFormData } = await triggerPreferredLoanForm();
        if (preferredLoanFormValid) {
            generateLoanPrioritised({ updatedData: preferredLoanFormData as PreferredLoanFormType });
            generateLoanStructure({ updatedData: preferredLoanFormData as PreferredLoanFormType });
        }
    };

    const onAIContentUpdate = (id: ComplianceFieldTypes, updatedContent: string) => {
        switch (id) {
            case "loan_objectives":
                setLoanObjectiveData(updatedContent);
                break;
            case "loan_requirements":
                setLoanRequirementsData(updatedContent);
                break;
            case "loan_circumstances":
                setLoanCircumstancesData(updatedContent);
                break;
            case "loan_financial_awareness":
                setLoanFinancialAwarenessData(updatedContent);
                break;
            case "loan_prioritised":
                setLoanPrioritisedData(updatedContent);
                break;
            case "lender_loan":
                setLenderLoanData(updatedContent);
                break;
            case "loan_structure":
                setLoanStructureData(updatedContent);
                break;
            default:
                console.log("Invalid field type");
                break;
        }
    };

    const handleSaveComplianceNote = () => {
        if (requestId) {
            let payload: CompliancePayload = {
                opportunity_id: requestId,
                document_identification_method: needAnalysisFormData?.documentIdentificationMethod,
                client_interview_method: needAnalysisFormData?.clientInterviewMethod,
                credit_guide_provided: needAnalysisFormData?.dateCreditGuide ? new Date(needAnalysisFormData.dateCreditGuide).toISOString() : undefined,
                estimated_settlement_date: needAnalysisFormData?.estimatedSettlementDate
                    ? new Date(needAnalysisFormData.estimatedSettlementDate).toISOString()
                    : undefined,
                facility_amount: needAnalysisFormData?.facilityAmount,

                rate_type: preferredFormData?.rateType_1a,
                repayment_type: preferredFormData?.repaymentType,
                repayment_frequency: preferredFormData?.repaymentFrequency,
                offset: preferredFormData?.offset,

                cash_out_involved: needAnalysisFormData?.cashOutReason,
                loan_structure: preferredFormData?.loanStructure,
                loan_scenario_lender_1: preferredFormData?.loanScenarioLender1,
                loan_scenario_lender_2: preferredFormData?.loanScenarioLender2,
                loan_scenario_lender_3: preferredFormData?.loanScenarioLender3,

                loan_objective_note: loanObjectiveData,
                loan_requirement_note: loanRequirementsData,
                loan_circumstances_note: loanCircumstancesData,
                loan_financial_awareness_note: loanFinancialAwarenessData,
                loan_structure_note: loanStructureData,
                loan_prioritised_note: loanPrioritisedData,
                lender_loan_note: lenderLoanData,
                loan_structured_note: loanStructureData,
                opportunity_data: {
                    lvr: needAnalysisFormData?.lvr,
                    purpose: needAnalysisFormData?.purpose,
                    offset: preferredFormData?.offset,
                    rateType_1a: preferredFormData?.rateType_1a
                }
            };

            if(!complianceID && pageType === 'create') {
                postComplianceNote(payload)
                .unwrap()
                .then(response => {
                    displaySuccess("Successfully saved compliance notes");
                })
                .catch(error => {
                    displayError(error);
                });
            } 
            if(complianceID && pageType === "update"){
                updateComplianceNote({...payload, complianceId:complianceID})
                .unwrap()
                .then(response => {
                    displaySuccess("Successfully updated compliance notes");
                })
                .catch(error => {
                    displayError(error);
                });
            }
            
        }
    };

    const handleViewUserInputPrompt = (id: ComplianceFieldTypes) => {
        setActivePromptId(id);
        const userPrompt =  getComplianceUserContent({...dealDataMapped, ...preferredFormData, ...needAnalysisFormData}  as ComplianceFormType , id);
        setActiveUserPromt(userPrompt);
        setViewUserPrompt(true);
     };
 
     const handleCloseUserPrompt = () => {
         setViewUserPrompt(false);
         setActivePromptId(null);
         setActiveUserPromt("")
     };

     const handleViewSystemPrompt = (id: ComplianceFieldTypes) => {
        setViewSystemPrompt(true);
        const selectedPrompt = complianceSystemPrompts?.data.find(prompt => prompt.prompt_type === id)
        if(selectedPrompt){
            setSelectedSystemPrompt(selectedPrompt as SystemPromptType<ComplianceFieldTypes>)
        }
     };

     const handleSystemPromptUpdate = (state: boolean) => {
        if(state) {
            refetchCompliancePrompt();
            setSelectedSystemPrompt(null);
        }
        
     };

     const handleSystemPromptClose = () => {
        setViewSystemPrompt(false);
        setSelectedSystemPrompt(null);
     };

     const handleDealUpdateState = () => {
        refetchDealsData();
     };

    return (
        <>
            <AsideContainer sideContent={<ComplianceSidebar />}>
                <Container fullWidth={true} align="left">
                    <Row>
                        <Col xs={24} md={24}>
                            <Flex justify="space-between" align="center" style={{ marginBottom: "1rem" }}>
                                 <Breadcrumb>
                                    {complianceBreadcrumbList.map((breadcrumb, index) => (
                                        <Breadcrumb.Item key={index}>
                                            {breadcrumb.path ? <Link to={breadcrumb.path}>{breadcrumb.title}</Link> : breadcrumb.title}
                                        </Breadcrumb.Item>
                                    ))}
                                 </Breadcrumb>
                                <BackButton />
                            </Flex>
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col xs={24} md={24}>
                            <HeaderRow>
                                <Flex gap={10}>
                                    <PageTitle title={`Compliance Note`} style={{ marginBottom: 0 }} />
                                    <CountTitle>{dealsData?.name}</CountTitle>
                                    <Tooltip title="Edit Deal">
                                        <Button size="small" icon={<EditOutlined />} onClick={() => setOpenDealFrom(true)} />
                                    </Tooltip>
                                    <Tooltip title="View Deal">
                                        <Button size="small" icon={<EyeOutlined />} onClick={() => setViewDealDetail(true)} />
                                    </Tooltip>
                                </Flex>

                                <Flex gap={10}>
                                    <Button
                                        type="primary"
                                        onClick={handleGenerateAllNotes}
                                        loading={
                                            loanObjectivesLoading ||
                                            loanRequirementsLoading ||
                                            loanCircumstancesLoading ||
                                            loanFinancialAwarenessLoading ||
                                            loanPrioritisedLoading ||
                                            lenderLoanLoading ||
                                            loanStructureLoading
                                        }
                                    >
                                        Generate All
                                    </Button>
                                    <Button type="primary" onClick={handleSaveComplianceNote} loading={postComplianceLoading || updateCompliaceLoading}>
                                        Save Note
                                    </Button>
                                </Flex>
                            </HeaderRow>
                            <Scrollbars style={{ height: "calc(100vh - 200px)" }} autoHide={true}>
                                <ComplianceNotePDF
                                    allValues={formFieldsData}
                                    generateAIContent={handleGenerateAIContent}
                                    handleNeedAnalysisForm={handleNeedAnalysisForm}
                                    submitNeedAnalysisRef={submitNeedAnalysisRef}
                                    setNeedAnalysisFormData={updateNeedAnalysisFormData}
                                    loanObjectivesLoading={loanObjectivesLoading}
                                    loanObjectiveData={loanObjectiveData}
                                    loanRequirementsData={loanRequirementsData}
                                    loanRequirementsLoading={loanRequirementsLoading}
                                    loanCircumstancesData={loanCircumstancesData}
                                    loanCircumstancesLoading={loanCircumstancesLoading}
                                    loanFinancialAwarenessData={loanFinancialAwarenessData}
                                    loanFinancialAwarenessLoading={loanFinancialAwarenessLoading}
                                    submitPreferredLoanFormRef={submitPreferredLoanFormRef}
                                    handlePreferredLoanForm={handlePreferredLoanForm}
                                    loanPrioritisedLoading={loanPrioritisedLoading}
                                    loanPrioritisedData={loanPrioritisedData}
                                    lenderLoanData={lenderLoanData}
                                    lenderLoanLoading={lenderLoanLoading}
                                    loanStructureData={loanStructureData}
                                    loanStructureLoading={loanStructureLoading}
                                    onAIContentUpdate={onAIContentUpdate}
                                    complianceGeneratedData={complianceGeneratedData}
                                    viewUserInputPrompt={handleViewUserInputPrompt}
                                    viewSystemPrompt={handleViewSystemPrompt}
                                    ref={complianceNotePDFRef}
                                />
                            </Scrollbars>
                        </Col>
                    </Row>
                </Container>
            </AsideContainer>
            <Drawer title={dealsData?.name ?? ""} onClose={() => setViewDealDetail(false)} open={viewDealDetail} width={themeConfig.drawer.xxl}>
                <DealDetailPanel dealDetail={dealsData?.json_data as DealFormType} />
            </Drawer>

             <Drawer
                title="Update Deal"
                onClose={() => setOpenDealFrom(false)}
                open={openDealFrom}
                width={themeConfig.drawer.xxl}
                extra={
                    <Space>
                        <Button onClick={handleSaveDeal} loading={isLoading} type="primary">
                            Save Deal
                        </Button>
                    </Space>
                }
            >
                <DealForm
                    handleFormSubmit={handleFormSubmit}
                    submitRef={submitRef}
                    formFieldsData={formFieldsData}
                    initialValues={dealsFormData}
                    dealData={dealFieldData}
                    brokerContact={brokerContactData}
                    ref={dealFormRef}
                    contactData={contactData}
                    secondaryContactData={secondaryContactData}

                />
            </Drawer>
            <Drawer 
                open={viewUserPrompt} 
                onClose={handleCloseUserPrompt}
                 size="large"
                title={formatTitle(activePromptId ?? "")}
                >
                    <div>
                        <Input.TextArea rows={20} readOnly={true} value={activeUserPrompt} />
                        <UserPromptForm  secondaryContactData={secondaryContactData} handleDealUpdateState={handleDealUpdateState} contactData={contactData} formData={{...formFieldsData, ...needAnalysisFormData}} dealId={requestId} selectedForm={activePromptId}/>
                    </div>
            </Drawer>

            {complianceSystemPrompts && <Drawer
                open={viewSystemPrompt}
                onClose={handleSystemPromptClose}
                size="large"
                title="Compliance System Prompts"
                >
                    <CompliancePromptsEditor handlePromptUpdateState={handleSystemPromptUpdate} isPromptsLoading={compliancePromptsLoading} systemPrompts={complianceSystemPrompts?.data} selectedPromptId={selectedSystemPrompt?.prompt_type} />
            </Drawer>}
        </>
    );
};

export default ComplianceGenerator;
