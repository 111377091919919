import { FC, useRef, RefObject, useEffect, forwardRef, useImperativeHandle } from "react";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectField from "@/components/Form/SelectField";
import { DatePickerField, InputField } from "@/components/Form";
import { Button, Col, Row } from "antd";
import { CASH_OUT_INVOLVED_OPTIONS, CLIENT_INTERVIEW_OPTIONS, DOCUMENT_IDENTIFICATION_OPTIONS, LOAN_PURPOSE_OPTIONS } from "@/devFrontData/optionList";
import { DealFormType } from "@/pages/deals/types";
import { themeConfig } from "@/config";
import { ComplianceAPIResponse } from "../../types";


const needAnalysisFormSchema = yup.object().shape({
    documentIdentificationMethod: yup.string().required("Method of documenent identification is required"),
    clientInterviewMethod: yup.string().required("Method of client interview is required"),
    dateCreditGuide: yup.string(),
    dateInterview: yup.string(),
    estimatedSettlementDate: yup.string(),
    facilityAmount: yup.number(),
    lvr: yup.number().transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      }).nullable().typeError("LVR must be a number").required("LVR is required"),
      cashOutReason: yup.string(),
    purpose: yup.string().required("Purpose is required"),
});
export type NeedAnalysisFormType = yup.InferType<typeof needAnalysisFormSchema>;

export interface NeedAnalysisFormMethods {
    triggerValidation: () => Promise<{ isValid: boolean; data?: NeedAnalysisFormType }>;
};


interface NeedAnalysisFormProps {
    handleFormSubmit: (data: NeedAnalysisFormType) => void;
    submitRef: RefObject<HTMLButtonElement>;
    setFormData?: (data: NeedAnalysisFormType) => void;
    dealsData?: Partial<DealFormType> | null;
    complianceGeneratedData?: ComplianceAPIResponse | null;
}
const NeedAnalysisForm = forwardRef<NeedAnalysisFormMethods, NeedAnalysisFormProps> (({handleFormSubmit, submitRef, setFormData, dealsData, complianceGeneratedData}, ref) => {
 
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        trigger,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(needAnalysisFormSchema),
        defaultValues: {
            documentIdentificationMethod:"video_over_internet",
            clientInterviewMethod:"email",
            estimatedSettlementDate: "",
            facilityAmount: 0
        }
    });

    

     /**
     ** Passes the updated fields value to the parent component
     */
     useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            if(setFormData) {
                setFormData(updatedData);
            }
            
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    /**
     * Sets deals data to Need Analysis Field
     */
    useEffect(() => {
        if(dealsData) {
            if(dealsData?.date){
                setValue("dateCreditGuide", dealsData?.date ?? "")
            }
            setValue("lvr", dealsData?.lvr ?? 0)
            setValue("purpose", dealsData?.purpose ?? '')
        }
        
    },[dealsData])

    useEffect(()=> {
        if(complianceGeneratedData){
            setValue('cashOutReason', complianceGeneratedData?.cash_out_involved);
            setValue('documentIdentificationMethod', complianceGeneratedData?.document_identification_method ?? "video_over_internet");
            setValue('clientInterviewMethod', complianceGeneratedData?.client_interview_method ?? "email");
            setValue('estimatedSettlementDate', complianceGeneratedData?.estimated_settlement_date ?? "");
            setValue('facilityAmount', complianceGeneratedData?.facility_amount ?? 0);
        }
    },[complianceGeneratedData])

    useImperativeHandle(ref, () => ({
        triggerValidation: async () => {
            const result = await trigger();
            if (result) {
                return { isValid: true, data: getValues() }; 
            }
            return { isValid: false };
        }
    }));

    const onSubmit: SubmitHandler<NeedAnalysisFormType> = data => {
        console.log("submi nedd")
        handleFormSubmit(data);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={themeConfig.rowGutterMd}>
                    <Col xs={24} md={12}>
                        <SelectField
                                name="documentIdentificationMethod"
                                control={control}
                                label="Method of Document Identification"
                                placeholder="Method of Document Identification"
                                error={errors.documentIdentificationMethod?.message}
                                formDirection="row"
                                options={DOCUMENT_IDENTIFICATION_OPTIONS}
                            />
                    </Col>
                    <Col xs={24} md={12}>
                        <SelectField
                                name="clientInterviewMethod"
                                control={control}
                                label="Method of Client Interview"
                                placeholder="Method of Client Interview"
                                error={errors.clientInterviewMethod?.message}
                                formDirection="row"
                                options={CLIENT_INTERVIEW_OPTIONS}
                            />  
                    </Col>
                    <Col xs={24} md={12}>
                        <DatePickerField formDirection="row"  control={control} label="Date Credit Guide Was Provided (Within 90 days of assessment)" name="dateCreditGuide" error={errors.dateCreditGuide?.message} />
                    </Col>
                    <Col xs={24} md={12}>
                        <DatePickerField formDirection="row"  control={control} label="Estimated Settlement Date (Double-Click)" name="estimatedSettlementDate" error={errors.estimatedSettlementDate?.message} />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputField 
                            label="Facility Amount (AU$)" 
                            name="facilityAmount" 
                            control={control} 
                            type="number"
                            error={errors.facilityAmount?.message ?? ""} 
                            formDirection="row"
                            prefix="$"
                        /> 
                    </Col>
                    <Col xs={24} md={12}>
                        <InputField required={true}  formDirection="row" label="LVR" name="lvr" type="number" suffix="%" control={control} error={errors.lvr?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={12}>
                        <SelectField
                            name="cashOutReason"
                            control={control}
                            label="Cash-Out Involved? (If Applicable)"
                            placeholder="Cash-Out Involved"
                            error={errors.cashOutReason?.message}
                            formDirection="row"
                            options={CASH_OUT_INVOLVED_OPTIONS}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <SelectField
                            required={true}
                            formDirection="row"
                            name="purpose"
                            control={control}
                            label="Purpose"
                            placeholder="Purpose"
                            error={errors.purpose?.message}
                            options={LOAN_PURPOSE_OPTIONS}
                        />
                    </Col>
                    <Col xs={24} md={24}>
                        <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                            Form Submit
                        </Button>
                    </Col>
                </Row>
            </form>

            
        </div>
    );
});

export default NeedAnalysisForm;
