import { FC, useEffect } from "react";
import { Row, Col, Button } from "antd";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetUserDetailQuery, useUpdateUserMutation } from "../api/accountApi";

// components
import { InputField, SelectField } from "@/components/Form";
import { BROKER_ROLE } from "@/devFrontData/optionList";
import { PageLoader } from "@/components/UI";
import { UserPayload } from "../types";
import { BrokerRole } from "@/types";
import { displayError, displayInfo } from "@/utils/common.utils";
import { useDispatch } from "react-redux";
import { setUserDetails } from "@/store/slices/auth/authSlice";


const updateProfileSchema = yup.object().shape({
    fullName: yup.string(),
    phone: yup.string(),
    brokerRole: yup.string().nullable().notRequired(),
});
export type UpdateProfileType = yup.InferType<typeof updateProfileSchema>;

interface UpdateProfileProps {
    getUpdateProfileMsg: (msg:string) => void;
};

const UpdateProfile:FC<UpdateProfileProps> = ({ getUpdateProfileMsg }) => {
    const dispatch = useDispatch();
    const {data:userDetail, isLoading:userDetailLoading} = useGetUserDetailQuery();
    const[updateUser, {data: userUpdateResponse, isLoading: userUpdateLoading }] =useUpdateUserMutation();
 
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(updateProfileSchema),
        defaultValues: {
            fullName: "",
            phone: "",
            brokerRole:"",
        }
    });

    useEffect(() => {
        if(userDetail && userDetail?.data){
            setValue("fullName", userDetail?.data.fullname ?? "")
            setValue("phone", userDetail?.data.phone?? "")
            setValue("brokerRole", userDetail?.data.broker_role)
        }
    },[userDetail]);

    const onSubmit: SubmitHandler<UpdateProfileType> =  async (data) => {
        const payload: UserPayload = {
            fullname: data.fullName ?? undefined,
            phone: data?.phone ?? undefined,
            broker_role: data?.brokerRole as BrokerRole ?? undefined,
        } 
        
        updateUser(payload)
        .unwrap()
        .then((response) => {
            dispatch(setUserDetails({ name: response.data.fullname }));
            getUpdateProfileMsg(response?.message ?? "")
        }).catch((error) => {
            displayError(error)
        })
    }

    return (
        <>
            <PageLoader isLoading={userDetailLoading} />
             <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={12}>
                    <Col xs={24} md={24}>
                        <InputField  name="fullName" label="Name" control={control} error={errors.fullName?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={24}>
                        <InputField  name="phone" label="Phone" control={control} error={errors.phone?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={24}>
                        <SelectField
                            name="brokerRole"
                            control={control}
                            label="Processor Role"
                            placeholder="Processor Role"
                            error={errors.brokerRole?.message}
                            options={BROKER_ROLE ?? []}
                        />
                    </Col>
                    <Col xs={24} md={24}>
                        <Button  type="primary" htmlType="submit" style={{ width: "100%"}} loading={userUpdateLoading}>
                            Update Profile
                        </Button>
                    </Col>
                </Row>
                

            </form>
        </>
    );
};

export default UpdateProfile;