// src/store/slices/formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DealFormType } from "@/pages/deals/types";

const initialState: DealFormType = {
    dealName: "",
    dealType:"",
    brokerPrimaryContact: "",
    brokerSecondaryContact:"",
    websiteTrackingId: "",
    formId: "",
    fullName: "",
    email: "",
    phone: "",
    citizenship: "",
    residency: "",
    occupation: "",
    employment: "",
    employer: "",
    since: "",
    currency: "",
    fx: "",
    salary: 0,
    salaryDeduction: 0,
    allowances: 0,
    commission: 0,
    bonusThis: 0,
    bonusLast: "",
    anyLiabilities: "",
    //@ts-ignore
    date: "",
    pricing: "",
    interestRate: 0,
    valuation: "P",
    livingStatus: "",
    currentMortgage_1: "",
    rentalExpense: 0,
    maritalStatus: "",
    kids: "",
    kidsAge: "",
    privateEducation: "",
    purpose: "",
    purposeEquity: "",
    lvr: 0,
    security: "",
    aipRentalyiel: 0,
    apiRent: 0,
    ccTotalLimit: 0,
    reduceLimit: "",
    ccNewLimit: "",
    lender: "",
    existingCustomer: "",
    propertyValue: 0,
    loanAmount: 0,
    loanTerm: 0,
    product: "",
    ccLimit1: 0,
    ccCurrency1: "",
    cardFXConversion1: 0,
    curentCCLimit1: "",
    hasCoApplicant: false,
    coApplicationStatus: false
};

const brokerFormSlice = createSlice({
    name: "brokerFormSlice",
    initialState,
    reducers: {
        setBrokerFormData: (state, action: PayloadAction<Partial<DealFormType>>) => {
            return { ...state, ...action.payload};
        },
        resetBrokerForm: () => initialState
    }
});

export const { setBrokerFormData, resetBrokerForm } = brokerFormSlice.actions;

export default brokerFormSlice.reducer;
