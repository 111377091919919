export const tierOneLenders = [
  {
      id:1,
      name: "ANZ",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
  {
      id:2,
      name: "CBA",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
  {
      id:3,
      name: "Heritage",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
  {
      id:4,
      name: "Macquarie",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
  {
    id:5,
    name: "BOQ",
    documents: ["Document 1", "Document 2", "Document 3"],
},
]

export const tierTwoLenders = [
  {
      id:5,
      name: "Westpac",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
]

export const tierThreeLenders = [
  {
      id:6,
      name: "Qudos",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
]

export const nonBankLenders = [
  {
      id:7,  
      name: "AAA",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
]

export const overseasLenders = [
  {
      id:8,  
      name: "OCBC HK",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
]

export const other = [
  {
      id:9,  
      name: "Other",
      documents: ["Document 1", "Document 2", "Document 3"],
  },
]


export const allLenders = [
  ...tierOneLenders,
  ...tierTwoLenders,
  ...tierThreeLenders,
  ...nonBankLenders,
  ...overseasLenders,
  ...other
];