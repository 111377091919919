import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Row, Col, Button, List } from 'antd';
import { InputField, SelectField } from '@/components/Form';
import UploadFileButton from '../TemplateComponents/UploadFileButton';
import { allLenders } from '@/devFrontData/lenders'; 

// Define the shape of the form data
interface FormValues {
    category: string;
    files: File[]; // Change to an array of files
}

// Define the props for the component
interface NewTemplateUploadFormProps {
    onSubmit: SubmitHandler<FormValues>;
    formBtnText: string;
}

const lenderNames = allLenders.map(lender => ({ value: lender.name, label: lender.name }));


const NewTemplateUploadForm = ({ onSubmit, formBtnText }: NewTemplateUploadFormProps): JSX.Element => {
    const { handleSubmit, control, formState: { isSubmitting }, setValue } = useForm<FormValues>({
        defaultValues: {
            files: []
        }
    });
    const [files, setFiles] = useState<File[]>([]);

    // Handle file change event to update the form state
    const handleFileChange = (fileList: FileList) => {
        const newFiles = [...files, ...Array.from(fileList)];
        setFiles(newFiles);
        setValue('files', newFiles);
    };

    const handleRemoveFile = (index: number) => {
        const newFiles = files.filter((_, idx) => idx !== index);
        setFiles(newFiles);
        setValue('files', newFiles); // Update form state
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={12}>
                    <Col xs={24} md={24}>
                        <SelectField options={lenderNames} name="category" label="Category" control={control} />
                    </Col>
                    <Col xs={24} md={24}>
                        <List
                            itemLayout="horizontal"
                            dataSource={files}
                            renderItem={(file, index) => (
                                <List.Item
                                    actions={[
                                        <Button type="link" onClick={() => handleRemoveFile(index)}>
                                            Remove
                                        </Button>
                                    ]}
                                >
                                    {file.name}
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <UploadFileButton onUpload={handleFileChange}/>
                    </Col>
                    <Col xs={24} md={24} style={{ marginTop: '16px' }}>
                        <Button loading={isSubmitting} type="primary" htmlType="submit" style={{ width: "100%" }}>
                            {formBtnText}
                        </Button>
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default NewTemplateUploadForm;
