import { Col, Row, Typography } from "antd";
import { NoteHeading } from "@/components/UI";
import { FC } from "react";

import { splitName } from "@/utils/common.utils";
import { DealFormType, ContactType } from "@/pages/deals/types";

const { Text } = Typography;

export interface ApplicantDetailsType {
    primaryContact: ContactType | null;
    secondaryContact: ContactType | null;
    otherContact: ContactType | null;
}

interface ApplicantDetailProps {
    applicantDetails: ApplicantDetailsType | null;
    allValues: Partial<DealFormType> | null;
}

const ApplicantDetail: FC<ApplicantDetailProps> = ({ allValues, applicantDetails }) => {
    const { firstName, lastName } = splitName(applicantDetails?.primaryContact?.name || "");
    const { firstName: coFirstName, lastName: coLastName } = splitName(applicantDetails?.secondaryContact?.name || "");
    return (
        <div style={{ marginBottom: "5px" }}>
            <NoteHeading title="Applicant" />
            <Row gutter={8} style={{paddingTop:"10px"}}>
                <Col xs={24} md={3}></Col>
                <Col xs={24} md={3}>
                    <Text strong>First Name</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text strong>Last Name</Text>
                </Col>
                <Col xs={24} md={4}>
                    <Text strong>Occupation</Text>
                </Col>
                <Col xs={24} md={4}>
                    <Text strong>Employer</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text strong>Citizenship</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text strong>Residence</Text>
                </Col>
            </Row>
            {applicantDetails?.primaryContact && (
                <ApplicantContent
                    title="Applicants 1"
                    firstName={firstName ?? ""}
                    lastName={lastName}
                    occupation={allValues?.occupation ?? ""}
                    employer={""}
                    citizenship={applicantDetails?.primaryContact?.citizenship ?? ""}
                    residency={applicantDetails?.primaryContact?.residency ?? ""}
                />
            )}
            {allValues?.hasCoApplicant && (
                <ApplicantContent
                    title="Applicants 2"
                    firstName={coFirstName ?? ""}
                    lastName={coLastName}
                    occupation={allValues?.occupation_2 ?? ""}
                    employer={""}
                    citizenship={applicantDetails?.secondaryContact?.citizenship ?? ""}
                    residency={applicantDetails?.secondaryContact?.residency ?? ""}
                />
            )}
        </div>
    );
};

export default ApplicantDetail;

interface ApplicantContentType {
    title: string;
    firstName: string;
    lastName: string;
    occupation: string;
    employer: string;
    citizenship: string;
    residency: string;
}
const ApplicantContent: FC<ApplicantContentType> = ({ title, firstName, lastName, occupation, employer, citizenship, residency }) => {
    return (
        <Row gutter={8} style={{ marginTop: "5px" }}>
            <Col xs={24} md={3}>
                <Text type="secondary">{title}</Text>
            </Col>
            <Col xs={24} md={3}>
                <Text type="secondary">{firstName}</Text>
            </Col>
            <Col xs={24} md={3}>
                <Text type="secondary">{lastName}</Text>
            </Col>
            <Col xs={24} md={4}>
                <Text type="secondary">{occupation}</Text>
            </Col>
            <Col xs={24} md={4}>
                <Text type="secondary">{employer}</Text>
            </Col>
            <Col xs={24} md={3}>
                <Text type="secondary">{citizenship}</Text>
            </Col>
            <Col xs={24} md={3}>
                <Text type="secondary">{residency}</Text>
            </Col>
        </Row>
    );
};
