import { Flex } from "antd";
import { ReactNode } from "react";

const DealHeaderRow = ({ children }: { children: ReactNode }) => {
    return (
        <Flex style={{ marginBottom: "1rem", width:"100%" }} gap={10} justify="space-between">
            {children}
        </Flex>
    );
};

export default DealHeaderRow;
