export const themeConfig = {
    rowGutterMd: 24,
    rowGutterXl: 42,
    drawerLarge: 1024,
    row: {
        gutter: {
            md: 24
        },
        
    },
    drawer: {
        xxl: 1024
    }
}