import { Col, Row } from "antd";
import { FC, useMemo, useState } from "react";
import { getEmojiFlag, TCountryCode } from "countries-list";
import { InputField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import SelectField from "@/components/Form/SelectField";
import { DefaultOptionsType } from "@/types";
import axios from "axios";

const CARD_CURRENCY = ["HKD", "SGD", "USD", "GBP", "JPY", "AED", "AUD"];
const generateCardCurrencyOptions = (currencies: string[]): DefaultOptionsType[] => {
    return currencies.map(currency => ({
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <span>{getEmojiFlag(currency as TCountryCode)}</span> &nbsp; {currency}
            </div>
        ),
        value: currency
    }));
};

const CardDetailForm: FC<FormPropsType> = ({ control, errors, hasCoApplicant }) => {
    const cardCurrency1Options = useMemo(() => generateCardCurrencyOptions(CARD_CURRENCY), []);

    return (
        <>
            <Row gutter={12}>
                <Col xs={24} md={12}>
                    <InputField label="Credit Card Limit" type="number" name="ccLimit1" control={control} error={errors.ccLimit1?.message ?? ""} />
                </Col>

                <Col xs={24} md={12}>
                    <SelectField
                        options={cardCurrency1Options}
                        label="Card Currency"
                        name="ccCurrency1"
                        control={control}
                        error={errors.ccCurrency1?.message ?? ""}
                    />
                </Col>
                
                <Col xs={24} md={12}>
                    <InputField
                        readonly={true}
                        label="FX Conversion Rate"
                        name="cardFXConversion1"
                        control={control}
                        error={errors.cardFXConversion1?.message ?? ""}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputField
                        readonly={true}
                        label="Current Credit Card Limits(AUD)"
                        name="curentCCLimit1"
                        control={control}
                        error={errors.curentCCLimit1?.message ?? ""}
                    />
                </Col>
            </Row>

            <Row gutter={12}>
                <Col xs={24} md={12}>
                    <InputField
                        variant="filled"
                        label="CC total limit"
                        name="ccTotalLimit"
                        prefix="$"
                        control={control}
                        error={errors.ccTotalLimit?.message ?? ""}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <InputField
                        prefix="$"
                        type="number"
                        label="Proposed Card Limit"
                        name="proposedCardLimit"
                        control={control}
                        error={errors.proposedCardLimit?.message ?? ""}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <InputField
                        prefix="$"
                        type="number"
                        label="Proposed CC total limit"
                        name="proposedCCTotalLimit"
                        control={control}
                        error={errors.proposedCCTotalLimit?.message ?? ""}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputField readonly={true} label="Reduce Limit ?" name="reduceLimit" control={control} error={errors.reduceLimit?.message ?? ""} />
                </Col>
                <Col xs={24} md={12}>
                    <InputField
                        readonly={true}
                        variant="filled"
                        label="CC New Limit"
                        name="ccNewLimit"
                        control={control}
                        error={errors.ccNewLimit?.message ?? ""}
                    />
                </Col>

                {hasCoApplicant && (
                    <>
                        <Col xs={24} md={12}>
                            <InputField
                                label="Credit Card Limit (2)"
                                type="number"
                                name="ccLimit_2"
                                control={control}
                                error={errors.ccLimit_2?.message ?? ""}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <SelectField
                                options={cardCurrency1Options}
                                label="Card Currency (2)"
                                name="cardCurrency2"
                                control={control}
                                error={errors.cardCurrency2?.message ?? ""}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <InputField
                                label="FX Conversion Rate (2)"
                                name="cardFXConversion2"
                                readonly={true}
                                control={control}
                                error={errors.cardFXConversion2?.message ?? ""}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <InputField
                                label="Current Credit Card Limits(AUD) (2)"
                                name="curentCCLimit2"
                                control={control}
                                error={errors.curentCCLimit2?.message ?? ""}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <InputField
                                prefix="$"
                                type="number"
                                label="Proposed Card Limit (2)"
                                name="proposedCardLimit_2"
                                control={control}
                                error={errors.proposedCardLimit_2?.message ?? ""}
                            />
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
};

export default CardDetailForm;
