
import { BrokerNoteFieldTypes, BrokerNoteFormType, ComplianceFieldTypes, ComplianceFormType, ComplianceLoanCircumstancesType, ComplianceLoanObjectiveType, ComplianceLoanPrioritisedType, ComplianceLoanRequirementsType, ComplianceLoanStructureType, LoanPurposeDetailsType, ApplicantOverviewType, LivingConditionsType, EmploymentIncomeType, CommitmentsType, OtherDetailsType, MitigantsType } from "../types";
import { generateLoanObjectiveUserContent, generateLoanRequirementUserContent, generateLoanCircumstancesUserContent, generateLoanPrioritisedUserContent, generateLoanStructureUserContent } from "../constant";
import { generateLoanPurposeUserContent, generateApplicationOverview, generateLivingConditionUserContent, generateEmploymentIncomeUserContent, generateCommitmentsUserContent, generateOtherDetailsUserContent, generateMitigantsUserContent } from "../constant/BrokerNoteUserContent";
import { CASH_OUT_INVOLVED_OPTIONS, FREQUENCE_OPTIONS, getOptionLabel, LENDER_UPDATED_OPTIONS, LOAN_PURPOSE_OPTIONS, RATE_TYPE_OPTIONS, REPAYMENT_OPTIONS } from "@/devFrontData/optionList";
import { getCountryData } from "countries-list";



const mapLoanObjectiveData = (data: ComplianceFormType): ComplianceLoanObjectiveType => ({
    purpose: getOptionLabel(data.purpose ?? "", LOAN_PURPOSE_OPTIONS),
    cashOutReason: getOptionLabel(data?.cashOutReason ?? "", CASH_OUT_INVOLVED_OPTIONS),
    LVR: data.lvr ?? 0
});

const mapLoanRequirementData = (data: ComplianceFormType): ComplianceLoanRequirementsType => ({
    citizenship: data.contactCitizenship ?? "",
    residency: data.contactResidency ?? "",
    purpose: data.purpose ?? "",
});

const mapLoanCircumstancesData = (data: ComplianceFormType): ComplianceLoanCircumstancesType => ({
    nationality1: data.contactCitizenship ?? "",
    residency1: getCountryData( data.contactResidency ?? "" as any)?.name as any,
    employment1: data.employment ?? "",
    occupation1: data.occupation ?? "",
    nationality2: data.secondaryContactCitizenship  ?? "",// *TODO: field validation not done for secondary contact
    residency2: getCountryData( data.secondaryContactResidency ?? "" as any)?.name as any,
    employment2: data.employment_2 ?? "",
    occupation2: data.occupation_2 ?? "",
    salary1: data.calMonthSalary ?? '0', // * calculated base salary
    allowance1: data.calcAllowance ?? "0", 
    averageCommission1: data.calcCommission ?? "0", 
    bonusThisYear1: data.bonusThis ?? 0,
    salary2: data.calMonthSalary_2 ?? "",
    allowance2: data.calcAllowance2 ?? "0",
    averageCommission2:  data.calcCommission2 ?? "0",
    bonusThisYear2: data?.bonusThis_2 ?? 0,
    anyDebtOutstanding: data?.anyLiabilities ?? "",
    creditCardLimit: data.curentCCLimit1 ?? "0",
    maritial: data.maritalStatus ?? "",
    kidsNumber: data.kids ?? "",
    kidsAge: data.kidsAge ?? "",
    livingStatus: data.livingStatus ?? "",
    mortgageOwnerOccupied: data.currentMortgage_1 ?? "",
    rentalExpense: data.rentalExpense ?? "0",
    purpose: data.purpose ?? "",
    purposeEquity: data.purposeEquity ?? "",
});

const mapLoanPrioritisedData = (data: ComplianceFormType): ComplianceLoanPrioritisedType => ({
   rateType:  getOptionLabel(data.rateType_1a ?? "", RATE_TYPE_OPTIONS),
   repaymentType: getOptionLabel(data.repaymentType ?? "", REPAYMENT_OPTIONS) ,
   repaymentFrequency:getOptionLabel(data.repaymentFrequency ?? "", FREQUENCE_OPTIONS) ,
   offset: data.offset ?? "",
});

const mapLoanStructureData = (data: ComplianceFormType): ComplianceLoanStructureType => ({
    rateType: getOptionLabel(data.rateType_1a ?? "", RATE_TYPE_OPTIONS),
    repaymentType: getOptionLabel(data.repaymentType ?? "", REPAYMENT_OPTIONS),
    repaymentFrequency: getOptionLabel(data.repaymentFrequency ?? "", FREQUENCE_OPTIONS),
    loanStructure: data.loanStructure?? ""
})

export const getComplianceUserContent = (data: ComplianceFormType, complianceField: ComplianceFieldTypes) => {
    console.log("complianceField", data)
    switch (complianceField) {
        case "loan_objectives":
           
            const loanObjectivesData = mapLoanObjectiveData(data);
            return generateLoanObjectiveUserContent(loanObjectivesData);
        case "loan_requirements":
            const loanRequirementsData = mapLoanRequirementData(data);
            return generateLoanRequirementUserContent(loanRequirementsData);
        case "loan_circumstances":
            const loanCircumstancesData = mapLoanCircumstancesData(data);
            return generateLoanCircumstancesUserContent(loanCircumstancesData);
        case "loan_financial_awareness": 
            return "";
        case "loan_prioritised":
            const loanPrioritisedData = mapLoanPrioritisedData(data);
            return generateLoanPrioritisedUserContent(loanPrioritisedData);
        case "lender_loan":
            return "";
        case "loan_structure":
            const loanStructureData = mapLoanStructureData(data);
            return generateLoanStructureUserContent(loanStructureData);            
        default:
                throw new Error(`Unknown content name: ${complianceField}`);
    }

}

const mapLoanPurposeDetails = (data: BrokerNoteFormType): LoanPurposeDetailsType => ({
    name: data.contactFullName ?? "",
    name2: data.secondaryContactFullName ?? "",
    LVRs: (data.lvr ?? 0) / 100,
    security: data?.loanDetailAddress ?? "",
    purpose: getOptionLabel(data.purpose ?? "", LOAN_PURPOSE_OPTIONS), 
    lender: getOptionLabel(data.lender ?? "", LENDER_UPDATED_OPTIONS),
    existingCustomer: data.existingCustomer === "no" ? "FALSE" : "TRUE",
    cashOutReason: data.cashOutReason ?? "",
    pricing: data.pricing ?? "",
    interestRate: data.interestRate ?? 0,
    valuation: data.valuation ?? ""
});

const mapApplicantOverviewDetails = (data: BrokerNoteFormType): ApplicantOverviewType => ({
    name: data.contactFullName ?? "",
    nationality: data.contactCitizenship ?? "",
    residency: data.contactResidency ?? "",
    employment: data.employment ?? "",
    occupation: data.occupation ?? "",
    employer: data.employer ?? "",
    jobStarted: data.since ?? "",
    currencyOfIncome: data.currency ?? "",
    name2: data?.secondaryContactFullName ?? "",
    nationality2: data.secondaryContactCitizenship ?? "",
    residency2: data.secondaryContactResidency ?? "",
    employment2: data.employment_2 ?? "",
    occupation2: data.occupation_2 ?? "",
    employer2: data.employer_2 ?? "",
    jobStarted2: data.since_2 ?? "",
    currencyOfIncome2: data.currencySalary_2 ?? ""
});

const mapLivingConditionDetails = (data: BrokerNoteFormType): LivingConditionsType => ({
    name1: data?.contactFullName ?? "",
    name2: data?.secondaryContactFullName ?? "",
    maritalStatus: data.maritalStatus ?? "",
    residency: data.contactResidency ?? "",
    kidsNumber: data.kids ?? "",
    kidsAge: data.kidsAge ?? "",
    privateEducation: data.privateEducation ?? "",
    livingStatus: data.livingStatus ?? "",
    mortgageOwnerOccupied: data.currentMortgage_1 ?? "",
    rentalExpense: data.rentalExpense,
    rentalExpenseAUD: ""
});

const mapEmploymentIncomeDetails = (data: BrokerNoteFormType): EmploymentIncomeType => ({
    date: data.date ?? "",
    fxRateAUDUSD: data.fx ?? "",
    name1: data?.contactFullName ?? "",
    currency1: data.currency ?? "",
    salary1: data.calMonthSalary ?? '',
    deductions1: data.salaryDeduction ?? 0,
    allowance1: data.allowances ?? 0,
    averageCommission1: data.calcCommission ?? 0,
    bonusThisYear1: data.bonusThis ?? 0,
    bonusLastYear1: data.bonusLast as string ?? 0,

    name2: data?.secondaryContactFullName ?? "",
    currency2: data.currencySalary_2 ?? "",
    salary2: data.calMonthSalary_2 ?? 0,
    deductions2:  data.salaryDeduction ?? 0,
    allowance2:  data.allowances_2 ?? 0,
    averageCommission2:  data.calcCommission2 ?? 0,
    bonusThisYear2:  data.bonusThis_2 ?? 0,
    bonusLastYear2: data.bonusLast_2 ?? 0,

    australianRentalYield: (data.aipRentalyiel ?? 0) / 100,
    australianRentalIncome: data.apiRent ?? 0,
    lender: getOptionLabel(data.lender ?? "", LENDER_UPDATED_OPTIONS)
});

const mapCommitmentsDetails = (data: BrokerNoteFormType): CommitmentsType => ({
    name1: data?.contactFullName ?? "",
    name2: data?.secondaryContactFullName ?? "",
    anyDebtOutstanding: data.anyLiabilities ?? "",
    detailOfDebt: data.detailsOfDebt ?? "",
    creditCardLimit: data.ccTotalLimit ?? "",
    reduceLimit: data.reduceLimit ?? "",
    newCreditCardLimit: data.ccNewLimit
});

const mapOthersDetails = (data: BrokerNoteFormType): OtherDetailsType => ({
    name1: data?.contactFullName ?? "",
    number1: data.contactPhone ?? "",
    name2: data?.secondaryContactFullName ?? "",
    number2: data.secondaryContactPhone ?? ""
});

const mapMitigants = (data: BrokerNoteFormType): MitigantsType => ({
    generatedPurpose: data.generatedPurpose ?? "",
    generatedApplicantOverview: data.generatedApplicantOverview ?? "",
    generatedLivingConditions: data.generatedLivingConditions ?? "",
    generatedEmploymentIncome: data.generatedEmploymentIncome ?? "",
    generateCommitments: data?.generateCommitments ?? "",
    fundsAvailable:0
});

export const getBrokerUserContent = (data: BrokerNoteFormType, contentName: BrokerNoteFieldTypes) => {
    switch (contentName) {
        case "loan_purpose":
            const loanPurposeDetails = mapLoanPurposeDetails(data);
            return generateLoanPurposeUserContent(loanPurposeDetails);
        case "applicant_overview":
            const applicantOverviewDetails = mapApplicantOverviewDetails(data);
            return generateApplicationOverview(applicantOverviewDetails);
        case "living_condition":
            const livingConditionDetails = mapLivingConditionDetails(data);
            return generateLivingConditionUserContent(livingConditionDetails);
        case "employment_income":
            const employmentIncomeDetails = mapEmploymentIncomeDetails(data);
            return generateEmploymentIncomeUserContent(employmentIncomeDetails);
        case "commitments":
            const commitmentsDetails = mapCommitmentsDetails(data);
            return generateCommitmentsUserContent(commitmentsDetails);
        case "others":
            const othersDetails = mapOthersDetails(data);
            return generateOtherDetailsUserContent(othersDetails);
        case "mitigants":
            const mitigantDetails = mapMitigants(data);
            return generateMitigantsUserContent(mitigantDetails);
        default:
            throw new Error(`Unknown content name: ${contentName}`);
    }
};