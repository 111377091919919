import { theme } from "antd";
import Title from "antd/es/typography/Title";

const { useToken } = theme;

const DetailViewTitle = ({ title }: { title: string }) => {
    const { token } = useToken();
    return (
        <div style={{ borderBottom: `1px solid ${token.colorBorder}` }}>
            <Title level={4} style={{ fontWeight: "400", margin: "0", padding: "1rem 0" }}>
                {title}
            </Title>
        </div>
    );
};

export default DetailViewTitle;
