import { FC, useEffect, useRef, useState } from "react";
import { Row, Col, Skeleton } from 'antd';
import { BrokerNoteFieldTypes, SystemPromptType } from "../../types";
import SystemPromptForm from "../SystemPromptForm";
import Title from "antd/es/typography/Title";
import { BROKER_TITLE_MAPPER } from "../../constant/list";
import { displayError, displaySuccess } from "@/utils/common.utils";
import { useUpdateBrokerSystemPromptMutation } from "../../api/brokerNoteGeneratorAPI";

interface BrokerNotePromptsEditorProps {
    selectedPromptId: BrokerNoteFieldTypes | undefined;
    systemPrompts: SystemPromptType<BrokerNoteFieldTypes>[];
    isPromptsLoading: boolean;
    handlePromptUpdateState: (state:boolean) => void;
};

const BrokerNotePromptsEditor:FC<BrokerNotePromptsEditorProps> = ({isPromptsLoading, systemPrompts, selectedPromptId, handlePromptUpdateState}) => {
    const promptRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});
    const [selectedId, setSelectedId] = useState<BrokerNoteFieldTypes|undefined>(undefined)
    useEffect(() => {
        if(selectedPromptId){
            setSelectedId(selectedPromptId)
        }
    },[selectedPromptId]);
    
    const [updateBrokerNoteSystemPrompt] =  useUpdateBrokerSystemPromptMutation();

    useEffect(() => {
        if (selectedId && promptRefs.current[selectedId]) {
            promptRefs.current[selectedId]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [selectedId, systemPrompts]);

    const handleUpdatedPrompt = (id:string, prompt:string) => {
        setLoadingStates((prev) => ({...prev, [id]: true}))
        setSelectedId(() => {
            const selecteditem = systemPrompts.find(prompt => prompt.id === id)
            return selecteditem?.prompt_type
        })
        updateBrokerNoteSystemPrompt({promptId: id, prompt}).unwrap()
            .then((response) => {
                setLoadingStates((prev) => ({...prev, [id]: false}));
                displaySuccess("Successfully updated system prompt");
                handlePromptUpdateState(true)
            })
            .catch(err => {
                setLoadingStates((prev) => ({...prev, [id]: false}));
                displayError(err);
            })
    };
    if(isPromptsLoading) {
        return <Skeleton />
    }
    return (
        <>
            {systemPrompts.map(systemPrompt => {
                return (
                    <Row key={systemPrompt.id} style={{marginBottom:"1.5rem"}} ref={(el) => (promptRefs.current[systemPrompt.prompt_type] = el)}>
                        <Col xl={24}>
                            <Title level={5}>{BROKER_TITLE_MAPPER[systemPrompt?.prompt_type ] || 'System Prompt'}</Title>
                            <SystemPromptForm isLoading={loadingStates[systemPrompt.id] || false} prompt={systemPrompt.prompt} getUpdatedPrompt={(prompt) => handleUpdatedPrompt(systemPrompt.id, prompt)}  />
                        </Col>
                    </Row>
                )
            })}
        </>
    );
};

export default BrokerNotePromptsEditor;
