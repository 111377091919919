import { PageTitle } from "@/components/UI";
import { Button, Drawer, Flex, Skeleton } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi, ICellRendererParams } from "ag-grid-community";

import { useGetPoliciesQuery } from "../api/policiesAPI";
import ActionButtonsRenderer from "@/pages/deals/components/ActionButtonsRenderer";
import { OpenAIFilled } from "@ant-design/icons";
import { themeConfig } from "@/config";
import PolicyCopilot from "../components/PolicyCopilot";

const Policies = () => {
    const {data: policiesData, isLoading: policiesDataLoading, isError: policiesDataError} = useGetPoliciesQuery(undefined, {refetchOnMountOrArgChange:true});
    const [openCopilot, setOpenCopilot] = useState(false);
    const gridRef = useRef<any>(null);
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const handleViewPolicy = (id:string) =>{
        console.log("handleViewPolicy", id);
    };

    useEffect(() => {
        if (!policiesDataLoading && policiesData) {
            const columns: ColDef[] = [
                { headerName: 'Policy Name', field: 'policy_name', sortable: true, filter: true,  pinned: "left", },
            ];


            policiesData.data.banks.forEach(bank => {
                columns.push({
                    headerName: `${bank.name}`,
                    field: `bank_${bank.id}.description`,
                    sortable: true,
                    filter: true,
                    cellRenderer: ExpandableCellRenderer,
                    // editable: true,
                    
                });
            });
            columns.push({
                headerName: "Actions",
                cellRenderer: ActionButtonsRenderer,
                cellRendererParams: {
                    buttons:['view', 'edit'],
                    selectTooltip: "Open Contact",
                   
                },
                width: 80,
                suppressMenu: true,
                pinned: "right",
                filter: false
            });

            setColumnDefs(columns);
            gridApi?.setColumnDefs(columns);  
        }
    }, [policiesData, policiesDataLoading, gridApi]);

    if (policiesDataError) {
        return <div>Error loading data</div>;
    }
    
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    return (
        <div style={{display:"block", width:"100%", padding:"0 10px"}}>
            <Flex style={{marginBottom:"1rem"}} justify="space-between">
                <PageTitle title="Policies" />
                <Button icon={<OpenAIFilled />} type="primary" onClick={() => setOpenCopilot(true)}>Copilot</Button>
            </Flex>
            {policiesDataLoading ? (
                <Skeleton />
            ) : (
                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 200px)" }}>
                    <AgGridReact 
                        defaultColDef={defaultColDef} 
                        tooltipInteraction={true} 
                        ref={gridRef} 
                        rowData={policiesData?.data.policies} 
                        columnDefs={columnDefs} 
                        sideBar={"columns"} 
          
                    />
                </div>
            )} 
            <Drawer
               width={themeConfig.drawerLarge}
               open={openCopilot}  onClose={() => setOpenCopilot(false)}>
                <PolicyCopilot />
            </Drawer>
        </div>
    );
};

const ExpandableCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => setIsExpanded(!isExpanded);
  
    return (
      <div onClick={toggleExpand} style={{ cursor: 'pointer', padding: '5px' }}>
        {isExpanded ? value : `${value.substring(0, 50)}...`}
      </div>
    );
  };
  


export default Policies;