import { FC, useState } from "react";
import { Tabs, Modal, Drawer } from "antd";
import type { TabsProps } from "antd";

//redux
import { useDispatch } from "react-redux";
import {
    useGetAllOpportunityServiceHistoryQuery,
    useDeleteOpportunityServiceMutation,
    useGetOpportunityServiceHistoryQuery
} from "@/store/apis/opportunityApi";
import { setLoading } from "@/store/slices/common/commonSlice";

//components
import DealList from "./DealList";
//types
import { Deal, DealFormType, DealListType } from "../../types";
import { displayError, displaySuccess } from "@/utils/common.utils";
import DealDetailPanel from "../DetailListPanel";
import { themeConfig } from "@/config";

interface DealTabInterface {

    handleEditDeal:(data:any, listType: DealListType) => void;
    handleGenerateComplianceNote:(dealId: number) => void;
    handleGenerateBrokerNote: (dealId: number) => void;
}
const DealTab: FC<DealTabInterface> = ({ handleEditDeal, handleGenerateComplianceNote, handleGenerateBrokerNote }) => {
    const dispatch = useDispatch();
    const {
        data: allDeals,
        isLoading: allDealLoading,
        isError: allDealError,
        refetch: refetchAllDealList
    } = useGetAllOpportunityServiceHistoryQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const {
        data: deals,
        isLoading: dealsLoading,
        isError: dealsError,
        refetch: refetchDeals
    } = useGetOpportunityServiceHistoryQuery(undefined, {
        refetchOnMountOrArgChange: true
    });
    const [deleteOpportunity, { isLoading: deleteOpportunityLoading }] = useDeleteOpportunityServiceMutation();

    const [listType, setListType] = useState<DealListType>("self");
    const [viewDealDetail, setViewDealDetail] = useState(false);
    const [dealsData, setDealsData] = useState<Deal | null>(null);

    
    const editDeal = (data:any) => {
      handleEditDeal(data, listType)
    };


    const handleConfirmDeleteDeal = (data: any) => {
        dispatch(setLoading(deleteOpportunityLoading));
        deleteOpportunity(data.id)
            .then((response: any) => {
                displaySuccess(response.message);
                refetchAllDealList();
                refetchDeals();
            })
            .catch(err => {
                displayError(err);
            })
            .finally(() => {
                dispatch(setLoading(deleteOpportunityLoading));
            });
    };
    const handleDeleteDeal = (data: any) => {
        Modal.confirm({
            title: `Are you sure you want to delete "${data.name}"?`,
            onOk: () => handleConfirmDeleteDeal(data),
            okType: "danger",
            okText: "Yes Confirm",
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            )
        });
    };

    const handleViewDeal = (deal: any) => {
        setViewDealDetail(true);
        setDealsData(deal);
    };

    const items: TabsProps["items"] = [
        {
            key: "self",
            label: "Your Deals",
            children: (
                <DealList
                    actionList={['view', 'edit', 'delete', 'complianceNote', 'brokerNote']}
                    listType={listType}
                    rowData={deals}
                    isLoading={dealsLoading}
                    isError={dealsError}
                    handleDeleteDeal={handleDeleteDeal}
                    handleEditDeal={editDeal}
                    handleGenerateComplianceNote={handleGenerateComplianceNote}
                    handleGenerateBrokerNote={handleGenerateBrokerNote}
                    handleViewDeal={handleViewDeal}
                />
            )
        },
        {
            key: "all",
            label: "All Deals",
            children: <DealList 
                 actionList={['view']} 
                 listType={listType} 
                 rowData={allDeals} 
                 isLoading={allDealLoading} 
                 isError={allDealError} 
                 handleViewDeal={handleViewDeal}
                  />
        }
    ];
    const onChange = (key: string) => {
        setListType(key as DealListType);
    };
    return (
        <>
            <Tabs defaultActiveKey="self" items={items} onChange={onChange} />
            <Drawer title={dealsData?.name ?? ""} onClose={() => setViewDealDetail(false)} open={viewDealDetail} width={themeConfig.drawer.xxl}>
                <DealDetailPanel dealDetail={dealsData?.json_data as DealFormType} />
            </Drawer>
        </>
    );
};

export default DealTab;
