import { ReactNode, useEffect, useRef, useState } from "react";
import {  useNavigate } from "react-router-dom";

import { Row, Col, Typography, Button, Drawer } from "antd";

//redux
import { useGetOpportunityServiceHistoryQuery } from "@/store/apis/opportunityApi";


//Components
import DealList from "@/pages/deals/components/DealTab/DealList";
import { Container } from "@/components/Layout";
import AsideContainer from "@/components/Layout/AsideContainer";
import HistorySidebar from "../../components/HistorySidebar";

//utils
import { themeConfig } from "@/config";
import { PageTitle } from "@/components/UI";
import BrokerNoteTable from "../../components/BrokerNoteTable";
import DealDetailPanel from "@/pages/deals/components/DetailListPanel";
import { Deal, DealFormType } from "@/pages/deals/types";
import { BrokerNoteResponse } from "../../types";
import BrokerNoteDetailPanel from "../../components/BrokerNoteDetailPanel";
import { BROKER_FIELD_LABELS } from "../../constant/list";
import BrokerNoteSidebar from "../../components/BrokerNote/BrokerNoteSidebar";


const { Title } = Typography;

const BrokerNotes = () => {
    const navigate = useNavigate();
    const [viewDealDetail, setViewDealDetail] = useState(false);
    const [dealsData, setDealsData] = useState<Deal | null>(null);

    const [viewBrokerNote, setViewBrokerNote] = useState(false);
    const [brokerNoteData, setBrokerNoteData] = useState<any>(null);

    const {
        data: deals,
        isLoading: dealsLoading,
        isError: dealsError,
        refetch: refetchDeals
    } = useGetOpportunityServiceHistoryQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const handleGenerateBrokerNote = (dealId: number) => {
        navigate(`/note-generation/broker-notes/${dealId}?source=deals`);
    };

    const handleViewDeal = (deal:any) => {
        setViewDealDetail(true);
        setDealsData(deal);
    };

    const handleViewBrokerNote = (data: BrokerNoteResponse) => {
        setBrokerNoteData((prevData: any) => {
            return {
                ...data, 

                name: data?.opportunity?.name
            }
        });
        setViewBrokerNote(true);
    };

    const handleEditBrokerNote = (data: BrokerNoteResponse) => {
        navigate(`/note-generation/broker-notes/${data.opportunity.id}/${data.id}`);
    };

    return (
        <>
        <AsideContainer sideContent={<BrokerNoteSidebar />}>
            <Container fullWidth={true} align="left">
                <Row gutter={themeConfig.row.gutter.md} >
                    <Col xs={24} md={12}>
                        <PageTitle title="Deals" />
                        <DealList 
                            listType="self"
                            actionList={['view','brokerNote']}
                            rowData={deals}
                            isLoading={dealsLoading}
                            isError={dealsError}
                            handleGenerateBrokerNote={handleGenerateBrokerNote}
                            handleViewDeal={handleViewDeal}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <PageTitle title="Broker Notes"  />
                        <BrokerNoteTable actionButtons={["view", "edit"]} viewNote={handleViewBrokerNote} editNote={handleEditBrokerNote} />
                    </Col>
                </Row>
            </Container>
        </AsideContainer>
        <Drawer title={dealsData?.name ?? ""} onClose={() => setViewDealDetail(false)} open={viewDealDetail} width={themeConfig.drawer.xxl}>
                <DealDetailPanel dealDetail={dealsData?.json_data as DealFormType} />
            </Drawer>
            <Drawer title={brokerNoteData?.name ?? ""} onClose={() => setViewBrokerNote(false)} open={viewBrokerNote} width={themeConfig.drawer.xxl}>
                <BrokerNoteDetailPanel data={brokerNoteData} tableMapper={BROKER_FIELD_LABELS} />
            </Drawer>
        </>
    );
};

export default BrokerNotes;
