import { Flex } from "antd";
import { ReactNode } from "react";

const HeaderRow = ({ children }: { children: ReactNode }) => {
    return (
        <Flex style={{ marginBottom: "2rem" }} gap={10} justify="space-between">
            {children}
        </Flex>
    );
};

export default HeaderRow;