import { ChangeEvent, ChangeEventHandler, FC, useState } from "react";
import { Button, Flex, Modal, Input, message, Drawer, Tooltip, theme } from "antd";
import ProfileMenu from "../ProfileMenu";
import { CopilotButton, ThemeToggle } from "@/components/UI";
import { usePostUserFeedbackMutation } from "@/store/apis/userApi";
import { useTheme } from "@/contexts/themeContext";
import styles from "./Header.module.scss";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { useToken } = theme;

interface HeaderProps {
    openCopilot: () => void;
}
const Header: FC<HeaderProps> = ({ openCopilot }) => {
    const { sidebarCollapseState, toggleSidebarState } = useTheme();
    const {token} =useToken();
  
    const [open, setOpen] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [feedback, setFeedback] = useState("");

    const [postUserFeedback, { isLoading: postUserFeedbackLoading }] = usePostUserFeedbackMutation();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(e.target.value);
    };
    const handleSubmit = () => {
        postUserFeedback({ message: feedback })
            .unwrap()
            .then(() => {
                messageApi.open({
                    type: "success",
                    content: "Successfully saved opportunity."
                });
                setOpen(false);
            });
    };
    const toggleCollapsed = () => {
        toggleSidebarState();
    };
    return (
        <>
            {contextHolder}
                    
                        <Button
                            onClick={toggleCollapsed}
                            className={`${styles["menu-button"]} ${sidebarCollapseState ? styles.btnCollapse : ""}`}
                            type="text"
                            style={{color:token.colorTextSecondary}}
                            icon={sidebarCollapseState ? <MenuUnfoldOutlined style={{fontSize:"22px"}} />  : <MenuFoldOutlined style={{fontSize:"22px"}}/>}
                        />
                    
            <div className={styles.headerButton}>
                <CopilotButton openCopilot={openCopilot} />
            </div>
            <Flex justify="flex-end" align="center" gap={10} className={`header ${styles.header}`}>
                <Button size="small" type="primary" onClick={() => setOpen(true)}>
                    Feedback
                </Button>
                <ThemeToggle />
                <ProfileMenu />
            </Flex>
            <Modal
                title={<p>Give Feedback</p>}
                footer={
                    <Button type="primary" onClick={handleSubmit} loading={postUserFeedbackLoading}>
                        Submit
                    </Button>
                }
                open={open}
                onCancel={() => setOpen(false)}
            >
                <TextArea name="feedback" rows={4} placeholder="Feedback" onChange={handleChange} />
            </Modal>
        </>
    );
};

export default Header;
