import { FC, ReactNode } from "react";
import { theme } from "antd";

const { useToken } = theme;

interface CountTitleProps {
    children: ReactNode
}
const CountTitle:FC<CountTitleProps> = ({children}) => {
    const { token } = useToken();

    return (
        <>
            <div style={{
                padding: "4px 10px",
                borderRadius:"5px",
                color: token.colorPrimary,
                background: "rgba(5,162,228, 0.1)",
                lineHeight: '1.5',
                fontSize:"13px",
                fontWeight: "600",
                display: "inline-block"
            }}>
                {children}
            </div>
        </>
    )
}

export default CountTitle;