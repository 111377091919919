import { FC, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { countries, getEmojiFlag, TCountryCode, getCountryCode } from "countries-list";
import { ContactType, DealFormType } from "@/pages/deals/types";
import { InputField, SelectField } from "@/components/Form";
import { LOAN_PURPOSE_OPTIONS } from "@/devFrontData/optionList";
import { UserPromptFormTypes } from "@/pages/noteGeneration/types";
import { FormFieldDataType } from "@/pages/deals/components/DealForm";
import { CoutriesOptionType } from "@/pages/deals/components/DealForm/forms/BasicDetailForm";

const schema = yup.object().shape({
    purpose: yup.string(),
    contactEmail: yup.string().email("Enter a valid email address."),
    contactCitizenship: yup.string().nullable().notRequired(),
    contactResidency: yup.string().nullable().notRequired()
});

type formType = yup.InferType<typeof schema>;

interface ComplianceLoanRequiremtFormProps {
    isLoading: boolean;
    formId: UserPromptFormTypes;
    formData?: Partial<FormFieldDataType> | null;
    contactData: ContactType | null;
    handleFormSubmit: ({ id, data }: { id: UserPromptFormTypes; data: Partial<DealFormType> }) => void;
}

const ComplianceLoanRequiremtForm: FC<ComplianceLoanRequiremtFormProps> = ({ formId, formData, contactData, handleFormSubmit, isLoading }) => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            purpose: ""
        }
    });
    const [countryOptions, setCountryOptions] = useState<CoutriesOptionType[]>([]);

    useEffect(() => {
        let autoCompleteOptions = [];
        for (let country in countries) {
            autoCompleteOptions.push({
                value: country,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{getEmojiFlag(country as TCountryCode)}</span> &nbsp; {countries[country as TCountryCode]?.name}
                    </div>
                )
            });
        }
        setCountryOptions(autoCompleteOptions);
    }, []);

    useEffect(() => {
        if (formData) {
            setValue("purpose", formData?.purpose ?? "");
        }
    }, [formData]);

    useEffect(() => {
        if (contactData) {
            setValue("contactResidency", getCountryCode(contactData?.residency ?? "") as string);
            setValue("contactCitizenship", contactData?.citizenship ?? "");
        }
    }, [contactData]);

    const onSubmit: SubmitHandler<formType> = data => {
        handleFormSubmit({ id: formId, data });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xl={24}>
                    <InputField 
                        label="Citizenship" 
                        name="contactCitizenship" 
                        control={control} 
                        error={errors.contactCitizenship?.message ?? ""} 
                        formDirection="row" 
                    />
                </Col>
                <Col xl={24}>
                    <SelectField
                        options={countryOptions}
                        label="residency"
                        name="contactResidency"
                        control={control}
                        error={errors.contactResidency?.message ?? ""}
                        formDirection="row"
                    />
                </Col>
                <Col xl={24}>
                    <SelectField
                        name="purpose"
                        control={control}
                        label="Purpose"
                        placeholder="Loan Purpose"
                        error={errors.purpose?.message}
                        options={LOAN_PURPOSE_OPTIONS}
                        formDirection="row"
                    />
                </Col>

                <Col xl={24}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Update
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default ComplianceLoanRequiremtForm;
