import { InputField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC } from "react";
import SelectField from "@/components/Form/SelectField";
import { SALARY_FREQUENCY } from "@/devFrontData/optionList";

const CoApplicantOccupation: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            
            <Col xs={24} md={12}>
                <InputField label="Occupation" name="occupation_2" control={control} error={errors.occupation_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Employment" name="employment_2" control={control} error={errors.employment_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField  name="employer_2" control={control} label="Employer (2)" error={errors.employer_2?.message} />
            </Col>  
            <Col xs={24} md={12}>
                <InputField label="Start Date" name="since_2" control={control} error={errors.since_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="currencySalary_2"
                    control={control}
                    label="Currency"
                    placeholder="Currency"
                    error={errors.currencySalary_2?.message}
                    options={[
                        { label: "AUD", value: "aud" },
                        { label: "USD", value: "usd" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField variant="filled" name="fx_2" control={control} label="FX" error={errors.fx_2?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="salary_2" control={control} label="Salary" error={errors.salary_2?.message} />
            </Col>
            
            <Col xs={24} md={12}>
                <SelectField
                        name="salaryFreq_2"
                        control={control}
                        label="Salary Frequency"
                        placeholder="Salary Frequency"
                        error={errors.salaryFreq_2?.message}
                        options={SALARY_FREQUENCY}
                    />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" readonly={true} variant="filled" name="calMonthSalary_2" control={control} label="Base Salary" error={errors.calMonthSalary_2?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="salaryDeduction_2" control={control} label="Deduction" error={errors.salaryDeduction_2?.message} />
            </Col>       
            <Col xs={24} md={12}>
                <InputField prefix="$" name="allowances_2" control={control} label="Allowances" error={errors.allowances_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="allowanceFreq_2"
                    control={control}
                    label="Allowance Frequency"
                    placeholder="Allowance Frequency"
                    error={errors.allowanceFreq_2?.message}
                    options={SALARY_FREQUENCY}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="calcAllowance2" variant="filled" readonly={true} control={control} label="Calc Allowance" error={errors.calcAllowance2?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="commission_2" control={control} label="Commission" error={errors.commission_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="commFreq_2"
                    control={control}
                    label="Commission Frequency"
                    placeholder="Commission Frequency"
                    error={errors.commFreq_2?.message}
                    options={SALARY_FREQUENCY}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="calcCommission2" variant="filled" readonly={true} control={control} label="Calc Commission" error={errors.calcCommission2?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="bonusThis_2" control={control} label="Bonus this year(2)" error={errors.bonusThis_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="bonusLast_2" control={control} label="Bonus last year(2)" error={errors.bonusLast_2?.message} />
            </Col>
                  
            {/* <Col xs={24} md={12}>
                <InputField
                    prefix="$"
                    name="coApplicantData.coApplicantDeduction"
                    control={control}
                    label="Deduction"
                    error={errors.coApplicantData?.coApplicantDeduction?.message}
                />
            </Col> */}
            {/* <Col xs={24} md={12}>
                <InputField
                    prefix="$"
                    name="coApplicantData.coApplicantAllowances"
                    control={control}
                    label="Allowances"
                    error={errors.coApplicantData?.coApplicantAllowances?.message}
                />
            </Col> */}
        </Row>
    );
};

export default CoApplicantOccupation;
