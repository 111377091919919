import { DealFormType } from "@/pages/deals/types";

export const getLoanAmount = (lvr:number, propertyValue:number) => {
    return (lvr/100) * propertyValue
};
export const getStampDuty = (propertyValue:number) => {
    return (5/100) * propertyValue
};

export const getFundsRequired = (allValues:DealFormType) => {
    const propertyValue = allValues?.propertyValue ?? 0;
    const loanAmount = getLoanAmount(allValues?.lvr ?? 0, propertyValue);
    const cashOutAmount = allValues?.cashOutAmount ?? 0;
    const stampDuty = getStampDuty(propertyValue);

    return propertyValue  + stampDuty - loanAmount - cashOutAmount;
}
