import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

//Store
import ActionButtonsRenderer, { ActionButtonType } from "../ActionButtonsRenderer";
import { displayError } from "@/utils/common.utils";
import { DealListType } from "../../types";


interface DealListProps {
    actionList?: ActionButtonType[];
    isLoading: boolean;
    isError: boolean;
    rowData: Record<string, any>[] | undefined;
    listType: DealListType;
    handleDeleteDeal?: (data:any) => void;
    handleEditDeal?: (data:any) => void;
    handleViewDeal?: (data: any) => void;
    handleGenerateComplianceNote?: (dealId: number) => void;
    handleGenerateBrokerNote?: (dealId: number) => void;

}
const DealList: FC<DealListProps> = ({  isLoading, rowData, isError, actionList, listType, handleDeleteDeal, handleViewDeal, handleEditDeal, handleGenerateComplianceNote, handleGenerateBrokerNote}) => {

    // const actionList = listType === "all"? ['view'] : ['view', 'edit', 'delete', 'complianceNote']
    const navigate = useNavigate();
    const gridRef = useRef<any>(null);

    const handleAction = (data: any) => {
        navigate(`/deals/${data.id}?source=deals`, { replace: true });
    };

    const deleteDeal = (data:any) => {
        if(handleDeleteDeal){
            handleDeleteDeal(data)
        };
    };

    const editDeal = (data:any) => {
        if(handleEditDeal) {
            handleEditDeal(data)
        }
    };

    const viewDeal = (data:any) => {
        console.log(data)
        if(handleViewDeal) {
            handleViewDeal(data)
        }   
        
    }
        

    const createComplianceNote = (data:any) => {
        if(handleGenerateComplianceNote){
            handleGenerateComplianceNote(data.id as number)
        }
        
    }

    const createBrokerNote = (data:any) => {
        if(handleGenerateBrokerNote){
            handleGenerateBrokerNote(data.id as number)
        }
    }

    const [colDefs, setColDefs] = useState<ColDef[]>(() => {

        const columns: ColDef[] =  [
            {
                headerName: "Deal Name",
                field: "name",
                headerTooltip: "Deal Name",
                editable: true
            },
             {
                headerName: "Deal Type",
                field: "type",
                headerTooltip: "Deal Type",
                editable: true
            },
            {
                headerName: "Contact",
                field: "primary_contact.email",
                headerTooltip: "Primary Contact",
                editable: true
            },
            {
                headerName: "Secondary Contact",
                field: "secondary_contact.email",
                headerTooltip: "Secondary Contact",
                editable: true
            },
            {
                headerName: "Actions",
                cellRenderer: ActionButtonsRenderer,
                cellRendererParams: {
                    buttons:actionList,
                    onAction: handleAction, // Passing the callback function to the renderer
                    handleDelete: deleteDeal,
                    handleEdit: editDeal,
                    handleView: viewDeal,
                    handleGenerateComplianceNote: createComplianceNote,
                    handleGenerateBrokerNote: createBrokerNote,
                },
                width: 150,
                suppressMenu: true,
                pinned: "right",
                filter: false,
            }
        ];
    
        return columns;
    });

    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    useEffect(() => {
        if(isError){
            displayError("Error fetching deals");
        }
    },[isError])

    return (
        <div>
            {isLoading ? (
                <Skeleton />
            ) : (
                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 280px)" }}>
                    <AgGridReact 
                        defaultColDef={defaultColDef} 
                        tooltipInteraction={true} 
                        ref={gridRef} 
                        rowData={rowData} 
                        columnDefs={colDefs} 
                        sideBar={"columns"} 
                        pagination={true} 
                        paginationPageSize={20}
                        />
                </div>
            )}
        </div>
    );
};

export default DealList;
