import { DetailViewPanel } from "@/components/UI";
import { REFINANCE_TABLE_MAPPER } from "@/devFrontData/formMapper";
import { REFINANCE_DATA_CATEGORY } from "@/pages/deals/constant";
import { FC } from "react";

interface RefinanceDetailViewProps {
    data: Record<string, any>;
}
const RefinanceDetailView:FC<RefinanceDetailViewProps> = ({data}) => {
    return (
        <>
            <DetailViewPanel
                data={data}
                title="Contact Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="contactDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title={`Has Co-Applicant : ${data?.coApplicationStatus}`}
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="contactDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
                hasBody={false}
            />

            {data?.coApplicationStatus === 'Yes'&& <DetailViewPanel
                data={data}
                title="Co-Applicant Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="coApplicantDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />}

            <DetailViewPanel
                data={data}
                title="Loan Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="loanDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="Employment Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="employmentDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="Family Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="familyDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="Australian Properties"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="australianProperties"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="Non Australian Properties"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="nonAustralianProperties"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="SE Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="seDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="Other Loan Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="otherLoanDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />

            <DetailViewPanel
                data={data}
                title="Card Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="cardDetail"
                categorySource={REFINANCE_DATA_CATEGORY}
            />
            {data?.coApplicationStatus === 'Yes'&& <DetailViewPanel
                data={data}
                title="Co-Applicant Employment Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="employmentDetailSecondary"
                categorySource={REFINANCE_DATA_CATEGORY}
            />}
            {data?.coApplicationStatus === 'Yes'&& <DetailViewPanel
                data={data}
                title="Co-Applicant SE Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="seDetailSecondary"
                categorySource={REFINANCE_DATA_CATEGORY}
            />}
            {data?.coApplicationStatus === 'Yes'&& <DetailViewPanel
                data={data}
                title="Co-Applicant Loan Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="loanDetailSecondary"
                categorySource={REFINANCE_DATA_CATEGORY}
            />}
            {data?.coApplicationStatus === 'Yes'&& <DetailViewPanel
                data={data}
                title="Co-Applicant Other Loan Detail"
                tableMapper={REFINANCE_TABLE_MAPPER}
                category="anyOtherLoanDetailSecondary"
                categorySource={REFINANCE_DATA_CATEGORY}
            />}
        </>
    );
};

export default RefinanceDetailView;
