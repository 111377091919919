import { InputField, RadioGroupField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC } from "react";
import SelectField from "@/components/Form/SelectField";
import { LIVING_SITUATION_OPTIONS } from "@/devFrontData/optionList";


const FamilyDetailsForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            

            
            
            <Col xs={24} md={12}>
                <SelectField
                    name="livingStatus"
                    control={control}
                    label="Living Status"
                    placeholder="living status"
                    error={errors.livingStatus?.message}
                    options={LIVING_SITUATION_OPTIONS}
                />
            </Col>
          
            <Col xs={24} md={12}>
                <InputField label="Rental Expense" type="number" name="rentalExpense" control={control} error={errors.rentalExpense?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <SelectField
                    name="maritalStatus"
                    control={control}
                    label="Marital Status"
                    placeholder="Currency"
                    error={errors.maritalStatus?.message}
                    options={[
                        { label: "Married", value: "married" },
                        { label: "Single", value: "single" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Kids" name="kids" control={control} error={errors.kids?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Kids Age" name="kidsAge" control={control} error={errors.kidsAge?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <RadioGroupField
                    name="privateEducation"
                    control={control}
                    label="Private Education"
                    error={errors.privateEducation?.message}
                    options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" }
                    ]}
                />
            </Col>
            
            <Col xs={24} md={12}>
                <InputField label="Purpose Equity" name="purposeEquity" control={control} error={errors.purposeEquity?.message ?? ""} />
            </Col>
            
            <Col xs={24} md={12}>
                <InputField label="Security" name="security"  control={control} error={errors.security?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="AIP Rental Yiel" name="aipRentalyiel" suffix="%" control={control} error={errors.aipRentalyiel?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="AIP Rental" name="apiRent" prefix="$" control={control} error={errors.apiRent?.message ?? ""} />
            </Col>

            

            
            <Col xs={24} md={12}>
                <RadioGroupField
                    name="existingCustomer"
                    control={control}
                    label="Existing Customer"
                    error={errors.existingCustomer?.message}
                    options={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" }
                    ]}
                />
            </Col>
        </Row>
    );
};

export default FamilyDetailsForm;
