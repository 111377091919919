import { useNavigate } from "react-router-dom";
import { Row, Col, Flex, Button, Tooltip } from "antd";

// components
import { Container } from "@/components/Layout";
import DealHeaderRow from "../components/DealHeaderRow";

//config
import { themeConfig } from "@/config";
import WebsiteDataList from "../components/WebsiteDataList";
import DealTab from "../components/DealTab";
import { DealListType } from "../types";
import { ArrowsAltOutlined, PlusOutlined } from "@ant-design/icons";
import { PageTitle } from "@/components/UI";
import { useState } from "react";

const Deals = () => {
    const navigate = useNavigate();
    const [activeColumn, setActiveColumn] = useState<"deals" | "data" | "none">("none");

    /**
     * @param data - selected row data
     * @param listType - deal list type 'all' or 'self'
     */
    const handleEditDeal = (data: any, listType: DealListType) => {
        navigate(`/deals/${data.id}?source=deals`);
    };
    /**
     *
     * @param id - The unique identifier for the website data item that is being selected.
     */
    const handleWebsiteDataSelection = (id: string) => {
        navigate(`/deals/${id}?source=website`);
    };

    /**
     * Create a new deal
     */
    const handleAddDeal = () => {
        navigate(`/deals/create`);
    };

    /**
     * handle create deal from the table
     * @param id - The unique identifier for the list
     */
    const handleCreateDeal = (id: string) => {
        navigate(`/deals/create/${id}?source=website`);
    };

    /**
     *
     * @param dealId - Deal ID
     */
    const handleGenerateComplianceNote = (dealId: number) => {
        navigate(`/note-generation/compliance-notes/${dealId}?source=deals`);
    };

    const handleGenerateBrokerNote = (dealId: number) => {
        navigate(`/note-generation/broker-notes/${dealId}?source=deals`);
    };

    const handleToggle = (column: "deals" | "data") => {
        setActiveColumn(prev => (prev === column ? "none" : column));
    };
    

    return (
        <>
            <Container fullWidth={true} align="left">
                <Row>
                    <Col xs={24} md={24}></Col>
                </Row>
                <div>
                    <Row gutter={themeConfig.rowGutterXl}>
                        <Col xs={24} md={activeColumn === "deals" ? 24 : activeColumn === "data" ? 0 : 12}>
                                <DealHeaderRow>
                                    <Flex align="center" style={{ width: "100%" }} justify="space-between">
                                        <Flex gap={12}>
                                            <PageTitle title="Deals" style={{ marginBottom: 0 }} />
                                            <Tooltip title={activeColumn === "deals" ? "Minimize" : "Expand"}>
                                                <Button size="small" onClick={() => handleToggle("deals")}  type="text" icon={<ArrowsAltOutlined />} />
                                            </Tooltip>
                                        </Flex>
                                        <Button size="small" onClick={handleAddDeal} type="primary" icon={<PlusOutlined />}>
                                            Add Deal
                                        </Button>
                                    </Flex>
                                </DealHeaderRow>
                                <DealTab
                                    handleGenerateComplianceNote={handleGenerateComplianceNote}
                                    handleGenerateBrokerNote={handleGenerateBrokerNote}
                                    handleEditDeal={handleEditDeal}
                                />
                            
                        </Col>
                        <Col xs={24} md={activeColumn === "data" ? 24 : activeColumn === "deals" ? 0 : 12}>
                            
                                <DealHeaderRow>
                                    <Flex gap={12}>
                                         <PageTitle title="Raw Original Data" style={{ marginBottom: 0 }} />
                                         <Tooltip title={activeColumn === "data" ? "Minimize" : "Expand"}>
                                            <Button size="small" onClick={() => handleToggle("data")} type="text" icon={<ArrowsAltOutlined  />} />
                                         </Tooltip>
                                            
                                    </Flex>
                                    
                                </DealHeaderRow>
                                <WebsiteDataList getSelectedRowId={handleWebsiteDataSelection} handleCreateDeal={handleCreateDeal} />
                          
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default Deals;
