import { useSelector } from "react-redux";
import { RootState } from "@/store";

import { FC, useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Flex, Row, Spin, Input, message } from "antd";

import styles from "../../../BrokerNote.module.scss";
import AsideNoteheading from "./AsideNoteHeading";
import { BrokerNoteFieldTypes } from "@/pages/noteGeneration/types";
import { debounce } from "@/utils/common.utils";



const { TextArea } = Input;

interface AIBrokerNoteProps {
    id: BrokerNoteFieldTypes;
    title: string;
    loading: boolean;
    aiData: string;
    generateAIContent: (id: BrokerNoteFieldTypes) => void;
    onAIContentUpdate: (id: BrokerNoteFieldTypes, updatedContent: string) => void;
    viewUserInputPrompt?: (id: BrokerNoteFieldTypes) => void;
    viewSystemPrompt: (id: BrokerNoteFieldTypes) => void;
}
const AIBrokerNote: FC<AIBrokerNoteProps> = ({ title, loading, aiData, id, generateAIContent, onAIContentUpdate, viewUserInputPrompt, viewSystemPrompt }) => {
    const {userData} = useSelector((state: RootState) => state.auth);  

    const [editedContent, setEditedContent] = useState<string>(aiData);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setEditedContent(aiData);
    }, [aiData]);

    const debouncedUpdate = useCallback(
        debounce(content => {
            onAIContentUpdate(id, content);
        }, 300),
        [id, onAIContentUpdate]
    );

    const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditedContent(event.target.value);
        debouncedUpdate(event.target.value);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(editedContent).then(() => {
            messageApi.success("Text copied successfully");
        });
    };

    const handleViewInputPrompt = () => {
        if(viewUserInputPrompt){
            viewUserInputPrompt(id)
        }
    };
    return (
        <Row gutter={10} style={{ marginBottom: "10px" }}>
            <Col xs={24} md={24}>
                <AsideNoteheading title={title} />
            </Col>
            <Col xs={24} md={24}>
                <Card styles={{ body: { padding: 0 } }} bordered={false}>
                    {loading ? (
                        <div className="typingEffect">
                            <Spin size="small" /> Loading...
                        </div>
                    ) : (
                        <TextArea rows={6} value={editedContent} onChange={handleContentChange} autoSize={{ minRows: 3}} />
                    )}
                </Card>
                <Flex gap={22} style={{ marginTop: "1rem" }} justify="flex-end">
                    <Button onClick={() => generateAIContent(id)} loading={loading}>
                        Generate
                    </Button>
                    
                    {viewUserInputPrompt && <Button onClick={handleViewInputPrompt}>
                        View Inputs
                    </Button>}
                    <Button onClick={() => viewSystemPrompt(id)}>{userData?.isAdmin ? "View / Edit Prompt" : "View Prompt" } </Button>
                    <Button onClick={copyToClipboard} disabled={editedContent && editedContent !== "" ? false : true}>
                        Copy
                    </Button>
                </Flex>

                {contextHolder}
            </Col>
        </Row>
    );
};

export default AIBrokerNote;
