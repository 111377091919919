import { FC, ReactNode } from "react";
import { useTheme } from "@/contexts/themeContext";
import styles from "./AsideContainer.module.scss";
import { Button, Tooltip } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

interface AsideContainerProps {
    children?: ReactNode;
    sideContent: ReactNode;
}
const AsideContainer: FC<AsideContainerProps> = ({ children, sideContent }) => {
    const {rightSidebarCollapseState, toggleRightSidebarState} = useTheme();

    const toggleCollapsed = () => {
        toggleRightSidebarState()
    };

    return (
        <div className={`${styles.asideContainer} right-sidebar-container`}>
                   
            {children}
            <div className={`rightSidebar ${styles.rightSidebar} ${rightSidebarCollapseState ? styles.rightSidebarCollapse : ""}`}>
                <Tooltip title={rightSidebarCollapseState ? "Expand" : "Collapse"} placement="leftTop">
                        <Button
                            onClick={toggleCollapsed}
                            className={`${styles["right-sidebar-button"]} ${rightSidebarCollapseState ? styles.btnCollapse : ""}`}
                
                            size="small"
                            shape="circle"
                            icon={<DoubleRightOutlined />}
                        />
                    </Tooltip>
                    <div  className={`${styles.rightSidebarContent} ${rightSidebarCollapseState ? styles.rightSidebarContentCollapse : ""}`}>
                        {sideContent}
                    </div>
                
                </div>
        </div>
    );
};

export default AsideContainer;
