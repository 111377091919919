import { FC, useMemo, useRef, useState } from "react";
import { Skeleton } from "antd";
//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

//Store

import ActionButtonsRenderer, { ActionButtonType } from "@/pages/deals/components/ActionButtonsRenderer";
import Scrollbars from "rc-scrollbars";
import { useGetAllBrokerNoteQuery } from "../api/brokerNoteGeneratorAPI";
import { dateTimeTransformer } from "@/utils/common.utils";

const TextCellRenderer: FC<{ value: string }> = ({ value }) => {
    return <Scrollbars style={{height:"200px"}}>{value}</Scrollbars>;
  };

  interface BrokerNoteTableProps {
    actionButtons: ActionButtonType[];
    viewNote?: (data:any) => void;
    editNote?: (data:any) => void;
  }

const BrokerNoteTable:FC<BrokerNoteTableProps> = ({actionButtons, viewNote, editNote}) => {
    const gridRef = useRef<any>(null);
    const { data, isError, isLoading, isSuccess } = useGetAllBrokerNoteQuery(undefined, {refetchOnMountOrArgChange: true});  

    const handleView = (data:any) => {
        if(viewNote){
            viewNote(data)
        }
    }
    const handleEdit = (data:any) => {
        if(editNote) {
            editNote(data)
        }
    }
    const formatDate = (params: any) => {
        return dateTimeTransformer(params.value)
      };

    const [colDefs, setColDefs] = useState<ColDef[]>(() => {

        const columns: ColDef[] =  [
            {
                headerName: "Deal Name",
                field: "opportunity.name",
                headerTooltip: "Deal Name",
            },
             {
                headerName: "Updated At",
                field: "updated_at",
                valueFormatter:formatDate
            },
            {
                headerName: "Lender Note",
                field: "lender_loan_note",
                headerTooltip: "Generated Lender Note",
                cellRendererParams: TextCellRenderer,
                filter: false
            },
            {
                headerName: "Actions",
                cellRenderer: ActionButtonsRenderer,
                cellRendererParams: {
                    buttons:actionButtons,
                    handleView: handleView,
                    handleEdit: handleEdit,
                },
                width: 150,
                suppressMenu: true,
                pinned: "right",
                filter: false,
            }
        ];
    
        return columns;
    });

    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    return (
        <>
            <div>
                {isLoading ? (
                    <Skeleton />
                ) : (
                    <div className="ag-theme-quartz" style={{ height: "calc(100vh - 280px)" }}>
                        <AgGridReact 
                            defaultColDef={defaultColDef} 
                            tooltipInteraction={true} 
                            ref={gridRef} 
                            rowData={data?.data} 
                            columnDefs={colDefs} 
                            sideBar={"columns"} 
                            pagination={true} 
                            paginationPageSize={20}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default BrokerNoteTable;
