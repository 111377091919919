import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

import { Button, Card, Col, Flex, message, Row, Spin, Input } from "antd";

import AIContentHeading from "./AIContentHeading";
import { ComplianceFieldTypes } from "../types";
import { debounce } from "@/utils/common.utils";


const { TextArea } = Input;

interface AIContentProps {
    id: ComplianceFieldTypes,
    title: string;
    paragraph?:string;
    isLoading: boolean;
    aiData: string;
    generateAIContent: (id: ComplianceFieldTypes) => void;
    onAIContentUpdate: (id: ComplianceFieldTypes, updatedContent: string) => void;
    viewUserInputPrompt?: (id: ComplianceFieldTypes) => void;
    viewSystemPrompt: (id: ComplianceFieldTypes) => void;
}
const AIContent: FC<AIContentProps> = ({ title, paragraph, isLoading, aiData, id, generateAIContent, onAIContentUpdate, viewUserInputPrompt, viewSystemPrompt }) => {
    const {userData} = useSelector((state: RootState) => state.auth);  

    const [editedContent, setEditedContent] = useState<string>(aiData);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setEditedContent(aiData);
    }, [aiData]); 

    const debouncedUpdate = useCallback(debounce((content) => {
        onAIContentUpdate(id, content);
    }, 300), [id, onAIContentUpdate]);

    const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditedContent(event.target.value);
        debouncedUpdate(event.target.value);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(editedContent).then(() => {
            messageApi.success('Text copied successfully');
        });
    };

    const handleViewInputPrompt = () => {
        if(viewUserInputPrompt){
            viewUserInputPrompt(id)
        }
    };
    return (
        <div style={{ marginBottom: "1rem"}}>
            <AIContentHeading title={title} paragraph={paragraph} />
            <Card  styles={{ body: { padding: 0 } }} bordered={false}>
                {isLoading ? (
                    <div className="typingEffect">
                        <Spin size="small" /> Loading...
                    </div>
                ) : (
                    <TextArea
                        value={editedContent}
                        onChange={handleContentChange}
                        rows={6}
                    />
                    // <div className="ai-note-content"  ref={aiNoteContentRef} dangerouslySetInnerHTML={{ __html: aiData }} />
                )}
            </Card>
            <Flex gap={22} style={{marginTop:"1rem"}} justify="flex-end">
                    <Button type="primary" onClick={() => generateAIContent(id)} loading={isLoading}>Generate</Button>
                    {viewUserInputPrompt && <Button onClick={handleViewInputPrompt}>
                        View Inputs
                    </Button>}
                    <Button onClick={() => viewSystemPrompt(id)}>{userData?.isAdmin ? "View / Edit Prompt" : "View Prompt" } </Button>
                    <Button  onClick={copyToClipboard} disabled={ (aiData && aiData !== "") ? false:true }>Copy</Button>
            </Flex>
            {contextHolder}
        </div>
    );
};

export default AIContent;
