import { useUpdateDealMutation } from "@/store/apis/opportunityApi";
import { displaySuccess, displayError } from "@/utils/common.utils";
import { DealFormType, DealPayload } from "@/pages/deals/types";
import { DealType } from "@/types";

export const useUpdateDeal = () => {
    const [updateDeal, { isLoading, data, error, isSuccess }] = useUpdateDealMutation();

    const handleDealUpdate = ({ formData, requestId }: { formData: DealFormType, requestId:string }) => {
        let payload:DealPayload = {
            name: formData?.dealName,
            type: formData?.dealType as DealType,
            website_tracking_id: formData?.websiteTrackingId,
            primary_processor: formData?.brokerPrimaryContact,
            secondary_processor: formData?.brokerSecondaryContact,
            
            json_data: formData,
            requestId: requestId
        }

        if(formData?.contactEmail) {
            payload.primary_contact = {
                email: formData?.contactEmail,
                name: formData?.contactFullName,
                phone: formData?.contactPhone,
                citizenship: formData?.contactCitizenship,
                residency: formData?.contactResidency
            }
        }
        if(formData?.secondaryContactEmail) {
            payload.secondary_contact = {
                email: formData?.secondaryContactEmail,
                name: formData?.secondaryContactFullName,
                phone: formData?.secondaryContactPhone,
                citizenship: formData?.secondaryContactCitizenship,
                residency: formData?.secondaryContactResidency
            }
        }

        updateDeal(payload)
            .unwrap()
            .then((response) => {
               displaySuccess("Successfully updated deal")
            })
            .catch(error => {
               displayError(error)
            });
        
    };
    return {
        handleDealUpdate,
        isLoading,
        data,
        error,
        isSuccess,
    };
}