import { Navigate, Route, Routes } from "react-router-dom";

import BrokerNotes from "./BrokerNotes";
import BrokerNoteGenerator from "./BrokerNotes/BrokerNoteGenerator";
import ComplianceNotes from "./ComplianceNotes";
import ComplianceGenerator from "./ComplianceNotes/ComplianceGenerator";
import TemplatesPage from "./TemplatesPage";

export const NoteGenerationRoutes = () => {
    return (
        <div style={{width:"100%", alignSelf:"baseline"}}>
            <Routes>
                <Route path="broker-notes" element={<BrokerNotes />} />
                <Route path="broker-notes/:requestId/:noteID?" element={<BrokerNoteGenerator />} />
                <Route path="compliance-notes" element={<ComplianceNotes />} />
                <Route path="compliance-notes/:requestId/:complianceID?" element={<ComplianceGenerator />} />
                <Route path="templates" element={<TemplatesPage />} />
                <Route path="*" element={<Navigate to="." />} />
            </Routes>
        </div>
    );
};
