import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "@/store";

import { ApiResponse } from "@/types";
import { DashboardAPIResponse } from "../types";

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        getDashboardData: builder.query<ApiResponse<DashboardAPIResponse>, void>({
            query: () => "api/dashboard/"
        })
    })
});
export const { useGetDashboardDataQuery } = dashboardApi;
