import { useParams, useNavigate, Link } from "react-router-dom";
import { Breadcrumb, Button, Col, Flex, Row } from "antd"
import ButtonGroup from "antd/es/button/button-group";
import Scrollbars from "rc-scrollbars";

//components
import { BackButton, PageLoader } from "@/components/UI";
import DealForm, { DealFormMethods, FormFieldDataType } from "../components/DealForm";
import { Container } from "@/components/Layout";

//redux
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { usePostOpportunityServiceHistoryMutation, useGetOrCreateContactMutation } from "@/store/apis/opportunityApi";
import { useFetchLoanDetailQuery } from "@/store/apis/loanApi";

//utils
import { mapWebsiteKeyToCopilot } from "@/utils/form.utils";

//constant
import {creatDealbreadcrumbList} from "../constant";
import { BrokerContactType, ContactType, DealFormType, DealInfoType, DealPayload } from "../types";


import Title from "antd/es/typography/Title";
import { DealType } from "@/types";
import { convertToNumber, displayError, displaySuccess } from "@/utils/common.utils";
import { getOptionValue, LENDER_UPDATED_OPTIONS, LIVING_SITUATION_OPTIONS, LOAN_PURPOSE_OPTIONS } from "@/devFrontData/optionList";
import { getCountryCode } from "countries-list";

// function itemRender(currentRoute, params, items, paths) {
//   const isLast = currentRoute?.path === items[items.length - 1]?.path;

//   return isLast ? (
//     <span>{currentRoute.title}</span>
//   ) : (
//     <Link to={`/${paths.join("/")}`}>{currentRoute.title}</Link>
//   );
// }
const DealCreate = () => {
    const { requestId } = useParams<{ requestId?: string }>();
    const navigate = useNavigate();

    const submitRef = useRef<HTMLButtonElement>(null);
    const dealFormRef = useRef<DealFormMethods>(null);

    const dealsFormData = useSelector((state: RootState) => state.opportunityForm);
    const [dealFieldData, setDealFieldData] = useState<DealInfoType | null>(null);
    const [brokerContactData, setBrokerContactData] = useState<BrokerContactType | null>(null);
    const [contactData, setContactData] = useState<ContactType | null>(null);
    const [secondaryContactData, setSecondaryContactData] = useState<ContactType | null>(null);

    const [formFieldsData, setFormFieldsData] = useState<FormFieldDataType | null>(null);

    /**
     * API to create new deal
     */
    const [
        postDeal,
        { isLoading: postDealLoading, data: postDealData, error: postDealError, isSuccess: postDealSuccess }
    ] = usePostOpportunityServiceHistoryMutation();

    /**
     * API to get contact details back if existed if not create a new contact
     */
    const [getOrCreateContact, {isLoading: contactAPILoading, data: contactAPIData, isError: contactApiError}] = useGetOrCreateContactMutation();
    const [getOrCreateSecondaryContact, {isLoading: secondaryContactAPILoading, data: secondaryContactAPIData, isError: secondaryContactApiError}] = useGetOrCreateContactMutation();

    /**
     * API to Details to Purchase Request
     */
    const {
        data: websiteData,
        error: websiteDataError,
        isLoading: websiteDataLoading,
        isFetching: websiteDataFetching,
        
    } = useFetchLoanDetailQuery(requestId!, undefined);

    /**
     * watch if url has `requestId` and website data is fetched
     */
    useEffect(() => {
        if(websiteData && requestId){
            console.log("websiteData", websiteData);
            let mappedData:any;
            if(websiteData.form_id === '8') {
                mappedData = mapWebsiteKeyToCopilot(websiteData, 'purchase');
                setDealFieldData({dealName:"", dealType:"purchase"});
            }
            if(websiteData.form_id === '1') {
                mappedData = mapWebsiteKeyToCopilot(websiteData, 'refinance');
                setDealFieldData({dealName:"", dealType:"refinance"})
            }
            mappedData.purpose = getOptionValue(mappedData.purpose ?? "", LOAN_PURPOSE_OPTIONS);
            mappedData.lender = getOptionValue(mappedData.lender ?? "", LENDER_UPDATED_OPTIONS);
            mappedData.livingStatus = getOptionValue(mappedData.livingStatus ?? "", LIVING_SITUATION_OPTIONS);
            
            mappedData.ccLimit1 = convertToNumber(mappedData.ccLimit1 ?? 0);
            mappedData.ccLimit_2 = convertToNumber(mappedData.ccLimit_2 ?? 0);
            mappedData.bonusThis = convertToNumber(mappedData.bonusThis ?? 0);
            mappedData.bonusLast = convertToNumber(mappedData.bonusLast ?? 0);

            mappedData.bonusThis_2 = convertToNumber(mappedData.bonusThis_2 ?? 0);
            mappedData.bonusLast_2 = convertToNumber(mappedData.bonusLast_2 ?? 0);

            
            let residency_1 = getCountryCode(mappedData.residency ?? "")
            console.log(mappedData)
            let contactPayload:ContactType = {
                email: mappedData.email,
                name: `${mappedData.firstName} ${mappedData.lastName}`,
                phone: mappedData.phone,
                citizenship: mappedData.citizenship,
                residency: residency_1 || mappedData.residency
            }
            
            let residency_2 = getCountryCode(mappedData.residency_2 ?? "")
            const contactAPIPrimary = getOrCreateContact(contactPayload).unwrap();
            const contactAPISecondary = mappedData.coApplicationStatus === 'Yes'
            ? getOrCreateSecondaryContact({
                email: mappedData.email_2, 
                name: `${mappedData.firstName_2} ${mappedData.lastName_2}`,
                phone: mappedData.phone_2,
                citizenship: mappedData.citizenship_2,
                residency: residency_2 || mappedData.residency_2
            }).unwrap()
            : Promise.resolve(null); // Resolve immediately if no second call
    
            Promise.all([contactAPIPrimary, contactAPISecondary])
            .then(([contactPrimaryData, contactSecondaryData]) => {

                setFormFieldsData(mappedData as FormFieldDataType)
                setContactData({
                    email: contactPrimaryData.data?.email,
                    name: contactPrimaryData.data?.name,
                    phone: contactPrimaryData.data?.phone,
                    citizenship: contactPrimaryData.data?.citizenship,
                    residency: contactPrimaryData.data?.residency
                });
                if(contactSecondaryData){
                    setSecondaryContactData({
                        email: contactSecondaryData.data?.email,
                        name: contactSecondaryData.data?.name,
                        phone: contactSecondaryData.data?.phone,
                        citizenship: contactSecondaryData.data?.citizenship,
                        residency: contactSecondaryData.data?.residency
                    })
                } 
            })
            .catch(err => {
                displayError(err)
            })
                
        }
    },[websiteData, requestId])

    /**
     * Handles the submission of the Deal Form.
     * @param {Object} params - The parameters for the function.
     * @param {DealFormType} params.formData - The data from the Deal Form.
     */
    const handleFormSubmit = ({ formData }: { formData: DealFormType }) => {

        let payload:DealPayload = {
            name: formData?.dealName,
            type: formData?.dealType as DealType,
            website_tracking_id: formData?.websiteTrackingId ?? '',
            primary_processor: formData?.brokerPrimaryContact ?? '',
            secondary_processor: formData?.brokerSecondaryContact ?? '',
            
            json_data: formData,
            requestId: requestId
        }

        if(formData?.contactEmail) {
            payload.primary_contact = {
                email: formData?.contactEmail,
                name: formData?.contactFullName,
                phone: formData?.contactPhone,
                citizenship: formData?.contactCitizenship,
                residency: formData?.contactResidency
            }
        }
        if(formData?.secondaryContactEmail) {
            payload.secondary_contact = {
                email: formData?.secondaryContactEmail,
                name: formData?.secondaryContactFullName,
                phone: formData?.secondaryContactPhone,
                citizenship: formData?.secondaryContactCitizenship,
                residency: formData?.secondaryContactResidency
            }
        }

        postDeal(payload)
            .unwrap()
            .then((response) => {
               displaySuccess("Successfully updated deal");
               navigate(`/deals`);
            })
            .catch(error => {
               displayError(error)
            });
    }
    
    const handleSaveDeal = () => {
        if (submitRef.current) {
            submitRef.current.click();
        } 
    };

    return (
        <>
            <Container fullWidth={true} align="left" >
                <Row>
                    <Col xs={24} md={24}>
                        <Flex justify="space-between" align="center" style={{ marginBottom: "1rem" }}>
                            <Breadcrumb>
                                {creatDealbreadcrumbList.map((breadcrumb, index) => (
                                    <Breadcrumb.Item key={index}>
                                        {breadcrumb.path ? <Link to={breadcrumb.path}>{breadcrumb.title}</Link> : breadcrumb.title}
                                    </Breadcrumb.Item>
                                ))}
                            </Breadcrumb>
                           <BackButton />
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Flex justify="space-between" align="center" style={{ marginBottom: "1rem" }}>
                            <div>
                                <Title level={4} style={{margin:0}}>
                                    Add New Deal
                                </Title>                                
                            </div>
                            <ButtonGroup>
                                <Button type="primary" onClick={handleSaveDeal} loading={postDealLoading}>Save Deal</Button>
                            </ButtonGroup>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Scrollbars style={{ height: "calc(100vh - 180px)" }}>
                            <DealForm
                                handleFormSubmit={handleFormSubmit}
                                submitRef={submitRef}
                                formFieldsData={formFieldsData}
                                initialValues={dealsFormData}
                                dealData={dealFieldData}
                                brokerContact={brokerContactData}
                                ref={dealFormRef}
                                contactData={contactData}
                                secondaryContactData={secondaryContactData}
                            />
                        </Scrollbars>
                    </Col>
                </Row>
            </Container>
            <PageLoader isLoading={contactAPILoading || secondaryContactAPILoading} />
        </>
    );
};

export default DealCreate;