import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Row, Col, Drawer } from "antd";

//redux
import { useGetOpportunityServiceHistoryQuery } from "@/store/apis/opportunityApi";

//Components
import DealList from "@/pages/deals/components/DealTab/DealList";
import { Container } from "@/components/Layout";
import AsideContainer from "@/components/Layout/AsideContainer";
import ComplianceSidebar from "../../components/ComplianceSidebar";

//utils
import { themeConfig } from "@/config";
import ComplianceNoteTable from "../../components/ComplianceNoteTable";
import { PageTitle } from "@/components/UI";
import DetailListPanel from "@/pages/deals/components/DetailListPanel";
import { Deal, DealFormType } from "@/pages/deals/types";
import ComplianceDetailPanel from "../../components/ComplianceDetailPanel";
import { COMPLIANCE_FIELD_LABELS } from "../../constant/list";
import { CASH_OUT_INVOLVED_OPTIONS, DOCUMENT_IDENTIFICATION_OPTIONS, getOptionLabel, LOAN_PURPOSE_OPTIONS, RATE_TYPE_OPTIONS, REPAYMENT_OPTIONS, LENDER_UPDATED_OPTIONS } from "@/devFrontData/optionList";
import { ComplianceAPIResponse } from "../../types";

const ComplianceNotes = () => {
    const navigate = useNavigate();

    const [viewDealDetail, setViewDealDetail] = useState(false);
    const [dealsData, setDealsData] = useState<Deal | null>(null);

    const [viewComplianceNote, setViewComplianceNote] = useState(false);
    const [complianceData, setComplianceData] = useState<any>(null);

    const {
        data: deals,
        isLoading: dealsLoading,
        isError: dealsError,
        refetch: refetchDeals
    } = useGetOpportunityServiceHistoryQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const handleGenerateComplianceNote = (dealId: number) => {
        navigate(`/note-generation/compliance-notes/${dealId}?source=deals`);
    };

    const handleViewDeal = (deal: any) => {
        setViewDealDetail(true);
        setDealsData(deal);
    };

    const handleViewComplianceNote = (data: any) => {
        setComplianceData((prevData: any) => {
            return {
                ...data, 
                document_identification_method:  getOptionLabel(data?.document_identification_method ?? "", DOCUMENT_IDENTIFICATION_OPTIONS),
                cash_out_involved: getOptionLabel(data?.cash_out_involved ?? "", CASH_OUT_INVOLVED_OPTIONS),
                rate_type: getOptionLabel(data?.rate_type ?? "", RATE_TYPE_OPTIONS),
                repayment_type:  getOptionLabel(data?.rate_type ?? "", REPAYMENT_OPTIONS),
                loan_scenario_lender_1: getOptionLabel(data?.loan_scenario_lender_1 ?? "", LENDER_UPDATED_OPTIONS),
                loan_scenario_lender_2: getOptionLabel(data?.loan_scenario_lender_2 ?? "", LENDER_UPDATED_OPTIONS),
                loan_scenario_lender_3: getOptionLabel(data?.loan_scenario_lender_3 ?? "", LENDER_UPDATED_OPTIONS),
                lvr: data?.opportunity?.json_data?.lvr,
                purpose: getOptionLabel(data?.opportunity?.json_data?.purpose ?? "", LOAN_PURPOSE_OPTIONS),                
                date: data?.opportunity?.json_data?.date,
                name: data?.opportunity?.name
            }
        });
        setViewComplianceNote(true);
    };

    const handleEditComplianceNote = (data: ComplianceAPIResponse) => {
        navigate(`/note-generation/compliance-notes/${data.opportunity.id}/${data.id}`);
    };

    return (
        <>
            <AsideContainer sideContent={<ComplianceSidebar />}>
                <Container fullWidth={true} align="left">
                    <Row gutter={themeConfig.row.gutter.md}>
                        <Col xs={24} md={12}>
                            <PageTitle title="Deals" />
                            <DealList
                                listType="self"
                                actionList={["view", "complianceNote"]}
                                rowData={deals}
                                isLoading={dealsLoading}
                                isError={dealsError}
                                handleGenerateComplianceNote={handleGenerateComplianceNote}
                                handleViewDeal={handleViewDeal}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <PageTitle title="Compliance Notes" />
                            <ComplianceNoteTable actionButtons={["view", "edit"]} viewComplianceNote={handleViewComplianceNote} editComplianceNote={handleEditComplianceNote} />
                        </Col>
                    </Row>
                </Container>
            </AsideContainer>
            <Drawer title={dealsData?.name ?? ""} onClose={() => setViewDealDetail(false)} open={viewDealDetail} width={themeConfig.drawer.xxl}>
                <DetailListPanel dealDetail={dealsData?.json_data as DealFormType} />
            </Drawer>

            <Drawer title={complianceData?.name ?? ""} onClose={() => setViewComplianceNote(false)} open={viewComplianceNote} width={themeConfig.drawer.xxl}>
                <ComplianceDetailPanel data={complianceData} tableMapper={COMPLIANCE_FIELD_LABELS} />
            </Drawer>
        </>
    );
};

export default ComplianceNotes;
