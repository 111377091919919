import { DealFormType } from "@/pages/deals/types";
import { theme } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { FC } from "react";

const { useToken } = theme;

interface PDFHeadingInterface {
    allValues: Partial<DealFormType> | null;
}
const PDFHeading:FC<PDFHeadingInterface> = ({allValues}) => {
    const { token } = useToken();
    return (
        <div style={{ textAlign: "left", position: "relative", marginBottom: "1rem" }}>
            <Title level={3} style={{ color: token.colorPrimary, margin: "0 0 2px" }}>
                BROKER NOTES
            </Title>
            <div>Australian Mortgages for Expats & Overseas Residents</div>
            <div style={{ position: "absolute", right: 0, top: 0 }}>
                <Paragraph style={{ color: token.colorPrimary, margin: 0 }}>  {dayjs().format("DD/MM/YYYY")}</Paragraph>
            </div>
        </div>
    );
};

export default PDFHeading;
